import { useWindowResizeCallback } from '@/hooks/use-window-resize-callback';
import { playerStore } from '@/stores/player-store';
import { clsx } from 'clsx';
import { ReactNode, useCallback, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import { OutPortal } from 'react-reverse-portal';
import classes from './mini-player.module.scss';

function DragParent({ children }: { children: ReactNode }) {
  const ref = useRef<HTMLDivElement>(null);
  const position = playerStore.useTracked.miniPlayerPosition();

  // Update player position based on window size
  const updatePosition = useCallback(() => {
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect();
      const { innerWidth, innerHeight } = window;
      const maxX = innerWidth - width;
      const maxY = innerHeight - height;
      const currentPosition = playerStore.get.miniPlayerPosition() || {
        x: maxX,
        y: maxY
      };
      const updatedPosition = {
        x: Math.max(Math.min(currentPosition.x, maxX), 0),
        y: Math.max(Math.min(currentPosition.y, maxY), 0)
      };
      playerStore.set.miniPlayerPosition(updatedPosition);
    }
  }, []);

  // Adjust position on window resize
  useWindowResizeCallback(updatePosition);

  // Set initial position
  useEffect(() => {
    updatePosition();
    return () => {
      playerStore.set.miniPlayerPosition(undefined);
    };
  }, [updatePosition]);

  return (
    <div className={clsx(classes.miniPlayerRoot, 'mini-player-root')}>
      <Draggable
        position={position}
        handle=".mini-player-handle"
        bounds="parent"
        onStop={(_, data) =>
          playerStore.set.miniPlayerPosition({ x: data.x, y: data.y })
        }
      >
        <div
          className={clsx(classes.miniPlayerContent, 'mini-player-content')}
          ref={ref}
        >
          <div
            className={clsx(classes.miniPlayerHandle, 'mini-player-handle')}
          />
          {children}
        </div>
      </Draggable>
    </div>
  );
}

export function MiniPlayer() {
  const miniPlayerInstance = playerStore.useTracked.miniPlayerInstance();
  return (
    miniPlayerInstance && (
      <DragParent>
        <OutPortal node={miniPlayerInstance.node} />
      </DragParent>
    )
  );
}
