import urlJoin from 'proper-url-join';
import { getPathLocale } from './get-path-locale';

export interface CreatePathWithLocaleOptions {
  pathname: string;
  locale?: string;
  search?: string;
  basePath?: string;
}

export function createPathWithLocale({
  pathname,
  locale,
  search = '',
  basePath = ''
}: CreatePathWithLocaleOptions) {
  const pathLocale = getPathLocale(pathname);
  return urlJoin(
    basePath,
    locale,
    pathLocale ? pathname.replace(`/${pathLocale}`, '') : pathname,
    search.startsWith('?') ? search : search ? `?${search}` : '',
    { leadingSlash: true }
  );
}
