import { GraphqlClient } from '@/libs/amplify/types';
import type { DefaultError } from '@tanstack/query-core';
import { UseSuspenseQueryOptions } from '@tanstack/react-query';
import {
  GetLeaderboardQueryKey,
  getLeaderboardQueryKey
} from './query-options';
import { GetLeaderboard, getLeaderboardSelectionSet } from './types';

export function getLeaderboardQuery(
  graphqlClient: GraphqlClient,
  owner: string,
  month: string
): UseSuspenseQueryOptions<
  GetLeaderboard | null,
  DefaultError,
  GetLeaderboard | null,
  GetLeaderboardQueryKey
> {
  const queryKey = getLeaderboardQueryKey(month);
  return {
    queryKey,
    queryFn: async () => {
      if (!owner) {
        return null;
      }
      const { data, errors } = await graphqlClient.models.Leaderboard.get(
        { owner, month },
        { selectionSet: getLeaderboardSelectionSet }
      );
      if (errors?.length) {
        console.error(errors);
      }
      return data as GetLeaderboard;
    }
  };
}
