import { EntityType } from '@/__generated__/API';
import { EntityItem, ParentEntityItem } from '@/api/entities/types';
import compact from 'lodash-es/compact';
import sortBy from 'lodash-es/sortBy';

type EntityRelationshipFilter = {
  entityType?: EntityType;
  slugId?: string;
  withoutChildren?: boolean;
};

export function isParentEntityItem(
  input: ParentEntityItem | EntityItem | undefined | null
): input is ParentEntityItem {
  return !!(input && 'children' in input && input.children);
}

export function filterEntityRelationship(
  input: ParentEntityItem | EntityItem | undefined | null,
  filter: EntityRelationshipFilter = {},
  result: EntityItem[] = []
): EntityItem[] {
  if (
    input &&
    (!filter.entityType || input.entityType === filter.entityType) &&
    (!filter.slugId || input.id === filter.slugId) &&
    (!filter.withoutChildren || !isParentEntityItem(input))
  ) {
    result.push(input as EntityItem);
  }

  if (isParentEntityItem(input)) {
    const sortedChildren = sortBy(compact(input.children?.entities), 'order');
    for (let i = 0; i < sortedChildren.length; i++) {
      filterEntityRelationship(
        sortedChildren[i] as unknown as ParentEntityItem | EntityItem,
        filter,
        result
      );
    }
  }

  return result;
}
