'use client'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PlayIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import { Trans } from '@lingui/macro';
import Link from '@mui/material/Link';

export type EndedOverlayProps = {
  entityId: string;
}

export function EndedOverlay({ entityId }: EndedOverlayProps) {
  return (
    <>
      <Box
        className="ended-overlay"
        sx={{
          position: 'absolute',
          left: '0',
          top: '0',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
          color: 'white',
          zIndex: 20
        }}
      >
        <Box
          className="ended-overlay-container"
          display="flex"
          height="100%"
          width="100%"
          sx={{
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box
            maxWidth="80%"
            display="flex"
            flexDirection="column"
            justifyItems="center"
          >
            <Link href={`/learn/replays/${entityId}`} sx={{ color: 'white', mr: 1 }}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <PlayIcon color='inherit' sx={{ width: '48px', height: '48px', fontWeight: '600', mr: 1 }} />
                <Typography fontSize="18px" fontWeight="600" sx={{ color: 'white' }}>
                  <Trans id="ended-overlay.title">
                    Watch the replay
                  </Trans>
                </Typography>
              </Box>
            </Link>

            <Typography
              fontWeight="600"
              fontSize="18px"
              lineHeight="26px"
              textAlign="center"
              sx={{ mt: 4 }}
            >
              <Trans id="ended-overlay.description">
                The event has ended.
              </Trans>
            </Typography>

          </Box>
        </Box>
      </Box>
    </>
  );
}