'use client';

import { useAnalyticsPageView } from '@/hooks/use-analytics-page-view';
import { User } from '@/types';
import type { RudderAnalytics } from '@rudderstack/analytics-js';

declare global {
  let analytics: RudderAnalytics;
  interface Window {
    analytics: RudderAnalytics;
  }
}

// Note that environment variables cannot be destructured for client-side usage
// due to the way Next.js handles environment variable exposure. Client-side
// environment variables must be explicitly accessed through `process.env`
// with the appropriate `NEXT_PUBLIC_` prefix.
const RUDDERSTACK_WRITE_KEY = process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY;

const RUDDERSTACK_DATA_PLANE_URL =
  process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL;

const isRudderStackEnabled = Boolean(
  RUDDERSTACK_WRITE_KEY && RUDDERSTACK_DATA_PLANE_URL
);

export function analyticsIdentify(user: User) {
  // Custom uuid for existing 2.0 users
  // For newly created users, custom uuid and id is same
  // After signup, custom:uuid is set in background thereby takes time and
  // comes up null just after signup
  const userId = user['custom:uuid'] || user.id;
  analytics.identify(userId, {
    email: user.email,
    last_name: user.family_name,
    first_name: user.given_name
  });
}

export function InitializeRudderstack() {
  useAnalyticsPageView();

  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}

export const RudderStackScript = `
  (function() {
    "use strict";
    window.RudderSnippetVersion = "3.0.25";
    var identifier = "rudderanalytics";
    if (!window[identifier]) {
      window[identifier] = [];
    }
    var rudderanalytics = window.analytics = window[identifier];
    if (Array.isArray(rudderanalytics)) {
      if (rudderanalytics.snippetExecuted === true && window.console && console.error) {
        console.error("RudderStack JavaScript SDK snippet included more than once.");
      } else {
        rudderanalytics.snippetExecuted = true;
        window.rudderAnalyticsBuildType = "legacy";
        var sdkBaseUrl = "https://cdn.rudderlabs.com/v3";
        var sdkName = "rsa.min.js";
        var scriptLoadingMode = "async";
        var methods = [ "setDefaultInstanceKey", "load", "ready", "page", "track", "identify", "alias", "group", "reset", "setAnonymousId", "startSession", "endSession", "consent" ];
        for (var i = 0; i < methods.length; i++) {
          var method = methods[i];
          rudderanalytics[method] = function(methodName) {
            return function() {
              if (Array.isArray(window[identifier])) {
                rudderanalytics.push([ methodName ].concat(Array.prototype.slice.call(arguments)));
              } else {
                var _methodName;
                (_methodName = window[identifier][methodName]) === null || _methodName === void 0 || _methodName.apply(window[identifier], arguments);
              }
            };
          }(method);
        }
        try {
          new Function('return import("")');
          window.rudderAnalyticsBuildType = "modern";
        } catch (e) {}
        var head = document.head || document.getElementsByTagName("head")[0];
        var body = document.body || document.getElementsByTagName("body")[0];
        window.rudderAnalyticsAddScript = function(url, extraAttributeKey, extraAttributeVal) {
          var scriptTag = document.createElement("script");
          scriptTag.src = url;
          scriptTag.setAttribute("data-loader", "RS_JS_SDK");
          if (extraAttributeKey && extraAttributeVal) {
            scriptTag.setAttribute(extraAttributeKey, extraAttributeVal);
          }
          if (scriptLoadingMode === "async") {
            scriptTag.async = true;
          } else if (scriptLoadingMode === "defer") {
            scriptTag.defer = true;
          }
          if (head) {
            head.insertBefore(scriptTag, head.firstChild);
          } else {
            body.insertBefore(scriptTag, body.firstChild);
          }
        };
        window.rudderAnalyticsMount = function() {
          (function() {
            if (typeof globalThis === "undefined") {
              var getGlobal = function getGlobal() {
                if (typeof self !== "undefined") {
                  return self;
                }
                if (typeof window !== "undefined") {
                  return window;
                }
                return null;
              };
              var global = getGlobal();
              if (global) {
                Object.defineProperty(global, "globalThis", {
                  value: global,
                  configurable: true
                });
              }
            }
          })();
          window.rudderAnalyticsAddScript("".concat(sdkBaseUrl, "/").concat(window.rudderAnalyticsBuildType, "/").concat(sdkName), "data-rsa-write-key", "${RUDDERSTACK_WRITE_KEY}");
        };
        if (typeof Promise === "undefined" || typeof globalThis === "undefined") {
          window.rudderAnalyticsAddScript("https://polyfill-fastly.io/v3/polyfill.min.js?version=3.111.0&features=Symbol%2CPromise&callback=rudderAnalyticsMount");
        } else {
          window.rudderAnalyticsMount();
        }
        ${
          isRudderStackEnabled
            ? `
          var loadOptions = {
            storage: {
              migrate: true,
              encryption: {
                version: 'v3'
              }
            }
          };
          rudderanalytics.load("${RUDDERSTACK_WRITE_KEY}", "${RUDDERSTACK_DATA_PLANE_URL}", loadOptions);
          rudderanalytics.ready(() => {
            rudderanalytics.page();
          });
        `
            : ``
        }
      }
    }
  })();
`;
