import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/runner/work/client/client/apps/web/node_modules/@mui/icons-material/ArrowForward.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/client/client/apps/web/node_modules/@mui/lab/Timeline/Timeline.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Card/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/CardActionArea/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/CardContent/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/CardHeader/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Divider/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/IconButton/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Link/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Paper/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Skeleton/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["DashboardCards"] */ "/home/runner/work/client/client/apps/web/src/app/[lang]/(shell)/components/dashboard/dashboard-cards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DashboardTabs"] */ "/home/runner/work/client/client/apps/web/src/app/[lang]/(shell)/components/dashboard/dashboard-tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DashboardTimelineItem"] */ "/home/runner/work/client/client/apps/web/src/app/[lang]/(shell)/components/dashboard/dashboard-timeline-item.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/src/app/[lang]/(shell)/components/dashboard/devtools/dashboard-datepicker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CurrentStreakCount"] */ "/home/runner/work/client/client/apps/web/src/app/[lang]/(shell)/components/dashboard/streak/current-streak-count.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DashboardStreakModal"] */ "/home/runner/work/client/client/apps/web/src/app/[lang]/(shell)/components/dashboard/streak/dashboard-streak-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StreakDateButton"] */ "/home/runner/work/client/client/apps/web/src/app/[lang]/(shell)/components/dashboard/streak/streak-date-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HighestStreakCount"] */ "/home/runner/work/client/client/apps/web/src/app/[lang]/(shell)/leaderboard/streaks/components/highest-streak-count.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/src/assets/icons/dashboard/fire-icon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageLayout"] */ "/home/runner/work/client/client/apps/web/src/components/layouts/page-layout.tsx");
