'use client';

import {
  SocialShareBar,
  SocialShareBarProps
} from '@/components/social-share/social-share-bar';
import { ButtonProps } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { SxProps, Theme } from '@mui/material/styles';
import {
  bindDialog,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import { ReactNode, useEffect } from 'react';
import { ShareButton } from './share-button';

export type SocialShareButtonDialogProps = SocialShareBarProps & {
  children(props: ButtonProps): ReactNode;
};

export function SocialShareDialogTrigger({
  children,
  ...props
}: SocialShareButtonDialogProps) {
  const dialogState = usePopupState({
    variant: 'dialog'
  });

  useEffect(() => {
    if (dialogState.isOpen) {
      analytics.track('Click', {
        element_id: 'social-share-button',
        type: 'BUTTON'
      });
    }
  }, [dialogState.isOpen]);

  return (
    <>
      <Dialog maxWidth="sm" fullWidth {...bindDialog(dialogState)}>
        <DialogContent>
          <SocialShareBar {...props} />
        </DialogContent>
      </Dialog>
      {children(bindTrigger(dialogState))}
    </>
  );
}

export type SocialShareButtonProps = SocialShareBarProps & {
  sx?: SxProps<Theme>;
  isTextButton?: boolean;
};

export function SocialShareButton({
  sx,
  isTextButton,
  ...socialShareBarProps
}: SocialShareButtonProps) {
  return (
    <SocialShareDialogTrigger {...socialShareBarProps}>
      {(props) => (
        <ShareButton
          isTextButton={isTextButton}
          sx={sx}
          {...props}
          className="social-share-button"
        />
      )}
    </SocialShareDialogTrigger>
  );
}
