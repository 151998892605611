'use client';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { SxProps, Theme } from '@mui/material/styles';
import { MouseEvent } from 'react';

export type ShareButtonProps = {
  sx?: SxProps<Theme>;
  className?: string;
  isTextButton?: boolean;
  onClick?: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
};

export function ShareButton({
  sx,
  isTextButton,
  className,
  ...buttonProps
}: ShareButtonProps) {
  const { i18n } = useLingui();

  return (
    <>
      {isTextButton ? (
        <Button
          variant="text"
          color="inherit"
          sx={{ color: 'text.secondary', ...sx }}
          aria-label={i18n.t('common.share')}
          className={className}
          startIcon={
            <ReplyRoundedIcon sx={{ transform: 'rotate(180deg) scaleY(-1)' }} />
          }
          {...buttonProps}
        >
          <div className='social-share-button-text'>
            <Trans id="common.share">Share</Trans>
          </div>
        </Button>
      ) : (
        <IconButton
          aria-label={i18n.t('common.share')}
          size="small"
          sx={sx}
          {...buttonProps}
        >
          <ReplyRoundedIcon sx={{ transform: 'rotate(180deg) scaleY(-1)' }} />
        </IconButton>
      )}
    </>
  );
}
