import { Box, Button, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';
import { Entitlement } from '@/entitlements/enums';
import { upsellRedirectDialogStore } from '@/stores/upsell-redirect-dialog-store';

export type VideoBrickedProps = {
  showUpsell: (forced: boolean) => void;
  entityRequiresUltra: boolean;
  entityRequiresCHPC: boolean;
}

export function VideoBricked({ showUpsell, entityRequiresUltra, entityRequiresCHPC }: VideoBrickedProps) {

  const handleClickUpgrade = () => {
    analytics.track('Click', {
      element_id: 'show-upsell-button',
      parent_id: 'Play & Brick',
      type: 'BUTTON'
    });
    if (entityRequiresUltra) {
      upsellRedirectDialogStore.set.entitlement(Entitlement.ULTRA);
      upsellRedirectDialogStore.set.isOpen(true);
      return;
    } else if (entityRequiresCHPC) {
      upsellRedirectDialogStore.set.entitlement(Entitlement.CHPC_NEW);
      upsellRedirectDialogStore.set.isOpen(true);
      return;
    } else {
      showUpsell(true);
    }
  }

  return (
    <Box
      id="bricked-overlay"
      className="bricked-overlay"
      sx={{
        position: 'absolute',
        left: '0',
        top: '0',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        color: 'white',
        zIndex: 1000
      }}
    >
      <Box
        className="bricked-overlay-container"
        display="flex"
        height="100%"
        width="100%"
        sx={{
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          maxWidth="80%"
          display="flex"
          flexDirection="column"
          justifyItems="center"
        >
          <Typography
            className="bricked-overlay-title"
            fontWeight="600"
            textAlign="center"
          >
            <Trans id="bricked.title">
              Feeling inspired?
              <br />
              Get the full experience!
            </Trans>
          </Typography>

          <Typography
            className="brick-overlay-description"
            fontWeight="600"
            textAlign="center"
            sx={{ mt: 2 }}
          >
            <Trans id="bricked.description">
              Upgrade plan to continue.
            </Trans>
          </Typography>

          <Box
            display="flex"
            flexDirection="row"
            flexGrow={1}
            justifyContent="center"
            sx={{ mt: 4 }}
            onClick={handleClickUpgrade}
          >
            <Button onClick={handleClickUpgrade}>
              <Trans id="common.upgrade-your-plan">
                Upgrade Your Plan
              </Trans>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}