'use client';
import { useLingui } from '@lingui/react';
import { noop } from 'lodash-es';
import {
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
  useEffect
} from 'react';

export type ConfirmNavigationContextType = [
  boolean,
  Dispatch<SetStateAction<boolean>>
];

export const ConfirmNavigationContext =
  createContext<ConfirmNavigationContextType>([false, noop]);

export function ConfirmNavigationProvider({ children }: PropsWithChildren) {
  const navigationBlockedState = useState(false);

  return (
    <ConfirmNavigationContext.Provider value={navigationBlockedState}>
      {children}
    </ConfirmNavigationContext.Provider>
  );
}

export function useConfirmNavigation(shouldConfirm = true) {
  const [confirmNavigation, setConfirmNavigation] = useContext(
    ConfirmNavigationContext
  );
  const { i18n } = useLingui();

  useEffect(() => {
    if (shouldConfirm) {
      setConfirmNavigation(true);

      const message = i18n.t('confirm-navigation.message');

      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        return message;
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        setConfirmNavigation(false);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [setConfirmNavigation, shouldConfirm, i18n]);

  // Return the current global state - whether direct navigation is blocked by confirmation
  return { willConfirm: confirmNavigation };
}
