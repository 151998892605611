'use client';

import { EntityLiveState, EntityType } from '@/__generated__/API';
import { useSendHeartbeat } from '@/components/player/utils/use-send-heartbeat';
import { SocialShareButton } from '@/components/social-share/social-share-button';
import { RenderHTML } from '@/libs/editor/html';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import {
  isHLSProvider,
  MediaAbortEvent,
  MediaEndedEvent,
  MediaErrorDetail,
  MediaErrorEvent,
  MediaPauseEvent,
  MediaPlayer,
  MediaPlayerProps,
  MediaPlayerQuery,
  MediaPlayEvent,
  MediaPlayingEvent,
  MediaProvider,
  MediaProviderAdapter,
  MediaRateChangeEvent,
  MediaSeekedEvent,
  MediaSeekingEvent,
  MediaStalledEvent,
  MediaVolumeChange,
  MediaWaitingEvent,
  Poster,
  Track,
  TrackProps,
  HLSErrorEvent
} from '@vidstack/react';
import type { ErrorData } from 'hls.js';
import {
  DefaultAudioLayout,
  DefaultAudioLayoutProps,
  defaultLayoutIcons,
  DefaultVideoLayout,
  DefaultVideoLayoutProps
} from '@vidstack/react/player/layouts/default';
import Image from 'next/image';
import { useCallback, useEffect, useRef, useState } from 'react';
import { sendPlaybackMetrics } from '../utils/send-playback-metrics';
import { usePlayerTranslations } from '../utils/use-player-translations';
import { BigPlayButton } from './controls/big-play-button';
import { CloseButton } from './controls/close-button';
import { DragHandle } from './controls/drag-handle';
import { EndedOverlay } from './controls/ended-overlay';
import { OpenLink } from './controls/open-link';
import { BackwardSeekButton, ForwardSeekButton } from './controls/seek-button';
import { SettingsMenu } from './controls/settings-menu';
import { SpeedMenu } from './controls/speed-menu';
import { TimeControl } from './controls/time-control';
import { UserCount } from './controls/user-count';
import { playerStore } from '@/stores/player-store';
import { useUpsellOffering } from '@/entitlements/hooks/use-upsell-offering';
import { AudioBricked } from './controls/audio-bricked';
import { VideoBricked } from './controls/video-bricked';
import './vidstack-player.scss';
import { Entitlement } from '@/entitlements/enums';

export type VidstackPlayerProps = Omit<Partial<MediaPlayerProps>, 'ref'> & {
  subtitle?: string;
  thumbnails?: string;
  tracks?: TrackProps[];
  smallLayoutWhen?: boolean | MediaPlayerQuery;
  playerRef?: MediaPlayerProps['ref'];
  pathname?: string;
  footer?: string | null;
  viewerCount?: number;
  entityId?: string;
  requiredEntitlements?: string[];
  enforceEntitlementsAt?: number;
  showPoster?: boolean;
  shareUrl?: string;
  entityLiveState?: EntityLiveState;
  entityType?: EntityType;
  canShowEndedOverlay?: boolean;
  bricked?: boolean;
  autoplay?: boolean;
  maxRetries?: number;
  retryInterval?: number;
  parentEntityId?: string;
};

function onProviderChange(provider: MediaProviderAdapter | null) {
  // vidstack reference: https://www.vidstack.io/docs/player/api/providers/hls?styling=default-theme#configuring
  // hls.js reference: https://github.com/video-dev/hls.js/blob/master/docs/API.md#fine-tuning
  if (isHLSProvider(provider)) {
    provider.config = {
      manifestLoadingMaxRetry: 0, // We'll handle retries ourselves
      maxBufferLength: 5, // Buffer only a few seconds in advance
      maxMaxBufferLength: 10 // Maximum buffer limit which player will never cross
    };
  }
}

/**
 * Renders a Vidstack media player component with customizable properties and layout configurations.
 *
 * This component is a wrapper around the `MediaPlayer` component from `@vidstack/react`, designed to
 * provide a flexible and extensible media player experience. It supports both video and audio media types,
 * and allows for the inclusion of custom poster images, thumbnail previews for video scrubbing, and text
 * tracks for captions or subtitles. The player's layout can adapt based on the provided `smallLayoutWhen`
 * condition, which defines when to switch to a more compact layout. Internationalization support is integrated
 * through the `useTranslation` hook to provide localized text for player controls and messages.
 *
 * @param VidstackPlayerProps - An object containing optional properties for the media player, including:
 *                              - `poster`: URL for a poster image to display before playback starts.
 *                              - `thumbnails`: URL for a VTT file with thumbnail preview data.
 *                              - `tracks`: An array of `TrackProps` objects for text tracks.
 *                              - `smallLayoutWhen`: A condition (boolean or media query) to determine when to use a small layout.
 *                              - `...props`: Additional `MediaPlayerProps` to pass to the `MediaPlayer` component.
 * @returns A `MediaPlayer` component configured with video/audio layouts, poster, thumbnails, and text tracks.
 */
export function VidstackPlayer({
  thumbnails,
  tracks,
  smallLayoutWhen,
  playerRef,
  title,
  subtitle,
  pathname,
  footer,
  viewerCount,
  entityId,
  requiredEntitlements,
  enforceEntitlementsAt,
  showPoster,
  shareUrl,
  entityLiveState,
  entityType,
  canShowEndedOverlay,
  bricked,
  parentEntityId,
  maxRetries = 60, // 60 retries by default or 10 minutes
  retryInterval = 10_000, // 10 seconds between retries
  ...props
}: VidstackPlayerProps) {
  const { palette } = useTheme();
  const translations = usePlayerTranslations();
  const containerRef = useRef<HTMLDivElement>();
  const isLive = entityLiveState === EntityLiveState.LIVE;
  const entitlementCheckIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const retryCountRef = useRef(0);
  const retryTimeoutRef = useRef<NodeJS.Timeout>();
  const [isBricked, setIsBricked] = useState<boolean>(bricked || false);
  const player = playerStore.use.playerInstance(entityId || '')?.ref.current;
  const { startHeartbeat, stopHeartbeat } = useSendHeartbeat(entityId);

  const {
    isEntitled,
    showUpsell,
    entityRequiresUltra,
    entityRequiresCHPC,
    missingEntitlements,
    userEntitlements
  } = useUpsellOffering({
    requiredEntitlements,
    entityId: parentEntityId || entityId
  });

  const smallAudioLayoutQuery = useCallback<MediaPlayerQuery>(({ width }) => {
    return width < 600;
  }, []);

  const smallVideoLayoutQuery = useCallback<MediaPlayerQuery>(
    ({ width, height }) => {
      return width < 440 || height < 220;
    },
    []
  );

  const onEntitlementCheck = useCallback(() => {
    if (enforceEntitlementsAt) {
      if ((player?.currentTime || 0) >= enforceEntitlementsAt && !isEntitled) {
        player?.pause();
        setIsBricked(true);
        if (entitlementCheckIntervalRef.current) {
          clearInterval(entitlementCheckIntervalRef.current);
          entitlementCheckIntervalRef.current = null;
        }
      }
    }
  }, [isEntitled, player, enforceEntitlementsAt]);

  /**
   * This event is fired when a play button is clicked.
   */
  const handlePlay = (event: MediaPlayEvent) => {
    if (entityId) {
      sendPlaybackMetrics('Video Playback Resumed', entityId);
    }
    if (props.onPlay) {
      props.onPlay(event);
    }
    if (!entitlementCheckIntervalRef.current) {
      onEntitlementCheck();
      entitlementCheckIntervalRef.current = setInterval(
        onEntitlementCheck,
        1000
      );
    }

    if (entityType === EntityType.EVENT && isLive) {
      startHeartbeat();
    }
  };

  const handleVolumeChange = ({ volume }: MediaVolumeChange) => {
    const settings = playerStore.get.settings();
    const updatedSettings = {...settings, volume};
    playerStore.set.settings(updatedSettings);
  }

  const handleRateChange = (playbackRate: MediaRateChangeEvent) => {
    const settings = playerStore.get.settings();
    const updatedSettings = {...settings, playbackRate: playbackRate as unknown as number};
    playerStore.set.settings(updatedSettings);
  }

  /**
   * This event is fired when buffering ends and playback starts/resumes.
   */
  const handlePlaying = (event: MediaPlayingEvent) => {
    if (player) {
      const settings = playerStore.get.settings();
      player.volume = settings.volume;
      player.playbackRate = Number(settings.playbackRate);
    }
    if (isLive) {
      player?.seekToLiveEdge();
    }
    if (isBricked) {
      event.target.currentTime = 0;
      event.target.pause();
    } else {
      if (entityId) {
        sendPlaybackMetrics('Video Playback Buffer Completed', entityId);
        sendPlaybackMetrics('Video Playback Started', entityId);
      }
      if (props.onPlaying) {
        props.onPlaying(event);
      }
    }
  };

  /**
   * This event is fired when the pause button is clicked.
   */
  const handlePause = (event: MediaPauseEvent) => {
    if (entityId) {
      sendPlaybackMetrics('Video Playback Paused', entityId);
    }
    if (props.onPause) {
      props.onPause(event);
    }
    stopHeartbeat();
    if (entitlementCheckIntervalRef.current) {
      clearInterval(entitlementCheckIntervalRef.current);
      entitlementCheckIntervalRef.current = null;
    }
  };

  /**
   * This event is fired when buffering starts.
   */
  const handleWaiting = (event: MediaWaitingEvent) => {
    if (entityId) {
      sendPlaybackMetrics('Video Playback Buffer Started', entityId);
    }
    if (props.onWaiting) {
      props.onWaiting(event);
    }
  };

  /**
   * This event is fired when the browser is trying to fetch data but is not receiving it.
   */
  const handleStalled = (event: MediaStalledEvent) => {
    if (entityId) {
      sendPlaybackMetrics('Video Playback Interrupted', entityId);
    }
    if (props.onStalled) {
      props.onStalled(event);
    }
  };

  /**
   * This event fires at the start of seeking a new position in the media timeline.
   */
  const handleSeeking = (detail: number, event: MediaSeekingEvent) => {
    if (entityId) {
      sendPlaybackMetrics('Video Playback Seek Started', entityId);
    }
    if (props.onSeeking) {
      props.onSeeking(detail, event);
    }
  };

  /**
   * This event fires at the end of seeking a new position in the media timeline.
   */
  const handleSeeked = (detail: number, event: MediaSeekedEvent) => {
    if (entityId) {
      sendPlaybackMetrics('Video Playback Seek Completed', entityId);
    }
    if (props.onSeeked) {
      props.onSeeked(detail, event);
    }
  };

  /**
   * This event fires when playback reaches the end of the media.
   */
  const handleEnded = (event: MediaEndedEvent) => {
    if (entityId) {
      sendPlaybackMetrics('Video Playback Completed', entityId);
    }
    if (props.onEnded) {
      props.onEnded(event);
    }
  };

  /**
   * This event is fired when media loading has been aborted,
   * as when a user navigates away from the page.
   */
  const handleAbort = (event: MediaAbortEvent) => {
    if (entityId) {
      sendPlaybackMetrics('Video Playback Interrupted', entityId);
    }
    if (props.onAbort) {
      props.onAbort(event);
    }
  };

  /**
   * This event is fired when an error occues while loading or playing media.
   */
  const handleError = (detail: MediaErrorDetail, event: MediaErrorEvent) => {
    console.error('playback error: ', event.target, detail);
    if (props.onError) {
      props.onError(detail, event);
    }
  };

  /**
   * This event is fired when the component unmounts, and clears interval if one exists.
   */
  const handleUnmount = () => () => {
    stopHeartbeat();
    if (entitlementCheckIntervalRef.current) {
      clearInterval(entitlementCheckIntervalRef.current);
      entitlementCheckIntervalRef.current = null;
    }
    if (retryTimeoutRef.current) {
      clearTimeout(retryTimeoutRef.current);
    }
  };

  const handleHlsError = (error: ErrorData, event: HLSErrorEvent) => {
    if (
      error.fatal &&
      error.type === 'networkError' &&
      error.details === 'manifestLoadError'
    ) {
      console.debug(
        `HLS error encountered (Retry ${retryCountRef.current + 1}/${maxRetries})`
      );

      if (retryCountRef.current < maxRetries) {
        retryCountRef.current++;

        // Clear existing timeout
        if (retryTimeoutRef.current) {
          clearTimeout(retryTimeoutRef.current);
        }

        // Schedule next retry
        retryTimeoutRef.current = setTimeout(() => {
          console.debug('Retrying stream load...');
          // Force a reload of the source
          const player = event.target?.provider;
          if (player) {
            const currentSrcType =
              player.currentSrc?.type || 'application/x-mpegurl';

            void player.loadSource({
              src: props.src,
              type: currentSrcType
            });
          }
        }, retryInterval);
      } else {
        console.debug('Max retries reached, stream unavailable');
      }
    }
  };

  // Reset retry count on successful load
  const handleManifestLoaded = () => {
    retryCountRef.current = 0;
    if (retryTimeoutRef.current) {
      clearTimeout(retryTimeoutRef.current);
    }
  };

  const layoutProps: DefaultVideoLayoutProps | DefaultAudioLayoutProps = {
    icons: defaultLayoutIcons,
    thumbnails,
    translations,
    noModal: true,
    noScrubGesture: true,
    colorScheme: palette.mode,
    showTooltipDelay: 300,
    seekStep: 15,
    noAudioGain: true
  };

  const SharedLayoutChildren = () => (
    <>
      {props.poster && (
        <Poster className="vds-poster" src={props.poster} alt={title || ''} />
      )}
      <span className="vds-heading">
        {title && <span className="vds-heading-title">{title}</span>}
        {subtitle && <span className="vds-heading-subtitle">{subtitle}</span>}
      </span>
    </>
  );

  const shouldShowEndedOverlay =
    entityId &&
    entityLiveState === EntityLiveState.ON_DEMAND &&
    entityType === EntityType.EVENT;

  useEffect(() => {
    if (
      !isEntitled &&
      (requiredEntitlements?.includes(Entitlement.ULTRA) ||
        requiredEntitlements?.some((entitlement) =>
          entitlement.includes('CHPC')
        ))
    ) {
      setIsBricked(true);
    }
  }, [isEntitled, requiredEntitlements]);

  useEffect(handleUnmount, [stopHeartbeat]);

  useEffect(() => {
    if (isBricked && entityId && entityLiveState && entityType && missingEntitlements && userEntitlements) {
      analytics.track('Element Visible', {
        element_id: 'bricked-overlay',
        type: 'BRICKED_OVERLAY',
        parent_id: 'Play & Brick',
        context: {
          entityId,
          entityType,
          entityLiveState,
          userEntitlements,
          missingEntitlements
        }
      });
    }
  }, [isBricked, entityId, entityLiveState, entityType, missingEntitlements, userEntitlements]);

  return (
    <Box
      className="player-container"
      ref={containerRef}
      sx={{
        position: 'relative',
        height: 'auto',
        width: '100%',
        containerType: 'inline-size',
        '& .vds-controls': {
          display: isBricked ? 'none !important' : undefined
        },
        '@container (max-width: 439px)': {
          '.bricked-overlay': {
            height: '100%',
            padding: '40px'
          },
          '.bricked-overlay-container': {
            alignItems: 'flex-start'
          }
        },
        '@container (min-width: 440px)': {
          '.bricked-overlay': {
            height: '100%'
          },
          '.bricked-overlay-container': {
            alignItems: 'center'
          }
        },
        '@container (max-width: 639px)': {
          '.bricked-overlay-title': {
            fontSize: '19px',
            lineHeight: '22px'
          },
          '.bricked-overlay-description': {
            fontSize: '14px',
            lineHeight: '18px'
          }
        },
        '@container (min-width: 640px)': {
          '.bricked-overlay-title': {
            fontSize: '39px',
            lineHeight: '45px'
          },
          '.bricked-overlay-description': {
            fontSize: '18px',
            lineHeight: '26px'
          }
        }
      }}
    >
      {showPoster ? (
        <>
          <Image
            src={props.poster || ''}
            alt="Poster"
            width={1280}
            height={720}
            style={{
              width: '100%',
              height: '100%'
            }}
            priority
          />
        </>
      ) : (
        <MediaPlayer
          tabIndex={isBricked ? -1 : undefined}
          src=""
          // @ts-ignore
          onProviderChange={onProviderChange}
          playsInline
          streamType={isLive ? 'live' : 'on-demand'}
          autoPlay={isLive}
          {...props}
          ref={playerRef}
          onPlay={handlePlay}
          // TODO: Determine correct typing for event handler
          onVolumeChange={handleVolumeChange as any}
          onRateChange={handleRateChange as any}
          onPause={handlePause}
          onWaiting={handleWaiting}
          onPlaying={handlePlaying}
          onStalled={handleStalled}
          onSeeking={handleSeeking}
          onSeeked={handleSeeked}
          onEnded={handleEnded}
          onAbort={handleAbort}
          onError={handleError}
          // @ts-ignore
          onHlsError={handleHlsError}
          // @ts-ignore
          onHlsManifestLoaded={handleManifestLoaded}
        >
          <MediaProvider />
          {tracks?.map((track) => <Track key={track.lang} {...track} />)}
          <DefaultVideoLayout
            {...layoutProps}
            smallLayoutWhen={smallLayoutWhen || smallVideoLayoutQuery}
            slots={{
              topControlsGroupEnd: (
                <>
                  <OpenLink href={pathname} />
                  <DragHandle />
                  <CloseButton />
                </>
              ),
              smallLayout: {
                settingsMenu: <SettingsMenu placement="bottom end" />,
                afterLiveButton: <UserCount viewerCount={viewerCount} />
              },
              largeLayout: {
                settingsMenu: <SettingsMenu />,
                centerControlsGroupCenter: <BigPlayButton />,
                afterPlayButton: (
                  <>
                    <BackwardSeekButton />
                    <ForwardSeekButton />
                  </>
                ),
                topControlsGroupEnd: <UserCount viewerCount={viewerCount} />
              }
            }}
          >
            <>
              <SharedLayoutChildren />
              {isBricked && (
                <VideoBricked
                  showUpsell={showUpsell}
                  entityRequiresUltra={entityRequiresUltra}
                  entityRequiresCHPC={entityRequiresCHPC}
                />
              )}
            </>
          </DefaultVideoLayout>
          <DefaultAudioLayout
            {...layoutProps}
            smallLayoutWhen={smallLayoutWhen || smallAudioLayoutQuery}
            slots={{
              afterSettingsMenu: (
                <div
                  className="mini-buttons"
                  style={
                    entityType === EntityType.DAILYFIRE ? { zIndex: 0 } : {}
                  }
                >
                  <OpenLink href={pathname} />
                  {shareUrl && (
                    <SocialShareButton
                      isTextButton
                      title="Share"
                      appendedPathname={shareUrl}
                      sx={{
                        color:
                          entityType === EntityType.DAILYFIRE ? 'white' : ''
                      }}
                    />
                  )}
                  <DragHandle />
                  <CloseButton />
                </div>
              ),
              settingsMenu: <SettingsMenu />,
              afterPlayButton: (
                <>
                  <BackwardSeekButton />
                  <ForwardSeekButton />
                  <TimeControl />
                </>
              ),
              beforeCaptionButton: <SpeedMenu />,
              seekBackwardButton: null,
              seekForwardButton: null
            }}
          >
            <>
              <SharedLayoutChildren />
              {isBricked && <AudioBricked showUpsell={showUpsell} />}
            </>
          </DefaultAudioLayout>
          {footer && <RenderHTML className="vds-footer">{footer}</RenderHTML>}
          {canShowEndedOverlay && shouldShowEndedOverlay && (
            <EndedOverlay entityId={entityId} />
          )}
        </MediaPlayer>
      )}
    </Box>
  );
}
