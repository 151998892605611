'use client';

import {
  getOfferingQuery,
  getSubscriberInfoQuery,
  GetSubscriberInfoQueryKey
} from '@/entitlements/api/query-options';
import { RevenueCatSubscriber } from '@/entitlements/types';
import { useGraphqlClient } from '@/libs/amplify/client';
import { GraphqlClient } from '@/libs/amplify/types';
import { useAuthStore } from '@/stores/auth-store-provider';
import type { DefaultError } from '@tanstack/query-core';
import {
  useSuspenseQuery,
  useQuery,
  UseSuspenseQueryOptions
} from '@tanstack/react-query';
import keys from 'lodash-es/keys';

export function getEntitlementsQuery(
  graphqlClient: GraphqlClient,
  owner: string
): UseSuspenseQueryOptions<
  RevenueCatSubscriber | null,
  DefaultError,
  string[],
  GetSubscriberInfoQueryKey
> {
  return {
    ...getSubscriberInfoQuery(graphqlClient, owner),
    select: (data) => keys(data?.entitlements) ?? []
  };
}

export function useGetSubscriberInfo() {
  const authStore = useAuthStore();
  const graphqlClient = useGraphqlClient();
  const userId = authStore.useTracked.userId() ?? '';
  return useQuery(getSubscriberInfoQuery(graphqlClient, userId));
}

export function useStreamGetSubscriberInfo() {
  const authStore = useAuthStore();
  const graphqlClient = useGraphqlClient();
  const userId = authStore.useTracked.userId() ?? '';
  return useSuspenseQuery(getSubscriberInfoQuery(graphqlClient, userId));
}

export function useGetEntitlements() {
  const authStore = useAuthStore();
  const graphqlClient = useGraphqlClient();
  const userId = authStore.useTracked.userId() ?? '';
  return useQuery(getEntitlementsQuery(graphqlClient, userId));
}

export function useStreamGetEntitlements() {
  const authStore = useAuthStore();
  const graphqlClient = useGraphqlClient();
  const userId = authStore.useTracked.userId() ?? '';
  return useSuspenseQuery(getEntitlementsQuery(graphqlClient, userId));
}

export function useGetOffering(id: string) {
  const graphqlClient = useGraphqlClient();
  return useQuery(getOfferingQuery(graphqlClient, id));
}

export function useStreamGetOffering(id: string) {
  const graphqlClient = useGraphqlClient();
  return useSuspenseQuery(getOfferingQuery(graphqlClient, id));
}
