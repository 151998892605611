'use client';

import { Carousel } from '@/components/carousel';
import { useLingui } from '@lingui/react';
import Image from 'next/image';

import authImg1 from '@/assets/images/auth-carousel/auth-item-1.webp';
import authImg2 from '@/assets/images/auth-carousel/auth-item-2.webp';
import authImg3 from '@/assets/images/auth-carousel/auth-item-3.webp';

const images = [authImg1, authImg2, authImg3];

export function AuthCarousel() {
  const { i18n } = useLingui();

  const handleNext = () => {
    analytics.track('Click', {
      element_id: 'auth-carousel-next-button',
      type: 'BUTTON'
    });
  };

  const handlePrev = () => {
    analytics.track('Click', {
      element_id: 'auth-carousel-prev-button',
      type: 'BUTTON'
    });
  };

  return (
    <Carousel
      onSlideNextTransitionEnd={handleNext}
      onSlidePrevTransitionEnd={handlePrev}
      loop
      spaceBetween={0}
      slidesPerViewPerBreakpoint={1}
      ContainerProps={{
        sx: {
          '&, & > div.swiper': {
            height: '100%',
            width: '100%'
          }
        }
      }}
      ButtonContainerProps={{
        sx: {
          top: 16,
          right: 16
        }
      }}
      ButtonProps={{
        color: 'inherit',
        sx: {
          color: '#fff',
          border: '1px solid #fff',
          padding: '2px',
          svg: {
            fontSize: '1.5rem'
          }
        }
      }}
    >
      {images.map((image, index) => (
        <Image
          placeholder="blur"
          key={index}
          fill
          src={image}
          style={{ objectFit: 'cover' }}
          alt={i18n.t({
            id: 'auth.carousel.item',
            message: 'Auth carousel image {number}',
            values: { number: index + 1 }
          })}
        />
      ))}
    </Carousel>
  );
}
