'use client';

import {
  Children,
  MutableRefObject,
  useCallback,
  useId,
  useMemo,
  useRef,
  useState
} from 'react';
import ChevronLeftOutlined from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined';
import Box, { BoxProps } from '@mui/material/Box';
import Stack, { StackProps } from '@mui/material/Stack';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { useTheme, Breakpoint } from '@mui/material/styles';
import { useLingui } from '@lingui/react';
import { CarouselSkeleton } from './carousel-skeleton';

import {
  Swiper,
  SwiperProps,
  SwiperSlide,
  SwiperRef,
  SwiperClass
} from 'swiper/react';
import { SwiperOptions } from 'swiper/types';
import {
  createCarouselBreakpoints,
  createCarouselStyles,
  defaultSwiperProps
} from './utils';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './caoursel.scss';

export type NavigationState = {
  prev: 'active' | 'disabled';
  next: 'active' | 'disabled';
};

export type CarouselProps = SwiperProps &
  Partial<Record<Breakpoint, SwiperOptions>> & {
    ContainerProps?: BoxProps;
    ButtonContainerProps?: StackProps;
    ButtonProps?: IconButtonProps;
    slidesPerViewPerBreakpoint?: number;
    swiperRef?: MutableRefObject<SwiperRef>;
    isLoading?: boolean;
    hideNavigation?: boolean;
    navigationSpeed?: number;
    onNavStateChanged?: (navState: NavigationState) => void;
    skeletonAspectRatio?: [number, number];
    id?: string
  };

export type CarouselNavigationProps = {
  navState: NavigationState,
  ButtonProps?: IconButtonProps,
  ButtonContainerProps?: StackProps,
  enabled: boolean,
  carouselId?: string
}

export function CarouselNavigation({navState, ButtonProps, ButtonContainerProps, enabled, carouselId}: CarouselNavigationProps) {
  const { i18n } = useLingui();
  return (
    <Stack
      direction="row"
      spacing={1}
      {...ButtonContainerProps}
      hidden={!enabled}
    >   
      <IconButton
        size="small"
        color="primary"
        disabled={navState.prev === 'disabled'}
        aria-label={i18n.t('common.previous')}
        className={`swiper-prev-button-${carouselId}`}
        {...ButtonProps}
        sx={[
          {
            color: 'primary.dark'
          },
          ...(Array.isArray(ButtonProps?.sx)
            ? ButtonProps.sx
            : [ButtonProps?.sx])
        ]}
      >
        <ChevronLeftOutlined />
      </IconButton>
      <IconButton
        size="small"
        color="primary"
        disabled={navState.next === 'disabled'}
        aria-label={i18n.t('common.next')}
        className={`swiper-next-button-${carouselId}`}
        {...ButtonProps}
        sx={[
          {
            color: 'primary.dark'
          },
          ...(Array.isArray(ButtonProps?.sx)
            ? ButtonProps.sx
            : [ButtonProps?.sx])
        ]}
      >
        <ChevronRightOutlined />
      </IconButton>

    </Stack>
  );
}

/**
 * A responsive carousel component built with the Swiper library, which supports
 * custom breakpoints, navigation buttons, and additional customization options.
 *
 * @remarks
 * - The `Carousel` component utilizes the `Swiper` library for slide functionality and the `MUI` library
 *   for styling and additional features.
 * - The component dynamically adjusts the carousel's behavior and layout based on screen size using breakpoints.
 * - Navigation buttons are automatically enabled or disabled based on the current slide position.
 * - The component also supports custom styles and configurations for the carousel container and buttons.
 */
export function Carousel({
  xs,
  sm,
  md,
  lg,
  xl,
  children,
  ContainerProps,
  ButtonContainerProps,
  ButtonProps,
  slidesPerViewPerBreakpoint,
  swiperRef,
  isLoading,
  hideNavigation,
  navigationSpeed,
  onNavStateChanged,
  skeletonAspectRatio,
  id,
  ...props
}: CarouselProps) {

  const [navigationState, setNavigationState] = useState<NavigationState>({
    prev: props.loop ? 'active' : 'disabled',
    next: props.loop ? 'active' : 'disabled'
  });
  const ref = useRef<SwiperRef>(null);

  const { direction } = useTheme();
  const breakpoints = useMemo(
    () =>
      createCarouselBreakpoints(
        { xs, sm, md, lg, xl },
        slidesPerViewPerBreakpoint
      ),
    [xs, sm, md, lg, xl, slidesPerViewPerBreakpoint]
  );

  const updateState = useCallback(() => {
    setTimeout(() => {
      const swiper = ref.current?.swiper;
      if (swiper) {
        const navState: NavigationState = {
          prev:
            !swiper.allowSlidePrev ||
            (swiper.isBeginning && !swiper.loopedSlides)
              ? 'disabled'
              : 'active',
          next:
            !swiper.allowSlideNext || (swiper.isEnd && !swiper.loopedSlides)
              ? 'disabled'
              : 'active'
        };

        setNavigationState(navState);
        onNavStateChanged?.(navState);
      }
    });
  }, [onNavStateChanged]);

  const onSlideChange = (swiper: SwiperClass) => {
    updateState();
    props.onSlideChange?.(swiper);
  };

  const onBreakpoint = (
    swiper: SwiperClass,
    breakpointParams: SwiperOptions
  ) => {
    updateState();
    props.onBreakpoint?.(swiper, breakpointParams);
  };

  const onInit = (swiper: SwiperClass) => {
    updateState();
    props.onInit?.(swiper);
    if (swiperRef && ref.current) {
      swiperRef.current = ref.current;
    }
  };

  const onDestroy = (swiper: SwiperClass) => {
    // Swiper does not clean up any pagination elements that it creates, so
    // we manually destroy any elements in case the anchor is outside the carousel
    if (swiper.pagination?.el) {
      swiper.pagination.el.innerHTML = '';
    }
  };

  const swiperProps: SwiperProps = {
    dir: direction,
    breakpoints,
    ...defaultSwiperProps,
    ...props
  };

  const navButtonContainerProps: StackProps = {
    ...ButtonContainerProps,
    sx: [{position: 'absolute', zIndex: 2, top: -48, right: 0 },
      ...Array.isArray(ButtonContainerProps?.sx) ? ButtonContainerProps.sx : [ButtonContainerProps?.sx]],
  };

  const guid = useId().replaceAll(':', '-');
  const carouselId = id || guid;

  if (isLoading) {
    return (
      <CarouselSkeleton
        {...{
          xs,
          sm,
          md,
          lg,
          xl,
          spaceBetween: props.spaceBetween,
          slidesPerViewPerBreakpoint,
          skeletonAspectRatio
        }}
      />
    );
  }

  return (
    <Box
      zIndex={0}
      position="relative"
      {...ContainerProps}
      style={createCarouselStyles(swiperProps)}
    >
      {!hideNavigation && (
        <CarouselNavigation 
          navState={navigationState} 
          ButtonProps={ButtonProps}
          ButtonContainerProps={navButtonContainerProps}
          enabled={!hideNavigation}
          carouselId={carouselId} />
      )}
      <Swiper
        {...swiperProps}
        onInit={onInit}
        ref={ref}
        onSlideChange={onSlideChange}
        onDestroy={onDestroy}
        onBreakpoint={onBreakpoint}
        navigation={{prevEl: `.swiper-prev-button-${carouselId}`, nextEl: `.swiper-next-button-${carouselId}`}}
      >
        {Children.toArray(children).map((child, index) => (
          <SwiperSlide key={index}>{child}</SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
