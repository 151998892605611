import useStableCallback from '@/hooks/use-stable-callback';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useAuthStore } from '@/stores/auth-store-provider';
import { useMutation } from '@tanstack/react-query';
import { useRef } from 'react';
import { useBoolean, useInterval } from 'usehooks-ts';

const HEARTBEAT_INTERVAL = 15000;

export function useSendHeartbeat(entityId?: string) {
  const authStore = useAuthStore();
  const isSignedIn = authStore.useTracked.isSignedIn();
  const sessionIdRef = useRef<string>();
  const graphqlClient = useGraphqlClient();

  const { value, setTrue, setFalse: stopHeartbeat } = useBoolean(false);

  const { mutate } = useMutation({
    mutationFn: async () => {
      if (entityId) {
        const { data, errors } =
          await graphqlClient.mutations.sendViewHeartbeat({
            sessionId: sessionIdRef.current,
            entityId
          });

        return data;
      }
    },
    onSuccess: (data) => {
      if (data?.sessionId) {
        sessionIdRef.current = data.sessionId;
      }
    }
  });

  const heartbeatEnabled = Boolean(value && isSignedIn && entityId);

  useInterval(mutate, heartbeatEnabled ? HEARTBEAT_INTERVAL : null);

  const startHeartbeat = useStableCallback(() => {
    mutate();
    setTrue();
  });

  return { startHeartbeat, stopHeartbeat, heartbeatEnabled };
}
