import { DeleteEntityLikeInput, Entity } from '@/__generated__/API';
import {
  EntityLikeSelectionSetResponse,
  getUserEntityLikeQueryKey
} from '@/api/entity-like/read';
import {
  getSocialProofQueryKey,
  SocialProofQueryResponse
} from '@/api/social-proof/read';
import { useUpdateSocialProofInStore } from '@/api/social-proof/utils';
import { useGraphqlClient } from '@/libs/amplify/client';
import { throwGraphqlError } from '@/utils/throw-graphql-error';
import { useMutation, useQueryClient } from '@tanstack/react-query';

/**
 * Type definition for the variables required to delete an entity like.
 * Extends DeleteEntityLikeInput and includes the entityId.
 */
export type DeleteEntityLikeVariables = DeleteEntityLikeInput & {
  entityId: Entity['id'];
};

/**
 * Custom hook to delete an entity like.
 * Utilizes useMutation from react-query to handle the mutation.
 *
 * @returns - The mutation object returned by useMutation.
 */
export function useDeleteEntityLike() {
  const graphql = useGraphqlClient();
  const queryClient = useQueryClient();
  const updateSocialProof = useUpdateSocialProofInStore();

  /**
   * Mutation function to delete an entity like.
   *
   * @param payload - The variables required to delete the entity like.
   */
  return useMutation({
    async mutationFn(payload: DeleteEntityLikeVariables) {
      const response = await graphql.models.EntityLike.delete({
        id: payload.id
      });
      throwGraphqlError(response.errors);
    },
    /**
     * Function to handle optimistic updates before the mutation is fired.
     *
     * @param payload - The variables required to delete the entity like.
     * @returns - The previous state of the entity like and social proof.
     */
    async onMutate(payload) {
      const entityLikeQueryKey = getUserEntityLikeQueryKey(payload.entityId);
      const socialProofQueryKey = getSocialProofQueryKey(payload.entityId);

      // Cancel any outgoing queries for the entity like and social proof
      const likeQueryCancel = queryClient.cancelQueries({
        queryKey: entityLikeQueryKey
      });
      const socialProofQueryCancel = queryClient.cancelQueries({
        queryKey: socialProofQueryKey
      });

      await Promise.allSettled([likeQueryCancel, socialProofQueryCancel]);

      // Get the previous state of the entity like and set it to null
      const previousEntityLike =
        queryClient.getQueryData<EntityLikeSelectionSetResponse | null>(
          entityLikeQueryKey
        );
      queryClient.setQueryData<EntityLikeSelectionSetResponse | null>(
        entityLikeQueryKey,
        null
      );

      // Get the previous state of the social proof and decrement the like count
      const previousSocialProof =
        queryClient.getQueryData<SocialProofQueryResponse | null>(
          getSocialProofQueryKey(payload.entityId)
        );
      updateSocialProof(payload.entityId, (old) => ({
        likesCount: (old?.likesCount ?? 0) - 1
      }));

      return { previousEntityLike, previousSocialProof };
    },
    /**
     * Function to handle errors during the mutation.
     *
     * @param _ - The error object.
     * @param payload - The variables required to delete the entity like.
     * @param context - The context object containing the previous state.
     */
    onError(_, payload, context) {
      if (context) {
        const entityLikeQueryKey = getUserEntityLikeQueryKey(payload.entityId);
        const socialProofQueryKey = getSocialProofQueryKey(payload.entityId);

        // Rollback to the previous state in case of an error
        queryClient.setQueryData(
          entityLikeQueryKey,
          context.previousEntityLike
        );
        queryClient.setQueryData(
          socialProofQueryKey,
          context.previousSocialProof
        );
      }
    }
  });
}
