import { User } from '@/types';
import { JWT } from '@auth/core/jwt';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { decodeJWT } from '@aws-amplify/core';
import dayjs from 'dayjs';
import omit from 'lodash-es/omit';

export const jwtFields = [
  'aud',
  'exp',
  'iat',
  'jti',
  'iss',
  'origin_jti',
  'event_id',
  'token_use',
  'auth_time'
];

export function getIdTokenExpiry(idToken: string): string {
  const decodedToken = decodeJWT(idToken);
  return dayjs((decodedToken.payload.exp ?? 0) * 1000).toISOString();
}

export function getUserFromIdToken(idToken: string): User {
  const decodedToken = decodeJWT(idToken);
  const user = omit(decodedToken.payload, ...jwtFields) as User;
  user.id = user.sub;
  return user;
}

export function sessionToToken(session: CognitoUserSession): JWT {
  const refreshToken = session.getRefreshToken();
  const idToken = session.getIdToken();
  const accessToken = session.getAccessToken();

  return {
    accessToken: accessToken.getJwtToken(),
    refreshToken: refreshToken.getToken(),
    idToken: idToken.getJwtToken()
  };
}
