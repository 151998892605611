'use client';

import { LanguageSwitcher } from '@/components/language-switcher';
import { Trans } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';

export function AuthFooter() {
  return (
    <Stack alignItems="center" justifyContent="space-between">
      <Typography variant="caption" color="text.secondary">
        <Trans id="sign-in.copyright">
          &copy; 2025 GrowthDay. All Rights Reserved.
        </Trans>
      </Typography>
      <LanguageSwitcher />
    </Stack>
  );
}
