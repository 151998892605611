/**
 * Regular expression to validate email addresses based on a shorter version of RFC 5322.
 * This regex checks for:
 * - Local part that does not contain special characters like <>()[]\\.,;:\s@"
 * - Optionally, the local part can contain dots separating alphanumeric characters
 * - An '@' symbol separating the local part from the domain part
 * - A domain part that can either be a set of numeric IP address blocks or
 *   alphanumeric domain names separated by dots and ending with a valid top-level domain
 *
 * Examples:
 * - Valid: "example@example.com", "user.name+tag+sorting@example.com"
 * - Invalid: "plainaddress", "@missingusername.com"
 */
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Regular expression to validate passwords with the following criteria:
 * - At least one uppercase letter
 * - At least one lowercase letter
 * - At least one digit
 * - At least one special character from the set !@#$%^&*()_+\-=\[\]{}|;:'",.<>\/?
 * - Minimum length of 8 characters
 *
 * Examples:
 * - Valid: "Password123!", "A1b2C3d4!"
 * - Invalid: "password", "PASSWORD123", "Password"
 */
export const PASSWORD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+\-=\[\]{}|;:'",.<>\/?]).{8,}$/;

/**
 * Regular expression to validate OTP with the following criteria:
 * - Exactly 6 digits (0-9)
 * - No letters, special characters, or spaces allowed
 *
 * Examples:
 * - Valid: "123456", "000000", "987654"
 * - Invalid: "12345" (too short), "1234567" (too long), "12345a" (contains letter), "12 345" (contains space)
 */
export const OTP_REGEX = /^\d{6}$/;

/**
 * Regular expression to validate ZIP codes.
 * This regex checks for:
 * - An empty string (to allow optional ZIP code fields)
 * - A string containing only digits (to match ZIP codes of any length)
 *
 * Examples:
 * - Valid: "", "12345", "987654321"
 * - Invalid: "123-456", "ABCDE"
 */
export const ZIPCODE_REGEX = /(^$)|(^\d+$)/;

/**
 * Regular expression to validate FirstPromoter Referral code.
 * This regex checks for:
 * - A non-empty string consisting of one or more of the following characters:
 *   - Lowercase letters (a-z)
 *   - Numbers (0-9)
 *   - Hyphens (-)
 *   - Underscores (_)
 *
 * Examples:
 * - Valid: "abc123", "my-code_01", "a_b-c"
 * - Invalid: "ABC123", "my code", "a$b#c"
 */
export const REFERRAL_CODE_REGEX = /^[a-z0-9-_]+$/;
