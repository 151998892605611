'use client';

import { listRecentlyPlayed } from '@/app/[lang]/(shell)/learn/api/recently-played/list-recently-played';
import { CarouselSkeleton } from '@/components/carousel/carousel-skeleton';
import { StyledTab, StyledTabList } from '@/components/styled-tab';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useAuthStore } from '@/stores/auth-store-provider';
import { Trans } from '@lingui/macro';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useInfiniteQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import { useState } from 'react';

const BrazeCardsCarousel = dynamic(
  () =>
    import('@/libs/braze/braze-cards-carousel').then(
      (mod) => mod.BrazeCardsCarousel
    ),
  {
    ssr: false,
    loading: () => <CarouselSkeleton />
  }
);

const RecentlyPlayedCarousel = dynamic(
  () =>
    import(
      '@/app/[lang]/(shell)/learn/components/recently-played/recently-played-carousel'
    ).then((mod) => mod.RecentlyPlayedCarousel),
  {
    ssr: false,
    suspense: true,
    loading: () => <CarouselSkeleton />
  }
);

export function DashboardTabs() {
  const authStore = useAuthStore();
  const [tab, setTab] = useState('recommended');
  const userId = authStore.useTracked.userId() ?? '';

  const graphqlClient = useGraphqlClient();
  const { data: recentlyPlayed } = useInfiniteQuery(
    listRecentlyPlayed(graphqlClient, userId, 50)
  );

  return (
    <TabContext value={tab}>
      <StyledTabList onChange={(_event, value) => setTab(value)}>
        <StyledTab
          color="secondary"
          value="recommended"
          label={
            <Trans id="dashboard.recommendedTab">Recommended for You</Trans>
          }
        />
        {(recentlyPlayed?.length ?? 0) > 0 && (
          <StyledTab
            color="secondary"
            value="continue"
            label={
              <Trans id="dashboard.continueWatchingTab">
                Continue Learning
              </Trans>
            }
          />
        )}
      </StyledTabList>
      <TabPanel value="recommended" sx={{ px: 0, pt: 1 }}>
        <BrazeCardsCarousel cardType="recommended" />
      </TabPanel>
      <TabPanel value="continue" sx={{ px: 0, pt: 1 }}>
        <RecentlyPlayedCarousel />
      </TabPanel>
    </TabContext>
  );
}
