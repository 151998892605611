'use client';

import { setupI18n } from '@lingui/core';
import { I18nProvider as LinguiProvider } from '@lingui/react';
import { I18nSetupData } from '../types';
import { PropsWithChildren } from 'react';

export function I18nProvider({
  children,
  ...setupI18nData
}: PropsWithChildren<I18nSetupData>) {
  return (
    <LinguiProvider i18n={setupI18n(setupI18nData)}>{children}</LinguiProvider>
  );
}
