export const listRecentlyPlayedQuery = /* GraphQL */ `
  query ListRecentlyPlayed(
    $owner: String!
    $entityId: ModelIDKeyConditionInput
  ) {
    listRecentlyPlayedByOwnerAndEntityId(owner: $owner, entityId: $entityId) {
      items {
        id
        lastPlayedAt
        playbackPercentage
        children {
          entityId
          lastPlayedAt
          playbackPercentage
          completeTime
        }
        entityType
        entityId
        images {
          width
          url
          height
          imageType
        }
      }
    }
  }
`;
