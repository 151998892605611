'use client';

import outputs from '@/__generated__/amplify_outputs.json';
import { GraphqlClient } from './types';
import type { Schema } from '@/schema';
import {
  AuthStoreApi,
  authStoreRef,
  useAuthStore
} from '@/stores/auth-store-provider';
import { decodeJWT, LibraryOptions } from '@aws-amplify/core';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/data';
import { useMemo } from 'react';
import { getIdTokenExpiry } from '../cognito/session-to-token';
import { isNearExpiry } from '@/utils/is-near-expiry';

function makeProxyGraphqlClient(authStore: AuthStoreApi) {
  return new Proxy({} as GraphqlClient, {
    get(_target, prop) {
      const isSignedIn = authStore.get.isSignedIn();
      const client = generateClient<Schema>({
        authMode: isSignedIn ? 'userPool' : 'apiKey'
      });
      const value = Reflect.get(client, prop);
      if (typeof value === 'function') {
        return (...args: unknown[]) => Reflect.apply(value, client, args);
      }
      return value;
    }
  });
}

export function useGraphqlClient() {
  const authStore = useAuthStore();
  return useMemo(() => makeProxyGraphqlClient(authStore), [authStore]);
}

const libraryOptionsClient: LibraryOptions = {
  ssr: true,
  Auth: {
    tokenProvider: {
      async getTokens({ forceRefresh } = {}) {
        let session = authStoreRef.current?.get.session();
        if (!session) return null;

        // We'll refresh the token 5 minutes before the expiry
        const tokenExpiry = getIdTokenExpiry(session.idToken);
        const sessionExpired = isNearExpiry(tokenExpiry);

        if (sessionExpired || forceRefresh) {
          session = await authStoreRef.current?.set.refreshSession();
        }

        if (!session) return null;

        return {
          idToken: decodeJWT(session.idToken),
          accessToken: decodeJWT(session.accessToken)
        };
      }
    },
    credentialsProvider: {
      async getCredentialsAndIdentityId(input) {
        let credentials = authStoreRef.current?.get.credentials();

        if (!credentials || isNearExpiry(credentials.credentials.expiration)) {
          credentials =
            await authStoreRef.current?.set.refreshCredentials(input);
        }

        return credentials;
      },
      clearCredentialsAndIdentityId() {}
    }
  }
};

export function configureAmplifyClient() {
  Amplify.configure(outputs, libraryOptionsClient);
}
