'use client';
import { AppProgressBar } from 'next-nprogress-bar';
// Colour set using CSS variables based on the layout
import './app-loading-bar.scss';

/**
 * Loading bar shown while navigating between pages in the app
 */
export function AppLoadingBar() {
  return (
    <AppProgressBar
      delay={200}
      shallowRouting
      options={{ showSpinner: false }}
    />
  );
}
