'use client';

import { CommentSelectionSetResponse } from '@/app/[lang]/(shell)/learn/api/comments/read';
import Box from '@mui/material/Box';
import dynamic from 'next/dynamic';
import { ReactNode, useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import { CardToModal } from './card-to-modal-wrapper';

const EntityComments = dynamic(
  () =>
    import(
      '@/app/[lang]/(shell)/learn/components/comments/entity-comments'
    ).then((mod) => mod.EntityComments),
  { ssr: false }
);

export type DashboardCardWrapperProps = {
  parentId: string;
  children?: ReactNode;
  commentsTitle?: ReactNode;
  disableComments?: boolean;
  parentType: CommentSelectionSetResponse['parentType'];
};

export function DashboardCardWrapper({
  parentId,
  children,
  commentsTitle,
  disableComments = true,
  parentType
}: DashboardCardWrapperProps) {
  const { value: isExpanded, setTrue: open, setFalse: close } = useBoolean();

  return (
    <CardToModal isExpanded={isExpanded} onClose={close}>
      <Box zIndex={1} position="relative">
        {isExpanded ? commentsTitle : children}
      </Box>
      {!disableComments && (
        <Box paddingX={'1rem'}>
          <EntityComments
            threadId={parentId}
            parentId={parentId}
            parentType={parentType}
            onShowMore={open}
            displayTopComments={!isExpanded}
          />
        </Box>
      )}
    </CardToModal>
  );
}
