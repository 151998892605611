'use client';

import useStableCallback from '@/hooks/use-stable-callback';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { useCallback, useEffect } from 'react';
import { getDashboardStreakQueryKey } from './query-options';
import { DashboardStreak, dashboardStreakSelectionSet } from './types';

export function useOnUpdateDashboardStreak(
  owner: string,
  lastActiveDate: string,
  callback?: (item: DashboardStreak) => void
) {
  const queryClient = useQueryClient();
  const queryKey = getDashboardStreakQueryKey(owner, lastActiveDate);
  const graphqlClient = useGraphqlClient();
  const stableCallback = useStableCallback(callback);

  const onSubscribe = useCallback(
    (streak: DashboardStreak) => {
      queryClient.setQueryData<DashboardStreak[]>(queryKey, (data) => {
        if (data && streak?.owner === owner) {
          return produce(data, (draft) => {
            const index = draft.findIndex(
              (draftStreak) => draftStreak.id === streak.id
            );

            if (index > -1) {
              if (dayjs(streak.updatedAt).isAfter(draft[index].updatedAt)) {
                draft[index] = streak;
              }
            } else {
              draft.push(streak);
            }
          });
        }
        return data;
      });
      stableCallback?.(streak);
    },
    [queryClient, queryKey, owner, stableCallback]
  );

  useEffect(() => {
    if (owner) {
      const options = {
        selectionSet: dashboardStreakSelectionSet,
        filter: {
          lastActiveDate: { ge: lastActiveDate }
        }
      };

      const createSubscription =
        graphqlClient.models.DashboardStreak.onCreate(options).subscribe(
          onSubscribe
        );

      const updateSubscription =
        graphqlClient.models.DashboardStreak.onUpdate(options).subscribe(
          onSubscribe
        );

      return () => {
        createSubscription.unsubscribe();
        updateSubscription.unsubscribe();
      };
    }
  }, [graphqlClient, lastActiveDate, owner, onSubscribe]);
}
