import { playerStore } from '@/stores/player-store';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';

export function CloseButton() {
  return (
    <IconButton
      onClick={() => {
        playerStore.set.miniPlayerEntityId(undefined);
      }}
      color="inherit"
      sx={{ ml: '-4px' }}
      className="mini-button vds-icon"
    >
      <CloseOutlined sx={{ fontSize: 20 }} />
    </IconButton>
  );
}
