import { useLingui } from '@lingui/react';
import { DefaultLayoutProps } from '@vidstack/react';
import { useMemo } from 'react';

export function usePlayerTranslations(): DefaultLayoutProps['translations'] {
  const lingui = useLingui();
  return useMemo(
    (): DefaultLayoutProps['translations'] => ({
      AirPlay: lingui.i18n.t({ id: 'player.airPlay', message: 'AirPlay' }),
      Audio: lingui.i18n.t({ id: 'player.audio', message: 'Audio' }),
      Auto: lingui.i18n.t({ id: 'player.auto', message: 'Auto' }),
      Captions: lingui.i18n.t({ id: 'player.captions', message: 'Captions' }),
      Chapters: lingui.i18n.t({ id: 'player.chapters', message: 'Chapters' }),
      'Closed-Captions Off': lingui.i18n.t({
        id: 'player.closedCaptionsOff',
        message: 'Closed-Captions Off'
      }),
      'Closed-Captions On': lingui.i18n.t({
        id: 'player.closedCaptionsOn',
        message: 'Closed-Captions On'
      }),
      Connected: lingui.i18n.t({
        id: 'player.connected',
        message: 'Connected'
      }),
      Connecting: lingui.i18n.t({
        id: 'player.connecting',
        message: 'Connecting'
      }),
      Continue: lingui.i18n.t({ id: 'player.continue', message: 'Continue' }),
      Default: lingui.i18n.t({ id: 'player.default', message: 'Default' }),
      Disconnected: lingui.i18n.t({
        id: 'player.disconnected',
        message: 'Disconnected'
      }),
      'Enter Fullscreen': lingui.i18n.t({
        id: 'player.enterFullscreen',
        message: 'Enter Fullscreen'
      }),
      'Enter PiP': lingui.i18n.t({
        id: 'player.enterPiP',
        message: 'Enter PiP'
      }),
      'Exit Fullscreen': lingui.i18n.t({
        id: 'player.exitFullscreen',
        message: 'Exit Fullscreen'
      }),
      'Exit PiP': lingui.i18n.t({ id: 'player.exitPiP', message: 'Exit PiP' }),
      'Google Cast': lingui.i18n.t({
        id: 'player.googleCast',
        message: 'Google Cast'
      }),
      LIVE: lingui.i18n.t({ id: 'player.live', message: 'LIVE' }),
      Mute: lingui.i18n.t({ id: 'player.mute', message: 'Mute' }),
      Normal: lingui.i18n.t({ id: 'player.normal', message: 'Normal' }),
      Off: lingui.i18n.t({ id: 'player.off', message: 'Off' }),
      Pause: lingui.i18n.t({ id: 'player.pause', message: 'Pause' }),
      Play: lingui.i18n.t({ id: 'player.play', message: 'Play' }),
      Quality: lingui.i18n.t({ id: 'player.quality', message: 'Quality' }),
      Replay: lingui.i18n.t({ id: 'player.replay', message: 'Replay' }),
      Reset: lingui.i18n.t({ id: 'player.reset', message: 'Reset' }),
      Seek: lingui.i18n.t({ id: 'player.seek', message: 'Seek' }),
      'Seek Backward': lingui.i18n.t({
        id: 'player.seekBackward',
        message: 'Seek Backward'
      }),
      'Seek Forward': lingui.i18n.t({
        id: 'player.seekForward',
        message: 'Seek Forward'
      }),
      Settings: lingui.i18n.t({ id: 'player.settings', message: 'Settings' }),
      'Skip To Live': lingui.i18n.t({
        id: 'player.skipToLive',
        message: 'Skip To Live'
      }),
      Speed: lingui.i18n.t({ id: 'player.speed', message: 'Speed' }),
      Unmute: lingui.i18n.t({ id: 'player.unmute', message: 'Unmute' }),
      Volume: lingui.i18n.t({ id: 'player.volume', message: 'Volume' }),
      Font: lingui.i18n.t({ id: 'player.font', message: 'Font' }),
      Track: lingui.i18n.t({ id: 'player.track', message: 'Track' }),
      Fullscreen: lingui.i18n.t({
        id: 'player.fullscreen',
        message: 'Fullscreen'
      }),
      Loop: lingui.i18n.t({ id: 'player.loop', message: 'Loop' }),
      Playback: lingui.i18n.t({ id: 'player.playback', message: 'Playback' }),
      Text: lingui.i18n.t({ id: 'player.text', message: 'Text' }),
      Download: lingui.i18n.t({ id: 'player.download', message: 'Download' }),
      Announcements: lingui.i18n.t({
        id: 'player.announcements',
        message: 'Announcements'
      }),
      Accessibility: lingui.i18n.t({
        id: 'player.accessibility',
        message: 'Accessibility'
      }),
      Boost: lingui.i18n.t({ id: 'player.boost', message: 'Boost' }),
      'Caption Styles': lingui.i18n.t({
        id: 'player.captionStyles',
        message: 'Caption Styles'
      }),
      'Captions look like this': lingui.i18n.t({
        id: 'player.captionsLookLikeThis',
        message: 'Captions look like this'
      }),
      Disabled: lingui.i18n.t({ id: 'player.disabled', message: 'Disabled' }),
      'Display Background': lingui.i18n.t({
        id: 'player.displayBackground',
        message: 'Display Background'
      }),
      Family: lingui.i18n.t({ id: 'player.family', message: 'Family' }),
      'Keyboard Animations': lingui.i18n.t({
        id: 'player.keyboardAnimations',
        message: 'Keyboard Animations'
      }),
      PiP: lingui.i18n.t({ id: 'player.pip', message: 'PiP' }),
      Size: lingui.i18n.t({ id: 'player.size', message: 'Size' }),
      Color: lingui.i18n.t({ id: 'player.color', message: 'Color' }),
      Opacity: lingui.i18n.t({ id: 'player.opacity', message: 'Opacity' }),
      Shadow: lingui.i18n.t({ id: 'player.shadow', message: 'Shadow' }),
      'Text Background': lingui.i18n.t({
        id: 'player.textBackground',
        message: 'Text Background'
      })
    }),
    [lingui]
  );
}
