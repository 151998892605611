import { EntityType } from '@/__generated__/API';
import type { Schema } from '@/schema';
import { SelectionSet } from 'aws-amplify/api';

export type WebUpsellOfferingMappingConfig = {
  default?: string;
  feature?: {
    'ai-brendon'?: string;
  };
  entity?: Record<string, string>;
  entityType?: {
    course?: string;
    event?: string;
    challenge?: string;
    dailyfire?: string;
    'featured-video'?: string;
    replay?: string;
  };
  entitlement?: Record<string, string>;
};

export type UpsellOfferingOptions = {
  requiredEntitlements?: string[] | null;
  entityId?: string | null;
  entityType?: EntityType;
  feature?: keyof NonNullable<WebUpsellOfferingMappingConfig['feature']>;
};

export const getOfferingSelectionSet = [
  'id',
  'readableTitle',
  'redirectUrl',
  'image.url',
  'image.width',
  'image.height',
  'image.cdnId',
  'media.locator',
  'media.title',
  'media.assetType',
  'heading',
  'description',
  'cta',
  'products.product.id',
  'products.product.name',
  'products.product.description',
  'products.product.productNamespace',
  'products.product.entitlements.identifier',
  'products.product.billingInterval',
  'products.product.priceId',
  'products.product.subscriptionGroup',
  'overrides.skipCreditCard'
] as const;

export type Offering = SelectionSet<
  Schema['Offering']['type'],
  typeof getOfferingSelectionSet
>;

export type RevenueCatSubscriber = Omit<
  Schema['RevenueCatSubscriber']['type'],
  'entitlements' | 'nonSubscriptions' | 'otherPurchases' | 'subscriptions'
> & {
  entitlements: Record<string, RevenueCatEntitlement>;
  nonSubscriptions: Record<string, RevenueCatNonSubscriptionProduct[]>;
  subscriptions: Record<string, RevenueCatSubscription>;
};

export type RevenueCatEntitlement = {
  expires_date: string;
  grace_period_expires_date: string | null;
  product_identifier: string;
  product_plan_identifier?: string;
  purchase_date: string;
};

export type RevenueCatStore =
  | 'app_store'
  | 'mac_app_store'
  | 'play_store'
  | 'amazon'
  | 'stripe';

export type RevenueCatNonSubscriptionProduct = {
  id: string;
  is_sandbox: boolean;
  purchase_date: string;
  store: RevenueCatStore;
};

export type RevenueCatSubscription = {
  auto_resume_date?: string;
  billing_issues_detected_at: string | null;
  expires_date: string;
  grace_period_expires_date: string | null;
  is_sandbox: boolean;
  original_purchase_date: string;
  ownership_type: 'PURCHASED' | 'FAMILY_SHARED';
  period_type: 'normal' | 'trial' | 'intro';
  purchase_date: string;
  refunded_at?: string;
  store: RevenueCatStore | 'promotional';
  unsubscribe_detected_at?: string;
};
