'use client';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useState } from 'react';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import Badge from '@mui/material/Badge';
import { useRouter } from '@/hooks/use-router';

export type DashboardDatepickerProps = {
  date: string;
};

function startOfMonth(date: dayjs.ConfigType) {
  return dayjs.tz(date).startOf('month').format('YYYY-MM-DD');
}

function endOfMonth(date: dayjs.ConfigType) {
  return dayjs.tz(date).endOf('month').format('YYYY-MM-DD');
}

function HighlightedDay(
  props: PickersDayProps<Dayjs> & { highlightedDates?: string[] }
) {
  const { highlightedDates = [], day, outsideCurrentMonth, ...other } = props;

  const isHighlighted =
    !outsideCurrentMonth &&
    highlightedDates.includes(props.day.format('YYYY-MM-DD'));

  return (
    <Badge
      key={props.day.toString()}
      color="secondary"
      overlap="circular"
      variant="dot"
      invisible={!isHighlighted}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export function DashboardDatepicker({ date }: DashboardDatepickerProps) {
  const [monthDate, setMonthDate] = useState(startOfMonth(date));
  const graphqlClient = useGraphqlClient();
  const router = useRouter();

  const queryKey = ['dashboard', 'devtools', 'listCards', monthDate];
  const { data: highlightedDates, isFetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const { data } = await graphqlClient.models.DashboardCard.list({
        filter: {
          and: [
            { date: { ge: monthDate } },
            { date: { le: endOfMonth(monthDate) } }
          ]
        },
        selectionSet: ['date'],
        limit: 1000
      });
      return data?.map((item) => item.date);
    }
  });

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      router.push(`/?date=${newDate.format('YYYY-MM-DD')}`);
      router.refresh();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        onChange={handleDateChange}
        onMonthChange={(month) => setMonthDate(startOfMonth(month))}
        loading={isFetching}
        renderLoading={() => <DayCalendarSkeleton />}
        value={dayjs.tz(date)}
        format="YYYY-MM-DD"
        slots={{
          day: HighlightedDay
        }}
        slotProps={{
          textField: {
            size: 'small',
            sx: {
              maxWidth: 160,
              backgroundColor: 'background.paper',
              borderRadius: 4,
              [`.${outlinedInputClasses.notchedOutline}`]: {
                borderRadius: 4
              }
            }
          },
          day: {
            highlightedDates
          } as any
        }}
      />
    </LocalizationProvider>
  );
}
