import OpenInNewOutlined from '@mui/icons-material/OpenInNewOutlined';
import IconButton from '@mui/material/IconButton';

export function OpenLink({ href }: { href?: string }) {
  if (!href) {
    return null;
  }
  return (
    <IconButton
      href={href}
      sx={{ ml: '-4px' }}
      color="inherit"
      className="mini-button vds-icon"
    >
      <OpenInNewOutlined sx={{ fontSize: 20 }} />
    </IconButton>
  );
}
