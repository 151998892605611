'use client';

import { AssetType, EntityLiveState, EntityType } from '@/__generated__/API';
import { useUpdatedValue } from '@/hooks/use-updated-value';
import { playerStore } from '@/stores/player-store';
import { useGraphqlClient } from '@/libs/amplify/client';
import compact from 'lodash-es/compact';
import { useQuery } from '@tanstack/react-query';
import { MediaPlayEvent, TrackProps } from '@vidstack/react';
import { OutPortal } from 'react-reverse-portal';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { VidstackPlayerProps } from './components/vidstack-player';
import { getVttContent } from './utils/get-vtt-content';
import { createBlobUrl } from '@/utils/create-blob-url';
import { throwGraphqlError } from '@/utils/throw-graphql-error';

export type PlayerProps = VidstackPlayerProps & {
  entityId: string;
  assetType: AssetType;
  locator: string;
  initialProgress?: number | null;
  disableMiniPlayer?: boolean;
  viewerCount?: number;
  requiredEntitlements?: string[];
  enforceEntitlementsAt?: number;
  entityLiveState?: EntityLiveState; 
  entityType?: EntityType;
  parentEntityId?: string;
};

export function Player(props: PlayerProps) {
  const { assetType, locator, initialProgress } = props;
  const entityIdRef = useUpdatedValue(props.entityId);
  const playerInstance = playerStore.useTracked.playerInstance(
    entityIdRef.current
  );
  const playerInitialized = !!playerInstance;
  const playerRef = useUpdatedValue(playerInstance?.ref?.current);
  const onPlayRef = useUpdatedValue(props.onPlay);
  const thumbnailRef = useRef<string>();

  const graphqlClient = useGraphqlClient();

  const { data } = useQuery({
    queryKey: ['getPlaybackMedia', { assetType, locator }],
    async queryFn() {
      const { data, errors } = await graphqlClient.queries.getPlaybackMedia({
        assetType,
        locator
      });
      throwGraphqlError(errors);
      return data;
    }
  });

  const thumbnails = useMemo(() => {
    if (data?.thumbPreview) {
      const vttContent = getVttContent(data.thumbPreview);
      if (vttContent) {
        thumbnailRef.current = createBlobUrl(vttContent, 'text/vtt');
        return thumbnailRef.current;
      }
    }
    return '';
  }, [data?.thumbPreview]);

  const tracks = useMemo(() => compact(data?.tracks), [data?.tracks]);

  useEffect(() => {
    if (initialProgress) {
      let unsubscribe = () => {};

      setTimeout(() => {
        const player = playerInstance?.ref?.current;
        if (player) {
          unsubscribe = player.subscribe(({ started, duration }) => {
            if (duration && !started) {
              setTimeout(() => {
                player.currentTime = (initialProgress / 100) * player.duration;
              });
            }
          });
        }
      });

      return () => {
        try {
          unsubscribe?.();
        } catch (e) {}
      };
    }
  }, [playerInstance, initialProgress]);

  useEffect(
    () => () => {
      const thumbnail = thumbnailRef.current;
      if (thumbnail) {
        try {
          URL.revokeObjectURL(thumbnail);
        } catch {}
      }
    },
    []
  );

  useEffect(() => {
    const {
      assetType,
      locator,
      entityId,
      initialProgress,
      disableMiniPlayer,
      ...restProps
    } = props;
    const playerProps: VidstackPlayerProps = {
      ...restProps,
      thumbnails: props.thumbnails || thumbnails,
      viewType:
        props.viewType || assetType === AssetType.AUDIO_URL ? 'audio' : 'video',
      poster: props.poster || data?.poster || '',
      src: props.src || data?.src || '',
      tracks: props.tracks || (tracks as TrackProps[])
    };

    if (!playerInitialized) {
      playerStore.set.initPlayer(
        entityIdRef.current,
        playerProps,
        window.location.pathname
      );
    } else {
      playerStore.set.updatePlayerProps(entityIdRef.current, playerProps);
    }
  }, [
    assetType,
    data?.poster,
    data?.src,
    playerInitialized,
    props,
    thumbnails,
    tracks,
    entityIdRef
  ]);

  useEffect(() => {
    const miniPlayerEntityId = playerStore.get.miniPlayerEntityId();
    if (
      miniPlayerEntityId &&
      entityIdRef.current &&
      miniPlayerEntityId === entityIdRef.current
    ) {
      playerStore.set.miniPlayerEntityId(undefined);
    }
  }, [entityIdRef]);

  useEffect(
    () => () => {
      const player = playerRef.current;
      if (player) {
        const playing = player.state.playing;
        if (playing && !props.disableMiniPlayer) {
          playerStore.set.miniPlayerEntityId(entityIdRef.current);
        }
      }
    },
    [playerRef, entityIdRef, props.disableMiniPlayer]
  );

  const handlePlay = useCallback(
    (nativeEvent: MediaPlayEvent) => {
      playerStore.set.pauseOtherPlayers(entityIdRef.current);
      if (playerStore.get.miniPlayerEntityId()) {
        playerStore.set.miniPlayerEntityId(undefined);
      }
      onPlayRef.current?.(nativeEvent);
    },
    [onPlayRef, entityIdRef]
  );

  return (
    playerInstance && (
      <OutPortal node={playerInstance.node} onPlay={handlePlay} />
    )
  );
}
