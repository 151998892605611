import { QueryKey } from '@tanstack/react-query';

export type RecentlyPlayedFilterInput = {
  entityId: string;
  parentEntityId?: string | null;
};

export function getRecentlyPlayedVariables({
  entityId,
  parentEntityId
}: RecentlyPlayedFilterInput) {
  return {
    entityId: parentEntityId || entityId
  };
}

export function getRecentlyPlayedQueryKey(
  owner: string,
  variablesInput: RecentlyPlayedFilterInput
) {
  return [
    'GetRecentlyPlayed',
    owner,
    getRecentlyPlayedVariables(variablesInput)
  ] as QueryKey;
}

export function listRecentlyPlayedQueryKey(owner: string) {
  return ['ListRecentlyPlayed', owner] as QueryKey;
}
