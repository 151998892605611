'use client';

import useStableCallback from '@/hooks/use-stable-callback';
import { isEditableElement } from '@/utils/is-editable-element';
import { useEffect, ReactNode, useRef } from 'react';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { CloseButton } from '@/components/close-button';

const StyledPaper = styled(Paper, {
  shouldForwardProp: (propName) => propName !== 'isExpanded'
})<{ isExpanded: boolean }>(({ theme, isExpanded }) => ({
  position: 'relative',
  pointerEvents: 'auto',
  paddingBottom: 12,
  ...(isExpanded && {
    minHeight: '100%',
    maxWidth: theme.breakpoints.values.lg,
    [theme.breakpoints.up('sm')]: {
      margin: '32px',
      minHeight: 'auto'
    },
    [theme.breakpoints.up('lg')]: {
      margin: '32px auto'
    }
  })
}));

export type CardToModalProps = {
  children?: ReactNode;
  onClose: () => void;
  isExpanded: boolean;
};

export function CardToModal({
  children,
  onClose,
  isExpanded = false
}: CardToModalProps) {
  const rootRef = useRef<HTMLDivElement>(null);

  const handleClose = useStableCallback(() => {
    if (isExpanded) {
      onClose();
    }
  });

  useEffect(() => {
    if (isExpanded) {
      const handleEscape = (event: KeyboardEvent) => {
        if (
          event.key === 'Escape' &&
          !isEditableElement(event.target as HTMLElement)
        ) {
          handleClose();
        }
      };

      document.addEventListener('keyup', handleEscape);

      // Cleanup the event listener on component unmount
      return () => {
        document.removeEventListener('keyup', handleEscape);
      };
    }
  }, [isExpanded, handleClose]);

  useEffect(() => {
    // Since we are manipulating the parent of the Dom, React dom wont be affected.
    if (isExpanded) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isExpanded]);

  return (
    <Box ref={rootRef}>
      <Modal
        open
        disablePortal
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        disableScrollLock
        hideBackdrop={!isExpanded}
        onClose={handleClose}
        container={() => rootRef.current!}
        className={isExpanded ? 'expanded-modal' : 'closed-modal'}
        sx={isExpanded ? {} : { position: 'static' }}
      >
        <Box
          width="100%"
          height="100%"
          sx={isExpanded ? { overflow: 'hidden auto' } : {}}
          onClick={handleClose}
        >
          <StyledPaper
            onClick={(event) => event.stopPropagation()}
            isExpanded={isExpanded}
          >
            {isExpanded && (
              <CloseButton
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  zIndex: 2,
                  backgroundColor: 'background.default',
                  '&:hover, &:focus': {
                    backgroundColor: 'background.paper'
                  }
                }}
                onClick={handleClose}
              />
            )}
            {children}
          </StyledPaper>
        </Box>
      </Modal>
    </Box>
  );
}
