import { Box, Button } from '@mui/material';
import { Trans } from '@lingui/react';

export type AudioBrickedProps = {
  showUpsell: (forced: boolean) => void;
}

export function AudioBricked({ showUpsell }: AudioBrickedProps) {
  
  const handleClickUpgrade = () => {
    analytics.track('Click', {
      element_id: 'show-upsell-button',
      parent_id: 'Play & Brick',
      type: 'BUTTON'
    });
    showUpsell(true);
  };
  
  return (
    <Box
      id="bricked-overlay"
      sx={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        zIndex: 999
      }}
    >
      <Button
        onClick={handleClickUpgrade}
      >
        <Trans id="common.upgrade-your-plan">
          Upgrade Your Plan
        </Trans>
      </Button>
    </Box>
  );
}
