import { theme } from '@/utils/theme';
import { Breakpoint } from '@mui/material/styles';
import { CSSProperties } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { SwiperProps } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';

/**
 * A mapping of MUI breakpoints to the number of slides displayed per view in the carousel.
 */
export const breakpointSlidesPerView: Record<Breakpoint, number> = {
  xl: 4,
  lg: 3,
  md: 2,
  sm: 1,
  xs: 1
};

/**
 * Creates a Swiper `breakpoints` configuration object that adjusts the number of visible slides
 * per view based on the specified breakpoints.
 *
 * @param breakpointMap - A record mapping each MUI breakpoint to Swiper options.
 * @param slidesPerViewPerBreakpoint - Slides per view for all breakpoints.
 * @returns A Swiper `breakpoints` configuration object where each breakpoint key
 * is mapped to its corresponding Swiper options, including `slidesPerView`.
 *
 * @example
 * const breakpoints = createCarouselBreakpoints({
 *   xs: { slidesPerView: 1 },
 *   md: { slidesPerView: 2 }
 * });
 * // Returns an object suitable for Swiper's breakpoints prop.
 */
export function createCarouselBreakpoints(
  breakpointMap: Partial<Record<Breakpoint, SwiperOptions | undefined>>,
  slidesPerViewPerBreakpoint?: number
): SwiperProps['breakpoints'] {
  return theme.breakpoints.keys.reduce<SwiperProps['breakpoints']>(
    (all, key) => ({
      ...all,
      [theme.breakpoints.values[key]]: {
        slidesPerView:
          slidesPerViewPerBreakpoint || breakpointSlidesPerView[key],
        ...breakpointMap[key]
      }
    }),
    {}
  );
}

/**
 * Generates a CSS properties object for the Carousel component based on the Swiper props.
 * This includes dynamic styling based on the `spaceBetween` property and the number of slides
 * per view at each breakpoint.
 *
 * @param swiperProps - The SwiperProps object containing the `spaceBetween` value and `breakpoints` configuration.
 * @returns A CSS properties object with custom properties for spacing and slide counts.
 *
 * @example
 * const styles = createCarouselStyles({
 *   spaceBetween: 16,
 *   breakpoints: {
 *     640: { slidesPerView: 2 }
 *   }
 * });
 * // Returns a CSS properties object for inline styling.
 */
export function createCarouselStyles({
  spaceBetween,
  breakpoints
}: SwiperProps): CSSProperties {
  return {
    '--space-between': `${spaceBetween || 0}px`,
    ...theme.breakpoints.keys.reduce<Record<string, number>>(
      (all, key) => ({
        ...all,
        [`--slides-${key}`]:
          (breakpoints?.[theme.breakpoints.values[key]]
            ?.slidesPerView as number) || breakpointSlidesPerView[key]
      }),
      {}
    )
  } as CSSProperties;
}

/**
 * Default properties for the Swiper component used in the Carousel.
 * Includes commonly used modules and basic configuration.
 */
export const defaultSwiperProps: SwiperProps = {
  modules: [Navigation, Pagination],
  freeMode: true,
  spaceBetween: 16,
  grabCursor: true
};
