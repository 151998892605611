import { createStore } from 'zustand-x';
import { EntityItem } from '@/api/entities/types';

type EntitlementStore = {
  // Dev only store values
  devEntitlements: string[];
  devOverride: boolean;
  devOpen: boolean;
  entity: EntityItem | null;

  // User store values
  upsellOfferingModalId: string;
  isRefreshingEntitlements: boolean;
};

export const entitlementsStore = createStore('entitlements')<EntitlementStore>({
  devEntitlements: [],
  devOverride: false,
  devOpen: false,
  entity: null,
  upsellOfferingModalId: '',
  isRefreshingEntitlements: false
}).extendActions((set) => ({
  resetState: () => {
    set.devOpen(false);
    set.devOverride(false);
    set.devOpen(false);
    set.devEntitlements([]);
    set.entity(null);
  }
}));
