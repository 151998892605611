import { useCallback, useRef } from 'react';

/**
 * Custom hook to create a stable callback that always has the latest reference to a given callback function.
 * This helps to avoid issues with dependencies in other hooks such as useEffect.
 *
 * @param callback - The callback function whose reference you want to stabilize.
 * @returns A stable callback function that always refers to the latest provided callback.
 */
export default function useStableCallback<
  ArgsTypes extends Array<any> = [],
  ReturnValue = any
>(callback: ((...args: ArgsTypes) => ReturnValue) | null | undefined) {
  const ref = useRef(callback);
  ref.current = callback;
  return useCallback(
    (...args: ArgsTypes) =>
      ref.current?.(...args) as typeof callback extends null
        ? null
        : typeof callback extends undefined
          ? undefined
          : ReturnValue,
    [ref]
  );
}
