/**
 * Safely parses a JSON string into a specified type or default type.
 * @param value - The JSON string to parse.
 * @returns Parsed JSON value or null if value is null.
 */
export function safeJsonParse<T = any>(value?: string | null): T | null {
  if (value === null) {
    return null;
  }
  try {
    return JSON.parse(value ?? '');
  } catch (e) {
    return value as unknown as T;
  }
}

/**
 * Safely converts a value to a JSON string.
 * @param value - The value to convert to JSON.
 * @returns A JSON string representing the value.
 */
export function safeJsonStringify<T = any>(value: T): string {
  try {
    return JSON.stringify(value);
  } catch (e) {
    return value as unknown as string;
  }
}
