'use client';

import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import type { DashboardStreakDatesProps } from './dashboard-streak-dates';
import { useSuspenseStreakInfo } from '../hooks/use-suspense-streak-info';

export function StreakDateButton({
  date,
  weekDates,
  weekDate
}: DashboardStreakDatesProps & { weekDate: string }) {
  const { streakDates } = useSuspenseStreakInfo(date, weekDates);
  const isCurrentDate = date === weekDate;
  const isCompleted = streakDates.includes(weekDate);

  return (
    <Button
      disableElevation
      color="secondary"
      sx={{
        width: 32,
        height: 32,
        minWidth: 0,
        fontWeight: 'medium',
        fontSize: 'medium',
        borderColor: isCurrentDate
          ? isCompleted
            ? 'secondary.dark'
            : 'secondary.main'
          : 'transparent',
        borderStyle: 'solid',
        borderWidth: 2,
        '&:hover': {
          borderWidth: 2
        }
      }}
      size="small"
      variant={isCurrentDate && !isCompleted ? 'outlined' : 'contained'}
      disabled={!isCurrentDate && !isCompleted}
    >
      {dayjs.tz(weekDate).locale('en').format('dd').slice(0, 1)}
    </Button>
  );
}
