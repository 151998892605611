import { SocialProof } from '@/__generated__/API';
import {
  getSocialProofQueryKey,
  SocialProofQueryResponse
} from '@/api/social-proof/read';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export type SocialProofUpdate = Partial<
  Pick<
    SocialProof,
    'sharesCount' | 'joinCount' | 'likesCount' | 'commentsCount' | 'rsvpCount'
  >
>;

export function useUpdateSocialProofInStore() {
  const queryClient = useQueryClient();

  return useCallback(
    (
      parentId: SocialProof['parentId'],
      updater: (old: SocialProofQueryResponse | undefined) => SocialProofUpdate
    ) => {
      queryClient.setQueryData<SocialProofQueryResponse | undefined>(
        getSocialProofQueryKey(parentId),
        (old) => {
          return {
            parentId,
            entityId: parentId,
            likesCount: old?.likesCount ?? 0,
            joinCount: old?.joinCount ?? 0,
            commentsCount: old?.commentsCount ?? 0,
            sharesCount: old?.sharesCount ?? 0,
            viewerCount: old?.viewerCount ?? 0,
            rsvpCount: old?.rsvpCount ?? 0,
            version: (old?.version ?? 0) + 1,
            ...updater(old)
          };
        }
      );
    },
    [queryClient]
  );
}
