'use client';

import { DayjsUpdater } from '@/components/dayjs-updater';
import { PlayerProvider } from '@/components/player/components/player-provider';
import { useRenderCount } from '@/hooks/use-render-count';
import { I18nProvider } from '@/i18n';
import { I18nSetupData } from '@/i18n/types';
import { configureAmplifyClient } from '@/libs/amplify/client';
import '@/libs/dayjs';
import { InitializeDayjs } from '@/libs/dayjs';
import { HashDeepLinks } from '@/libs/deeplinks/hash-deep-links';
import { getQueryClient } from '@/libs/react-query/client';
import { AppSnackbarProvider } from '@/providers/app-snackbar-provider';
import { AuthProvider } from '@/providers/auth-provider';
import { AppStoreProvider } from '@/stores/app-store-provider';
import { createLocalizedTheme } from '@/utils/theme';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import CssBaseline from '@mui/material/CssBaseline';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import InitColorSchemeScript from '@mui/system/InitColorSchemeScript';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactQueryStreamedHydration } from '@tanstack/react-query-next-experimental';
import { Session } from 'next-auth';
import dynamic from 'next/dynamic';
import { ReactNode, useState } from 'react';
import { StatsigProvider } from '@/providers/statsig-provider';
import { ConfirmNavigationProvider } from '@/providers/confirm-navigation-provider';

const UpsellProvider = dynamic(
  () =>
    import('@/entitlements/components/upsell/upsell-provider').then(
      (mod) => mod.UpsellProvider
    ),
  { ssr: false }
);

const UpsellRedirectProvider = dynamic(
  () =>
    import(
      '@/entitlements/components/upsell-redirect/upsell-redirect-provider'
    ).then((mod) => mod.UpsellRedirectProvider),
  { ssr: false }
);

const FeedbackProvider = dynamic(
  () => import('@/libs/feedback/provider').then((mod) => mod.FeedbackProvider),
  { ssr: false }
);
const InitializeDataDog = dynamic(
  () => import('@/libs/datadog').then((mod) => mod.InitializeDataDog),
  { ssr: false }
);
const InitializeRudderstack = dynamic(
  () => import('@/libs/rudderstack').then((mod) => mod.InitializeRudderstack),
  { ssr: false }
);
const InitializeBraze = dynamic(
  () => import('@/libs/braze').then((mod) => mod.InitializeBraze),
  { ssr: false }
);
const InitializeBranchIO = dynamic(
  () => import('@/libs/branch.io').then((mod) => mod.InitializeBranchIO),
  { ssr: false }
);

configureAmplifyClient();

type ProvidersProps = {
  children: ReactNode;
  colorMode: 'light' | 'dark';
  i18nSetupData: I18nSetupData;
  lang: string;
  session: Session | null;
};

/*
  This component is designed to ensure optimal performance by rendering only once.
  It's crucial for maintaining the server state synced to the client before even the first render.
  If there's a need to perform operations that might cause re-renders, those should be encapsulated
  within a separate component or handled in a way that doesn't affect this component's rendering.
*/
export function Providers({
  children,
  colorMode,
  i18nSetupData,
  lang,
  session
}: ProvidersProps) {
  const renderCount = useRenderCount();
  const [queryClient] = useState(getQueryClient());

  if (renderCount() > 1 && process.env.NODE_ENV !== 'production') {
    console.warn(
      `Rendered Count: ${renderCount()}. This component is designed to ensure optimal performance by rendering only once.`
    );
  }

  const localizedTheme = createLocalizedTheme(lang);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryStreamedHydration>
        <I18nProvider {...i18nSetupData}>
          <AuthProvider session={session}>
            <AppStoreProvider colorMode={colorMode}>
              <StatsigProvider>
                <InitColorSchemeScript defaultMode={colorMode} />
                <InitializeDataDog />
                <InitializeRudderstack />
                <InitializeBranchIO />
                <InitializeDayjs lang={lang} />
                <DayjsUpdater />
                <ConfirmNavigationProvider>
                  <AppRouterCacheProvider>
                    <InitializeBraze />
                    <CssVarsProvider
                      defaultMode={colorMode}
                      theme={localizedTheme}
                    >
                      <CssBaseline />
                      {/*<WebVitals />*/}
                      {children}
                      <HashDeepLinks />
                      <PlayerProvider />
                      <AppSnackbarProvider />
                      <FeedbackProvider />
                      <UpsellProvider />
                      <UpsellRedirectProvider />
                    </CssVarsProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </AppRouterCacheProvider>
                </ConfirmNavigationProvider>
              </StatsigProvider>
            </AppStoreProvider>
          </AuthProvider>
        </I18nProvider>
      </ReactQueryStreamedHydration>
    </QueryClientProvider>
  );
}
