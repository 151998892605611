import { SocialProof } from '@/__generated__/API';
import { GraphqlClient } from '@/libs/amplify/types';
import { Schema } from '@/schema';
import { throwGraphqlError } from '@/utils/throw-graphql-error';
import { SelectionSet } from 'aws-amplify/api';

export const getSocialProofQueryKey = (parentId: SocialProof['parentId']) => [
  'socialProof',
  parentId
];

export const socialProofSelectionSet = [
  'joinCount',
  'likesCount',
  'commentsCount',
  'sharesCount',
  'viewerCount',
  'rsvpCount',
  'parentId',
  'entityId',
  'version'
] as const;

export type SocialProofQueryResponse = SelectionSet<
  Schema['SocialProof']['type'],
  typeof socialProofSelectionSet
>;

export function getSocialProofQuery(
  parentId: SocialProof['parentId'],
  graphqlClient: GraphqlClient
) {
  return {
    queryKey: getSocialProofQueryKey(parentId),
    queryFn: async () => {
      // Get the social proof for the current entity
      const { data, errors } = await graphqlClient.models.SocialProof.get(
        { parentId },
        {
          selectionSet: socialProofSelectionSet
        }
      );

      throwGraphqlError(errors);

      return data;
    },
    enabled: !!parentId
  };
}
