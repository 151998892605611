'use client';

import { checkForEntitlements } from '@/entitlements/utils';
import { useCallback, useMemo } from 'react';
import { useGetEntitlements } from '../api/get';
import { useDevEntitlements } from '../devtools/use-dev-entitlements';
import { Entitlement } from '../enums';

export function useEntitlements(requiredEntitlements?: string[] | null) {
  const { data: apiUserEntitlements = [], isLoading } = useGetEntitlements();
  const { isDev, devUserEntitlements } = useDevEntitlements();

  const userEntitlements = useMemo(
    () => (isDev ? devUserEntitlements : apiUserEntitlements),
    [apiUserEntitlements, devUserEntitlements, isDev]
  );

  const checkRequiredEntitlements = useCallback(
    (requiredEntitlements?: string[] | null) =>
      checkForEntitlements(userEntitlements, requiredEntitlements),
    [userEntitlements]
  );

  const isUltraUser = useMemo(
    () => userEntitlements.some((entitlement) => entitlement.includes('ULTRA')),
    [userEntitlements]
  );

  const isCHPCUser = useMemo(
    () => userEntitlements.some((entitlement) => entitlement.includes('CHPC')),
    [userEntitlements]
  );

  const chpcEntitlements = useMemo(() => {
    return [
      Entitlement.CHPC_ACTIVE,
      Entitlement.CHPC_CHARGE,
      Entitlement.CHPC_DECLARATION,
      Entitlement.CHPC_LEADERSHIP,
      Entitlement.CHPC_NEW
    ]
  }, []);

  const entityRequiresCHPC = useMemo(() => {
    return (requiredEntitlements || [])
      .some(requiredEntitlement => chpcEntitlements.includes(requiredEntitlement as Entitlement));
  }, [chpcEntitlements, requiredEntitlements]);

  const entityRequiresUltra = useMemo(() => {
    return (requiredEntitlements || [])
      .some(requiredEntitlement => requiredEntitlement as Entitlement === Entitlement.ULTRA);
  }, [requiredEntitlements]);

  return useMemo(
    () => ({
      checkForEntitlements: checkRequiredEntitlements,
      userEntitlements,
      isUltraUser,
      isCHPCUser,
      isUltraOrCHPCUser: isUltraUser || isCHPCUser,
      ...checkRequiredEntitlements(requiredEntitlements),
      isLoading,
      chpcEntitlements,
      entityRequiresCHPC,
      entityRequiresUltra
    }),
    [
      checkRequiredEntitlements,
      userEntitlements,
      requiredEntitlements,
      isUltraUser,
      isCHPCUser,
      isLoading,
      chpcEntitlements,
      entityRequiresCHPC,
      entityRequiresUltra
    ]
  );
}
