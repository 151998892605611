import { kebabCase } from 'lodash-es';
import partition from 'lodash-es/partition';
import { useCallback, useMemo } from 'react';
import { UpsellOfferingOptions, WebUpsellOfferingMappingConfig } from './types';

/**
 * Determines the entitlements a user currently has and identifies which are missing.
 *
 * @param userEntitlements - A list of entitlements the user currently has.
 * @param requiredEntitlements - A list of entitlements required to check against.
 * @returns An object containing:
 * - `existingEntitlements`: A list of entitlements that the user has from the required entitlements.
 * - `missingEntitlements`: A list of entitlements that the user is missing from the required entitlements.
 * - `isEntitled`: A boolean indicating whether the user has at least one of the required entitlements.
 */
export function checkForEntitlements(
  userEntitlements: string[],
  requiredEntitlements?: string[] | null
) {
  const [existingEntitlements, missingEntitlements] = partition(
    requiredEntitlements,
    (entitlement) => userEntitlements.includes(entitlement)
  );

  const isEntitled = (requiredEntitlements || []).length > 0
    ? existingEntitlements.length > 0
    : true;

  return {
    existingEntitlements,
    missingEntitlements,
    isEntitled
  };
}

/**
 * Finds the appropriate upsell offering ID based on the provided configuration and context.
 *
 * @param config - A statsig config mapping that defines entitlement-based, entity-based,
 * entity type-based, and feature-based mappings for upsell offerings.
 * Reference: https://console.statsig.com/7pny0jbjAPTYMt4Aqes4p6/dynamic_configs/web_upsell_offering_mapping
 * @param options - Options for determining the upsell offering, which include:
 * - `feature`: The feature to check upsell for.
 * - `entityType`: The type of entity to check upsell for.
 * - `entityId`: The specific entity ID to check upsell for.
 * @param missingEntitlements - A list of entitlements the user is missing.
 * @returns The ID of the upsell offering, or default offering id if no specific match is found.
 */
export function findUpsellOfferingId(
  config: WebUpsellOfferingMappingConfig = {},
  { feature, entityType, entityId }: UpsellOfferingOptions = {},
  missingEntitlements: string[]
) {
  const firstMissingEntitlement = missingEntitlements[0];

  if (config.entitlement) {
    const firstMissingEntitlement = missingEntitlements.find(
      (missingEntitlement) => missingEntitlement in config.entitlement!
    );
    if (firstMissingEntitlement) {
      return config.entitlement[firstMissingEntitlement];
    }
  }

  if (entityId && config.entity && entityId in config.entity) {
    return config.entity[entityId];
  }

  if (entityType && config.entityType) {
    const kebabCasedEntityType = kebabCase(
      entityType
    ) as keyof WebUpsellOfferingMappingConfig['entityType'];

    if (kebabCasedEntityType in config.entityType) {
      return config.entityType[kebabCasedEntityType];
    }
  }

  if (feature && config.feature && feature in config.feature) {
    return config.feature[feature];
  }

  return config.default;
}
