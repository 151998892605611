import { GraphqlClient } from '@/libs/amplify/types';
import type { DefaultError } from '@tanstack/query-core';
import { UseSuspenseQueryOptions } from '@tanstack/react-query';
import {
  GetEntityFilterInput,
  getEntityQueryKey,
  GetEntityQueryKey,
  getEntityVariables
} from './query-options';
import {
  EntityItem,
  entitySelectionSet,
  ParentEntityItem,
  parentEntitySelectionSet
} from './types';
import { throwGraphqlError } from '@/utils/throw-graphql-error';

export function getEntityQuery(
  graphqlClient: GraphqlClient,
  filterInput: GetEntityFilterInput
): UseSuspenseQueryOptions<
  EntityItem | null,
  DefaultError,
  EntityItem | null,
  GetEntityQueryKey
> {
  const queryKey = getEntityQueryKey(filterInput);
  const getOptions = getEntityVariables(filterInput);
  return {
    queryKey,
    queryFn: async () => {
      if (!getOptions.id) {
        return null;
      }
      const { data: entity, errors } = await graphqlClient.models.Entity.get(
        getOptions,
        { selectionSet: entitySelectionSet }
      );
      throwGraphqlError(errors);
      return entity as EntityItem;
    }
  };
}

export function getParentEntityQuery(
  graphqlClient: GraphqlClient,
  filterInput: GetEntityFilterInput
): UseSuspenseQueryOptions<
  ParentEntityItem | null,
  DefaultError,
  ParentEntityItem | null,
  GetEntityQueryKey
> {
  const queryKey = getEntityQueryKey(filterInput);
  const getOptions = getEntityVariables(filterInput);

  return {
    queryKey,
    queryFn: async () => {
      if (!getOptions.id) {
        return null;
      }
      const { data: entity, errors } = await graphqlClient.models.Entity.get(
        getOptions,
        { selectionSet: parentEntitySelectionSet }
      );
      throwGraphqlError(errors);
      return entity as ParentEntityItem;
    }
  };
}
