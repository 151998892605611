import type { Schema } from '@/schema';
import { SelectionSet } from 'aws-amplify/data';

export const dashboardCardSelectionSet = [
  'date',
  'children.id',
  'children.order',
  'children.type',
  'children.image.url',
  'children.image.height',
  'children.image.width',
  'children.date',
  'children.entityId',
  'children.customPrompt',
  'children.assessmentType'
] as const;

export type DashboardCard = SelectionSet<
  Schema['DashboardCard']['type'],
  typeof dashboardCardSelectionSet
>;

export type DashboardCardItem = DashboardCard['children'][number];

export type DashboardProgress = Schema['DashboardProgress']['type'];

export type DashboardProgressItem = NonNullable<
  DashboardProgress['children']
>[number];

export const dashboardStreakSelectionSet = [
  'id',
  'startDate',
  'lastActiveDate',
  'owner',
  'count',
  'updatedAt'
] as const;

export type DashboardStreak = SelectionSet<
  Schema['DashboardStreak']['type'],
  typeof dashboardStreakSelectionSet
>;
