'use client';

import {
  enqueueSnackbar,
  VariantType,
  closeSnackbar,
  SnackbarKey,
  OptionsObject
} from 'notistack';
import { ReactNode } from 'react';
import { createStore } from 'zustand-x';

/**
 * Defines `snackbarStore` for managing snackbar notifications within the application.
 * This store is client-side only and utilizes `zustand-x` for state management. It manages
 * the visibility, severity, and message content of snackbars, providing a flexible way to
 * display notifications to the user.
 *
 * Actions:
 * - `create`: Opens a snackbar with the specified severity and message. This action updates
 *   the store's state to reflect the provided parameters and sets `isOpen` to true, triggering
 *   the snackbar to appear with the specified content.
 * - `close`: Closes the snackbar and resets its state to the default values (`isOpen` set to
 *   false, `severity` to 'info', and `message` to an empty string), effectively clearing the
 *   current notification and preparing the store for the next message.
 *
 * Usage Guidelines:
 * - Use the `create` action to display a new snackbar notification, specifying the desired
 *   severity and message content. This provides a dynamic and user-friendly way to communicate
 *   information, warnings, errors, or success messages to the user.
 * - Invoke the `close` action to close the currently displayed snackbar and clear its content,
 *   typically in response to a user action (e.g., clicking a 'close' button) or automatically
 *   after a certain duration.
 *
 * Example:
 * ```typescript
 * snackbarStore.set.create('success', <Trans id="successMessage">Your changes have been saved successfully!</Trans>);
 * ```
 */
export const snackbarStore = createStore('snackbar')<{}>({}).extendActions(
  () => ({
    create<V extends VariantType>(
      variant: V,
      message: ReactNode,
      options?: Omit<OptionsObject<V>, 'variant'> & { icon?: ReactNode }
    ) {
      return enqueueSnackbar(message, {
        ...options,
        variant: variant as VariantType
      });
    },
    close(key?: SnackbarKey) {
      closeSnackbar(key);
    }
  })
);
