import type { Schema } from '@/schema';
import { OverridePathToArray } from '@/types';
import { SelectionSet } from 'aws-amplify/data';

export const recentlyPlayedSelectionSet = [
  'id',
  'lastPlayedAt',
  'playbackPercentage',
  'completeTime',
  'children.*',
  'entityType',
  'entityId',
  'images.*'
] as const;

export type RecentlyPlayedItem = OverridePathToArray<
  SelectionSet<
    Schema['RecentlyPlayed']['type'],
    typeof recentlyPlayedSelectionSet
  >,
  'children' | 'images'
>;
