import {
  Menu,
  MenuPlacement,
  Tooltip,
  useMediaState,
  usePlaybackRateOptions
} from '@vidstack/react';
import { CheckIcon } from '@vidstack/react/icons';
import { useDefaultLayoutWord } from '@vidstack/react/player/layouts/default';

export function SpeedMenu({
  placement = 'top end'
}: {
  placement?: MenuPlacement;
}) {
  const speedLabel = useDefaultLayoutWord('Speed');
  const options = usePlaybackRateOptions();
  const $live = useMediaState('live');
  return (
    !$live && (
      <Menu.Root className="vds-menu vds-settings-menu">
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <Menu.Button
              className="vds-speed-button vds-button"
              aria-label={speedLabel}
            >
              <strong>{options.selectedValue + 'x'}</strong>
            </Menu.Button>
          </Tooltip.Trigger>
          <Tooltip.Content
            className="vds-tooltip-content"
            placement={placement}
          >
            {speedLabel}
          </Tooltip.Content>
        </Tooltip.Root>
        <Menu.Items className="vds-menu-items" placement={placement} offset={0}>
          <Menu.RadioGroup
            className="vds-radio-group"
            value={options.selectedValue}
          >
            {options.map(({ label, value, select }) => (
              <Menu.Radio
                className="vds-radio"
                value={value}
                onSelect={select}
                key={value}
              >
                <CheckIcon className="vds-icon" />
                <span className="vds-radio-label">{label}</span>
              </Menu.Radio>
            ))}
          </Menu.RadioGroup>
        </Menu.Items>
      </Menu.Root>
    )
  );
}
