import { useGetEntitlements } from '@/entitlements/api/get';
import { entitlementsStore } from '@/entitlements/store/entitlement-store';
import { useAuthStore } from '@/stores/auth-store-provider';
import { isUserDev } from '@/utils/acl';
import { useCallback, useMemo } from 'react';

export function useDevEntitlements() {
  const authStore = useAuthStore();
  const { data: apiUserEntitlements = [] } = useGetEntitlements();
  const user = authStore.useTracked.user();
  const isDev = isUserDev(user);
  const devEntitlements = entitlementsStore.useTracked.devEntitlements();
  const devOverride = entitlementsStore.useTracked.devOverride();

  const devUserEntitlements = useMemo(() => {
    if (isDev) {
      return devOverride
        ? devEntitlements
        : [...apiUserEntitlements, ...devEntitlements];
    }

    return apiUserEntitlements;
  }, [apiUserEntitlements, devEntitlements, devOverride, isDev]);

  return {
    devUserEntitlements,
    isDev
  };
}
