import { ChecklistLoading } from '@/app/[lang]/(shell)/checklists/components/checklist-loading';
import EntityCommentSkeleton from '@/app/[lang]/(shell)/learn/components/comments/entity-comment-skeleton';
import Timeline from '@mui/lab/Timeline';
import { CardContent, CardHeader, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import { DashboardTimelineItem } from './dashboard-timeline-item';

export function EntityCardSkeleton() {
  return (
    <Paper sx={{ overflow: 'hidden' }}>
      <Skeleton sx={{ height: '212px', width: '100%' }} variant="rectangular" />
      <Box sx={{ display: 'flex', gap: 1.5, p: 1 }}>
        <Skeleton sx={{ height: '2rem', width: '77%' }} variant="rounded" />
        <Skeleton sx={{ height: '2rem', width: '77%' }} variant="rounded" />
        <Skeleton sx={{ height: '2rem', width: '77%' }} variant="rounded" />
      </Box>
      <Divider />
      <Box sx={{ p: 1, pb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mb: 1 }}>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton
            sx={{ height: '2.5rem', flex: 1, opacity: 0.5 }}
            variant="rounded"
          />
        </Box>
        <EntityCommentSkeleton />
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingY: 1 }}>
          <Skeleton sx={{ height: '1rem', width: '12rem' }} variant="rounded" />
        </Box>
      </Box>
    </Paper>
  );
}

export function JournalCardSkeleton() {
  return (
    <Paper>
      <CardHeader
        sx={{ paddingBottom: 0 }}
        title={
          <Typography variant="h6" fontWeight={600}>
            <Skeleton sx={{ width: '60%' }} />
          </Typography>
        }
      />
      <CardContent>
        <Skeleton
          sx={{ height: '5rem', width: '100%', opacity: 0.5, mb: 2 }}
          variant="rounded"
        />
        <Skeleton
          sx={{ height: '8.5rem', width: '100%', opacity: 0.5 }}
          variant="rounded"
        />
      </CardContent>
    </Paper>
  );
}

export function ChecklistCardSkeleton() {
  return (
    <Paper>
      <CardHeader
        sx={{ paddingBottom: 0 }}
        title={
          <Typography variant="h6" fontWeight={600}>
            <Skeleton sx={{ width: '60%' }} />
          </Typography>
        }
      />
      <CardContent sx={{ '&:last-child': { paddingBottom: 0 } }}>
        <ChecklistLoading />
      </CardContent>
    </Paper>
  );
}

export function LifescoreCardSkeleton() {
  return (
    <Paper>
      <CardHeader
        title={
          <Typography variant="h6" fontWeight={600}>
            <Skeleton sx={{ width: '40%' }} />
          </Typography>
        }
        subheader={
          <Typography variant="h6" fontWeight={600}>
            <Skeleton sx={{ width: '60%' }} />
          </Typography>
        }
        sx={{ borderBottom: '1px solid', borderBottomColor: 'divider' }}
      />
      <CardContent>
        <Skeleton
          sx={{ height: '10rem', width: '100%', opacity: 0.5 }}
          variant="rounded"
        />
      </CardContent>
    </Paper>
  );
}

export function DashboardCardsSkeleton() {
  return (
    <div>
      <Timeline sx={{ px: 0, mt: 0, ml: { xs: 0, lg: -4 } }}>
        <DashboardTimelineItem>
          <EntityCardSkeleton />
        </DashboardTimelineItem>
        <DashboardTimelineItem>
          <JournalCardSkeleton />
        </DashboardTimelineItem>
        <DashboardTimelineItem>
          <ChecklistCardSkeleton />
        </DashboardTimelineItem>
        <DashboardTimelineItem>
          <EntityCardSkeleton />
        </DashboardTimelineItem>
        <DashboardTimelineItem>
          <LifescoreCardSkeleton />
        </DashboardTimelineItem>
      </Timeline>
    </div>
  );
}
