'use client';

import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import TimelineConnector, {
  timelineConnectorClasses
} from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot, { timelineDotClasses } from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import NoSsr from '@mui/material/NoSsr';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

const StyledTimelineItem = styled(TimelineItem)({
  [`&.${timelineItemClasses.root}`]: {
    '&:before': {
      content: 'none'
    }
  }
});

const StyledTimelineDot = styled(TimelineDot)(({ theme }) => ({
  [`&.${timelineDotClasses.root}`]: {
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    margin: '8px 0',
    borderWidth: 0
  }
}));

const StyledTimelineConnector = styled(TimelineConnector)(({ theme }) => ({
  [`&.${timelineConnectorClasses.root}`]: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.action.disabled} 30%, transparent 30%)`,
    backgroundSize: '100% 12px'
  }
}));

export type DashboardTimelineItemProps = {
  children?: ReactNode;
  checked?: boolean;
};

export function DashboardTimelineItem({
  children,
  checked
}: DashboardTimelineItemProps) {
  return (
    <StyledTimelineItem>
      <TimelineSeparator>
        <StyledTimelineDot variant="outlined">
          <RadioButtonUnchecked sx={{ color: 'grey.400' }} />
          <NoSsr>
            {checked && (
              <CheckCircle sx={{ position: 'absolute' }} color="secondary" />
            )}
          </NoSsr>
        </StyledTimelineDot>
        <StyledTimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ maxWidth: 'calc(100% - 20px)', pr: 0, py: 1 }}>
        {children}
      </TimelineContent>
    </StyledTimelineItem>
  );
}
