import { getDashboardProgressQuery } from '@/api/dashboard/get-dashboard';
import { useCreateGrowthCoinTransactionMutation } from '@/api/growth-coin-transactions/create';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DashboardCardItem, DashboardProgress } from './types';
import { getDashboardProgressQueryKey } from './query-options';
import { getNewOrUpdatedProgress, grantCoinsOnCompletion } from './utils';
import { throwGraphqlError } from '@/utils/throw-graphql-error';

export function useUpdateDashboardProgress(
  owner: string,
  date: string,
  cards: DashboardCardItem[]
) {
  const queryKey = getDashboardProgressQueryKey(owner, date);
  const graphqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const { mutate: createGrowthCoinTransaction } =
    useCreateGrowthCoinTransactionMutation();

  let { data: previousProgress, refetch } = useQuery(
    getDashboardProgressQuery(graphqlClient, owner, date)
  );

  return useMutation({
    onMutate: async (itemId) => {
      await queryClient.cancelQueries({ queryKey });

      const updatedProgress = getNewOrUpdatedProgress({
        date,
        itemId,
        cards,
        owner,
        previousProgress
      });

      queryClient.setQueryData<DashboardProgress>(
        queryKey,
        () => updatedProgress
      );

      return { previousProgress, updatedProgress };
    },
    mutationFn: async (itemId: string) => {
      if (!previousProgress) {
        previousProgress = (await refetch()).data;
      }

      const updatedProgress = getNewOrUpdatedProgress({
        date,
        itemId,
        cards,
        owner,
        previousProgress
      });

      const { data, errors } = previousProgress
        ? await graphqlClient.models.DashboardProgress.update(updatedProgress)
        : await graphqlClient.models.DashboardProgress.create(updatedProgress);

      throwGraphqlError(errors);

      return data as DashboardProgress;
    },
    onError: async (_error, _variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousProgress);
      await queryClient.invalidateQueries({ queryKey, refetchType: 'all' });
    },
    onSuccess: (data, variables, context) => {
      grantCoinsOnCompletion(
        createGrowthCoinTransaction,
        variables,
        data,
        context?.previousProgress
      );
      queryClient.setQueryData<DashboardProgress>(queryKey, data);
    },
    scope: {
      id: 'dashboard-progress'
    }
  });
}
