'use client';

import { CommentParentType, EntityType } from '@/__generated__/API';
import { EntityItem } from '@/api/entities/types';
import { useCreateEntityLike } from '@/api/entity-like/create';
import { useDeleteEntityLike } from '@/api/entity-like/delete';
import { useGetUserEntityLike } from '@/api/entity-like/read';
import { useGetSocialProof } from '@/api/social-proof/read';
import { Plural, Trans } from '@lingui/macro';
import {
  ChatBubbleOutlineOutlined,
  EditNoteOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined
} from '@mui/icons-material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tab, { tabClasses } from '@mui/material/Tab';
import useMediaQuery from '@mui/material/useMediaQuery';
import dynamic from 'next/dynamic';
import { ReactNode, SyntheticEvent, useCallback, useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import { CardToModal } from './card-to-modal-wrapper';

const EntityComments = dynamic(
  () =>
    import(
      '@/app/[lang]/(shell)/learn/components/comments/entity-comments'
    ).then((mod) => mod.EntityComments),
  { ssr: false }
);

const CreateComment = dynamic(
  () =>
    import(
      '@/app/[lang]/(shell)/learn/components/comments/create-comment'
    ).then((mod) => mod.CreateComment),
  { ssr: false }
);

const EntityNote = dynamic(
  () =>
    import('@/app/[lang]/(shell)/learn/components/notes/entity-note').then(
      (mod) => mod.EntityNote
    ),
  { ssr: false }
);

export type DashboardEntityWrapperProps = {
  entity: EntityItem;
  children?: ReactNode;
};

enum TabsEnum {
  Notes = 'notes',
  Comments = 'comments'
}

const StyledTab = styled(Tab)(({ theme }) => ({
  [`&.${tabClasses.root}`]: {
    minHeight: 24,
    padding: '0 12px',
    margin: '12px 0',
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.divider}`
    },
    '&:first-child': {
      opacity: `1`
    }
  }
}));

export function DashboardEntityWrapper({
  entity,
  children
}: DashboardEntityWrapperProps) {
  const [value, setValue] = useState<TabsEnum>(TabsEnum.Comments);
  const { value: isExpanded, setTrue: open, setFalse: close } = useBoolean();

  const handleChange = (_: SyntheticEvent, newValue: TabsEnum) => {
    if (newValue) {
      setValue(newValue);
    }
  };

  // Get social proof
  const { data: entitySocialProof } = useGetSocialProof(entity.id);

  // Likes count
  const LIKES_COUNT = entitySocialProof?.likesCount ?? 0;
  const COMMENTS_COUNT = entitySocialProof?.commentsCount ?? 0;

  // Check if the user has liked this entity
  const { data: entityLike } = useGetUserEntityLike(entity.id);

  // Add or remove entity like
  const { mutate: createLike, isPending: creatingEntityLike } =
    useCreateEntityLike();
  const { mutate: deleteLike, isPending: deletingEntityLike } =
    useDeleteEntityLike();

  const handleLikeToggle = useCallback(async () => {
    if (entityLike) {
      analytics.track('Click', {
        element_id: 'unlike-button',
        parent_id: entity.id,
        type: 'BUTTON'
      });
      deleteLike({ id: entityLike.id, entityId: entity.id });
    } else {
      analytics.track('Click', {
        element_id: 'like-button',
        parent_id: entity.id,
        type: 'BUTTON'
      });
      createLike({ entityId: entity.id });
    }
  }, [entityLike, entity.id, deleteLike, createLike]);
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <CardToModal isExpanded={isExpanded} onClose={close}>
      <Box zIndex={1} position="relative">
        {children}
      </Box>
      <TabContext value={value}>
        <TabList
          textColor="inherit"
          variant="fullWidth"
          onChange={handleChange}
          indicatorColor="secondary"
        >
          <StyledTab
            disableRipple
            disabled={creatingEntityLike || deletingEntityLike}
            onClick={handleLikeToggle}
            icon={
              entityLike ? (
                <FavoriteOutlined color="secondary" fontSize="small" />
              ) : (
                <FavoriteBorderOutlined fontSize="small" />
              )
            }
            iconPosition="start"
            label={
              isSmallScreen ? (
                LIKES_COUNT
              ) : (
                <Plural
                  id="dashboard.likeCount"
                  value={LIKES_COUNT}
                  one="# Like"
                  other="# Likes"
                />
              )
            }
          />
          <StyledTab
            disableRipple
            icon={<ChatBubbleOutlineOutlined fontSize="small" />}
            iconPosition="start"
            label={
              isSmallScreen ? (
                COMMENTS_COUNT
              ) : (
                <Plural
                  id="dashboard.commentCount"
                  value={COMMENTS_COUNT}
                  one="# Comment"
                  other="# Comments"
                />
              )
            }
            value={TabsEnum.Comments}
          />
          <StyledTab
            disableRipple
            icon={<EditNoteOutlined fontSize="small" />}
            iconPosition="start"
            label={<Trans id="dashboard.tabNotes">Notes</Trans>}
            value={TabsEnum.Notes}
          />
        </TabList>
        <TabPanel sx={{ p: 2 }} value={TabsEnum.Notes}>
          <EntityNote entity={entity} />
        </TabPanel>
        <TabPanel sx={{ p: { sm: 1, xs: 0.5 } }} value={TabsEnum.Comments}>
          <EntityComments
            parentId={entity.id}
            threadId={entity.id}
            parentType={CommentParentType.ENTITY}
            onShowMore={open}
            displayTopComments={!isExpanded}
            enableImageUpload={entity.entityType === EntityType.CHALLENGE}
          >
            <CreateComment
              parentType={CommentParentType.ENTITY}
              parentId={entity.id}
              threadId={entity.id}
              // Enable images for challenge comments
              enableImageUpload={entity.entityType === EntityType.CHALLENGE}
            />
          </EntityComments>
        </TabPanel>
      </TabContext>
    </CardToModal>
  );
}
