import { SeekButton, Tooltip } from '@vidstack/react';
import { SeekBackward15Icon, SeekForward15Icon } from '@vidstack/react/icons';
import { useDefaultLayoutWord } from '@vidstack/react/player/layouts/default';

export function BackwardSeekButton() {
  const label = useDefaultLayoutWord('Seek Backward');
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <SeekButton
          className="vds-seek-backward-button vds-button"
          seconds={-15}
        >
          <SeekBackward15Icon className="vds-icon" />
        </SeekButton>
      </Tooltip.Trigger>
      <Tooltip.Content className="vds-tooltip-content" placement="top start">
        {label}
      </Tooltip.Content>
    </Tooltip.Root>
  );
}

export function ForwardSeekButton() {
  const label = useDefaultLayoutWord('Seek Forward');
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <SeekButton className="vds-seek-forward-button vds-button" seconds={15}>
          <SeekForward15Icon className="vds-icon" />
        </SeekButton>
      </Tooltip.Trigger>
      <Tooltip.Content className="vds-tooltip-content" placement="top start">
        {label}
      </Tooltip.Content>
    </Tooltip.Root>
  );
}
