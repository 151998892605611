'use client';

import { playerStore } from '@/stores/player-store';
import { InPortal } from 'react-reverse-portal';
import { MiniPlayer } from './mini-player';
import { VidstackPlayer } from './vidstack-player';

export function PlayerProvider() {
  const playerInstancesMap = playerStore.use.playerInstancesMap();
  return (
    <>
      {Object.entries(playerInstancesMap).map(
        ([key, { ref, props, node, pathname }]) => (
          <InPortal key={key} node={node}>
            <VidstackPlayer {...props} playerRef={ref} pathname={pathname} entityId={key}/>
          </InPortal>
        )
      )}
      <MiniPlayer />
    </>
  );
}
