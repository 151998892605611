export function isEditableElement(
  target: Element | HTMLElement | null | undefined
): boolean {
  if (!target) return false;

  const tagName = target.tagName.toLowerCase();
  const contentEditable = target.getAttribute('contenteditable');

  return (
    tagName === 'input' ||
    tagName === 'textarea' ||
    tagName === 'select' ||
    contentEditable === 'true' ||
    contentEditable === ''
  );
}
