import { playerStore } from '@/stores/player-store';
import { PlaybackEventName, PlaybackEventMeta } from '../types';

/* Send event playback analytics to rudderstack */
export function sendPlaybackMetrics(eventName: PlaybackEventName, entityId: string) {
  const player = playerStore.get.playerInstance(entityId)?.ref?.current;
  if (player) {
    const videoQuality = Array.from(player.qualities).find(quality => quality?.selected === true);
    let meta: PlaybackEventMeta = {
      content_asset_id: entityId || 'undefined',
      position: Number(player.currentTime.toFixed(0)),
      total_length: Number(player.duration.toFixed(0)),
      bitrate: videoQuality?.bitrate || 0,
      framerate: 0, // TODO: Find source for framerate
      video_player: 'vidstack',
      sound: player.volume * 100,
      full_screen: document.fullscreenElement === playerStore.get.playerInstance(entityId)?.ref?.current,
      quality: String(videoQuality?.height) || 'undefined',
      // livestream: player.duration === Infinity,
      livestream: player.duration === Infinity,
    };
    if (
      eventName === 'Video Playback Started' ||
      eventName === 'Video Playback Buffer Completed' ||
      eventName === 'Video Playback Seek Completed'
    ) {
      const currentTime = player.currentTime;
      const liveEdge = player.state.seekableEnd;
      const delay = liveEdge - currentTime;
      meta.playback_delay = delay;
    }
    if (eventName === 'Video Playback Interrupted') {
      // TODO: Determine source of interruption
      meta.method = 'unknown';
    }
    analytics.track(eventName, meta);
  }
}