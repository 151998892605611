import { GraphQLFormattedError } from 'graphql';

export function throwGraphqlError(
  errors: GraphQLFormattedError[] | undefined | null
) {
  if (errors?.length) {
    console.error(errors);
    if (typeof window !== 'undefined') {
      const datadogRum = window.datadogRum;
      if (datadogRum) {
        errors.forEach((error) => datadogRum.addError(error));
      }
    }
    throw new Error(errors.map((err) => err.message).join(', '));
  }
}
