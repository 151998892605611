import { ReactNode } from 'react';

export type LayoutProps<T extends {} = {}> = {
  params: T & {
    lang: string;
  };
  children: ReactNode;
};

export type PageProps<Params extends {} = {}, SearchParams extends {} = {}> = {
  params: Params & {
    lang: string;
  };
  searchParams: SearchParams;
};

export type Nullable<T> = T | null | undefined;

export type OverridePathToArray<
  T,
  K extends string
> = K extends `${infer F}.${infer R}`
  ? F extends keyof T
    ? Omit<T, F> & { [P in F]: OverridePathToArray<T[F], R> }
    : T
  : { [P in keyof T]: P extends Extract<K, keyof T> ? T[P][] : T[P] };

export type AnalyticsProps = {
  analyticsParentId: string;
  analyticsContext?: Record<string, any>;
};

export type PaginatedApiData<T> = {
  data: T[];
  nextToken: string | null | undefined;
};

export type User = {
  // identification attributes
  'cognito:username': string;
  'cognito:groups'?: UserRole[];
  id: string;
  sub: string;

  // custom attributes
  'custom:uuid'?: string;
  'custom:profile_completed'?: string;
  'custom:community_terms_ack'?: string;

  // standard mandatory attributes
  email: string;

  // standard optional attributes
  address?: { formatted: string };
  email_verified?: boolean;
  family_name?: string;
  given_name?: string;
  locale?: string;
  name?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  picture?: string;
  zoneinfo?: string;

  // standard attributes not-enabled in nimbus yet
  birthdate?: string;
  gender?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  profile?: string;
  updated_at?: string;
  website?: string;

  // social identities
  identities: Array<{
    dateCreated: string;
    issuer?: string | null;
    primary: boolean;
    providerName: string;
    providerType: string;
    userId: string;
  }>;
};

export type UserInfoAddress = {
  state?: string | null | undefined;
  country?: string | null | undefined;
  zipcode?: string | null | undefined;
};

export enum UserRole {
  Admin = 'admin',
  Dev = 'dev',
  Content = 'content'
}

export enum AuthFlowType {
  ADMIN_NO_SRP_AUTH = 'ADMIN_NO_SRP_AUTH',
  ADMIN_USER_PASSWORD_AUTH = 'ADMIN_USER_PASSWORD_AUTH',
  CUSTOM_AUTH = 'CUSTOM_AUTH',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  REFRESH_TOKEN_AUTH = 'REFRESH_TOKEN_AUTH',
  USER_AUTH = 'USER_AUTH',
  USER_PASSWORD_AUTH = 'USER_PASSWORD_AUTH',
  USER_SRP_AUTH = 'USER_SRP_AUTH'
}
