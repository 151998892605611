'use client';

import { useUpdateShareCount } from '@/api/share-count/update';
import facebookLogo from '@/assets/logos/facebook.webp';
import linkedinLogo from '@/assets/logos/linkedin.webp';
import whatsappLogo from '@/assets/logos/whatsapp.webp';
import xLogo from '@/assets/logos/x.webp';
import { snackbarStore } from '@/stores/snackbar-store';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { InputBase, inputBaseClasses } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import kebabCase from 'lodash-es/kebabCase';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import urlJoin from 'proper-url-join';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import { useCopyToClipboard } from 'usehooks-ts';

export type SocialShareBarProps = {
  entityId?: string;
  title?: string;
  description?: string;
  appendedPathname?: string;
  query?: Record<string, string>;
  url?: string;
  onShare?: (medium: string) => void;
  hideCopy?: boolean;
};

const FilledInput = styled(InputBase)(({ theme }) => ({
  paddingRight: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.action.focus,
  [`& .${inputBaseClasses.input}`]: {
    padding: theme.spacing(1.5, 1, 1.5, 2)
  }
}));

// Note that environment variables cannot be destructured for client-side usage
// due to the way Next.js handles environment variable exposure. Client-side
// environment variables must be explicitly accessed through `process.env`
// with the appropriate `NEXT_PUBLIC_` prefix.
const APP_URL = process.env.NEXT_PUBLIC_APP_URL;

export function SocialShareBar({
  entityId,
  title,
  description,
  appendedPathname,
  url: inputUrl,
  query,
  onShare,
  hideCopy
}: SocialShareBarProps) {
  const { mutate } = useUpdateShareCount();
  const { i18n } = useLingui();
  const pathname = usePathname();
  const [, copyToClipboard] = useCopyToClipboard();
  const url =
    inputUrl || urlJoin(APP_URL, appendedPathname || pathname, { query });

  let content =
    (title ? `${title}\n` : '') + (description ? `${description}\n\n` : '');
  if (!content.trim()) {
    content = '';
  }
  const body = encodeURIComponent(content + url);

  const handleClick = (medium: string) => {
    if (entityId) {
      mutate(entityId);
    }
    onShare?.(medium);
    analytics.track('Click', {
      element_id: `${kebabCase(medium)}-share-button`,
      type: 'BUTTON'
    });

    if (medium === 'Copy Link') {
      void copyToClipboard(url);
      snackbarStore.set.create('info', i18n.t('social-share.link-copied'));
    }
  };

  return (
    <Stack spacing={2} direction="column">
      <Typography variant="h6">
        <Trans id="common.shareOn">Share On:</Trans>
      </Typography>
      <Stack>
        <Stack
          spacing={0}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <IconButton
            component={WhatsappShareButton}
            resetButtonStyle={false}
            url={url}
            title={content}
            onClick={() => handleClick('Whatsapp')}
          >
            <Image
              width={48}
              height={48}
              src={whatsappLogo}
              alt={i18n.t('social-share.whatsapp-logo')}
            />
          </IconButton>
          <Typography variant="body2">
            <Trans id="social-share.whatsapp">Whatsapp</Trans>
          </Typography>
        </Stack>
        <Stack
          spacing={0}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <IconButton
            component={TwitterShareButton}
            resetButtonStyle={false}
            url={url}
            title={content}
            onClick={() => handleClick('X')}
          >
            <Image
              width={48}
              height={48}
              src={xLogo}
              alt={i18n.t('social-share.x-logo')}
            />
          </IconButton>
          <Typography variant="body2">
            <Trans id="social-share.x">X</Trans>
          </Typography>
        </Stack>
        <Stack
          spacing={0}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <IconButton
            component={FacebookShareButton}
            resetButtonStyle={false}
            url={url}
            onClick={() => handleClick('Facebook')}
          >
            <Image
              width={48}
              height={48}
              src={facebookLogo}
              alt={i18n.t('social-share.facebook-logo')}
            />
          </IconButton>
          <Typography variant="body2">
            <Trans id="social-share.facebook">Facebook</Trans>
          </Typography>
        </Stack>
        <Stack
          spacing={0}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <IconButton
            component={LinkedinShareButton}
            resetButtonStyle={false}
            url={url}
            summary={description}
            title={title}
            onClick={() => handleClick('LinkedIn')}
          >
            <Image
              width={48}
              height={48}
              src={linkedinLogo}
              alt={i18n.t('social-share.linkedin-logo')}
            />
          </IconButton>
          <Typography variant="body2">
            <Trans id="social-share.linkedin">LinkedIn</Trans>
          </Typography>
        </Stack>
        <Stack
          spacing={0}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <IconButton
            target="hidden-iframe-mail-share"
            href={`mailto:?subject=${title}&body=${body}`}
            onClick={() => handleClick('Email')}
          >
            <EmailOutlinedIcon sx={{ width: 48, height: 48 }} />
          </IconButton>
          <Typography variant="body2">
            <Trans id="social-share.email">Email</Trans>
          </Typography>
        </Stack>
        <iframe
          title="hidden-iframe-mail-share"
          name="hidden-iframe-mail-share"
          style={visuallyHidden}
        />
      </Stack>
      {!hideCopy && (
        <Stack>
          <FilledInput
            readOnly
            fullWidth
            value={url}
            inputProps={{
              onClick: (event) => (event.target as HTMLInputElement).select()
            }}
            endAdornment={
              <InputAdornment position="end" variant="outlined">
                <Button variant="text" onClick={() => handleClick('Copy Link')}>
                  <Trans id="social-share.copy">COPY</Trans>
                </Button>
              </InputAdornment>
            }
          />
        </Stack>
      )}
    </Stack>
  );
}
