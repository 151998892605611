export function getLeaderboardQueryKey(month: string) {
  return ['GetLeaderboard', { month }] as const;
}

export type GetLeaderboardQueryKey = ReturnType<typeof getLeaderboardQueryKey>;

export function listLeaderboardStreaksQueryKey(month: string) {
  return ['ListLeaderboardStreaks', { month }] as const;
}

export type ListLeaderboardStreaksQueryKey = ReturnType<
  typeof listLeaderboardStreaksQueryKey
>;

export function listLeaderboardCoinsQueryKey(month: string) {
  return ['ListLeaderboardCoins', { month }] as const;
}

export type ListLeaderboardCoinsQueryKey = ReturnType<
  typeof listLeaderboardCoinsQueryKey
>;

export function listLeaderboardReferralsQueryKey(month: string) {
  return ['ListLeaderboardReferrals', { month }] as const;
}

export type ListLeaderboardReferralsQueryKey = ReturnType<
  typeof listLeaderboardReferralsQueryKey
>;
