import { Config } from 'next-i18n-router/dist/types';

export const i18nConfig: Config = {
  locales: ['en', 'es', 'fr', 'pt'],
  defaultLocale: 'en',
  serverSetCookie: 'always',
  localeCookie: 'NEXT_LOCALE'
};

export const localeNameMap: Record<string, string> = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  pt: 'Português'
};
