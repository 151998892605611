import {
  UpsellOfferingOptions,
  WebUpsellOfferingMappingConfig
} from '@/entitlements/types';
import { findUpsellOfferingId } from '@/entitlements/utils';
import { entitlementsStore } from '@/entitlements/store/entitlement-store';
import { useDynamicConfig } from '@statsig/react-bindings';
import { useCallback, useMemo } from 'react';
import { useEntitlements } from './use-entitlements';

export function useUpsellOffering(options: UpsellOfferingOptions = {}) {
  const { value: config } = useDynamicConfig('web_upsell_offering_mapping');

  const entitlementsResult = useEntitlements(options.requiredEntitlements);

  const showUpsell = useCallback(
    (forced?: boolean) => {
      if (entitlementsResult.isEntitled && !forced) return;

      const upsellOfferingId = findUpsellOfferingId(
        config as WebUpsellOfferingMappingConfig,
        options,
        entitlementsResult.missingEntitlements
      );

      if (upsellOfferingId) {
        entitlementsStore.set.upsellOfferingModalId(upsellOfferingId);
      }
    },
    [
      config,
      entitlementsResult.isEntitled,
      entitlementsResult.missingEntitlements,
      options
    ]
  );

  return useMemo(
    () => ({ ...entitlementsResult, showUpsell }),
    [entitlementsResult, showUpsell]
  );
}
