'use client';
import { useAuthStore } from '@/stores/auth-store-provider';
import { useRef } from 'react';
import dayjs from 'dayjs';

/**
 * Component to update dayjs globally whenever user's timezone is changed
 */
export function DayjsUpdater() {
  const authStore = useAuthStore();
  const user = authStore.useTracked.user();
  const zoneInfo = useRef<null | string>(null);

  if (user?.zoneinfo && zoneInfo.current !== user?.zoneinfo) {
    zoneInfo.current = user.zoneinfo;
    dayjs.tz.setDefault(zoneInfo.current);
  }

  return null;
}
