import { GraphqlClient } from '@/libs/amplify/types';
import { DefaultError } from '@tanstack/query-core';
import { UseSuspenseQueryOptions } from '@tanstack/react-query';
import {
  getDashboardStreakQueryKey,
  GetDashboardStreakQueryKey
} from './query-options';
import { DashboardStreak, dashboardStreakSelectionSet } from './types';

export function getDashboardStreakQuery(
  graphqlClient: GraphqlClient,
  owner: string,
  lastActiveDate: string
): UseSuspenseQueryOptions<
  DashboardStreak[],
  DefaultError,
  DashboardStreak[],
  GetDashboardStreakQueryKey
> {
  const queryKey = getDashboardStreakQueryKey(owner, lastActiveDate);
  return {
    queryKey,
    queryFn: async () => {
      if (!owner) {
        return [];
      }
      const { data, errors } =
        await graphqlClient.models.DashboardStreak.listDashboardStreakByOwnerAndLastActiveDate(
          {
            owner,
            lastActiveDate: { ge: lastActiveDate }
          },
          {
            sortDirection: 'ASC',
            selectionSet: dashboardStreakSelectionSet
          }
        );
      if (errors?.length) {
        console.log(errors);
      }
      return (data ?? []) as DashboardStreak[];
    }
  };
}
