import type { Schema } from '@/schema';
import { SelectionSet } from 'aws-amplify/data';

export const getLeaderboardSelectionSet = [
  'highestStreakCount',
  'totalCoinsCount',
  'totalReferralsCount',
  'totalLeadsCount',
  'owner',
  'updatedAt'
] as const;

export type GetLeaderboard = SelectionSet<
  Schema['Leaderboard']['type'],
  typeof getLeaderboardSelectionSet
>;

export const listLeaderboardSelectionSet = [
  'highestStreakCount',
  'totalCoinsCount',
  'totalReferralsCount',
  'totalLeadsCount',
  'profile.displayName'
] as const;

export type ListLeaderboard = SelectionSet<
  Schema['Leaderboard']['type'],
  typeof listLeaderboardSelectionSet
>;
