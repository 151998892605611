'use client'

import { useMemo } from 'react';
import { useMediaState } from '@vidstack/react';
import { PersonOutline } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import millify from 'millify';

type UserCountProps = {
  viewerCount?: number;
}

export function UserCount({ viewerCount = 0 }: UserCountProps) {
  const $live = useMediaState('live');

  const formattedViewerCount = useMemo(() => {
    return millify(viewerCount, {
      precision: 2,
      lowercase: true
    });
  }, [viewerCount]);

  return (
    $live && (
      <Box
        className="vds-user-count-group"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        sx={{ ml: 1, px: '2px' }}
      >
        <PersonOutline sx={{ width: '20px' }} />
        <Typography fontSize="12px" sx={{ pr: '2px' }}>
          {formattedViewerCount}
        </Typography>
      </Box>
    )
  );
}
