import { Time, useMediaState } from '@vidstack/react';

export function TimeControl() {
  const $live = useMediaState('live');
  return (
    !$live && (
      <div className="vds-time-group">
        <Time className="vds-time" type="current" />
        <div className="vds-time-divider">/</div>
        <Time className="vds-time" type="duration" />
      </div>
    )
  );
}
