/**
 * RevenueCat Entitlements.
 * @see https://app.revenuecat.com/projects/625793d6/entitlements
 */
export enum Entitlement {
  'MASTERY' = '01_MASTERY',
  'PRO' = '01_PRO',
  'STARTER' = '01_STARTER',
  'ULTRA' = '06_ULTRA',
  'TEAMS' = '05_TEAMS',
  'PRODUCTIVITY_MASTERCLASS' = '02_PRODUCTIVITY_MASTERCLASS',
  'SUCCESS_ACCELERATOR' = '02_SUCCESS_ACCELERATOR',
  'FOCUS_DISCIPLINE_MASTERCLASS' = '02_FOCUS_DISCIPLINE_MASTERCLASS',
  'MINDSET_RESILIENCE_MASTERCLASS' = '02_MINDSET_RESILIENCE_MASTERCLASS',
  'CONFIDENCE_MASTERCLASS' = '02_CONFIDENCE_MASTERCLASS',
  'WEALTH_HABIT_MASTERCLASS' = '02_WEALTH_HABIT_MASTERCLASS',
  'SELF_DISCIPLINE_MASTERY_COLLECTION' = '02_SELF_DISCIPLINE_MASTERY_COLLECTION',
  'HIGH_PERFORMANCE_GPS' = '02_HIGH_PERFORMANCE_GPS',
  'OVERCOME_STRESS_MASTERCLASS' = '02_OVERCOME_STRESS_MASTERCLASS',
  'ADVANCED_HUMAN_BEHAVIOR_CHANGE_MASTERCLASS' = '02_ADVANCED_HUMAN_BEHAVIOR_CHANGE_MASTERCLASS',
  'RELATIONSHIP_EVENT' = '02_RELATIONSHIP_EVENT',
  'PERSUASION_MASTERCLASS' = '02_PERSUASION_MASTERCLASS',
  'AUDIOBOOKS' = '11_AUDIOBOOKS',
  'SECRETS_OF_THE_TOP_TWO' = '02_SECRETS_OF_THE_TOP_TWO',
  'VIDEO_COURSES' = '11_VIDEO_COURSES',
  'CHPC_ACTIVE' = '03_CHPC_ACTIVE',
  'CHPC_NEW' = '03_CHPC_NEW',
  'CHPC_CHARGE' = '03_CHPC_CHARGE',
  'CHPC_DECLARATION' = '03_CHPC_DECLARATION',
  'CHPC_LEADERSHIP' = '03_CHPC_LEADERSHIP',
  'HIGH_PERFORMANCE_SYSTEM' = '02_HIGH_PERFORMANCE_SYSTEM',
  'PERFORMANCE_EDGE' = '02_PERFORMANCE_EDGE',
  'NY_SUCCESS_ACCELERATOR' = '02_NY_SUCCESS_ACCELERATOR'
}
