import React from 'react';
import { Box, Skeleton } from '@mui/material';

export default function EntityCommentSkeleton() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        borderRadius: 2
      }}
    >
      <Skeleton variant="circular" width={40} height={40} sx={{ mr: 1.5 }} />
      <Box sx={{ flexGrow: 1 }}>
        <Skeleton variant="text" width="30%" height={24} sx={{ mb: 0.5 }} />
        <Skeleton variant="text" width="20%" height={20} sx={{ mb: 1 }} />
        <Skeleton variant="text" width="50%" height={20} sx={{ mb: 1 }} />
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Skeleton variant="text" width={40} height={20} />
          <Skeleton variant="text" width={40} height={20} />
          <Skeleton variant="text" width={40} height={20} />
          <Skeleton variant="text" width={40} height={20} />
        </Box>
      </Box>
    </Box>
  );
}
