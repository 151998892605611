import { useCallback, useEffect, useRef, useState } from 'react';

type RunBeforeUnloadOptions = {
  runOnUnmount?: boolean;
};

export function useRunBeforeUnload(
  callback: () => void | Promise<void>,
  { runOnUnmount }: RunBeforeUnloadOptions = {}
) {
  const [pending, setPending] = useState(false);
  const callbackRef = useRef(callback);
  const pendingRef = useRef(pending);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    pendingRef.current = pending;
  }, [pending]);

  useEffect(() => {
    async function beforeUnload(e: BeforeUnloadEvent) {
      if (!pending) return;
      e.preventDefault();
      await callbackRef.current?.();
    }

    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [pending]);

  const enable = useCallback(() => setPending(true), []);

  const disable = useCallback(() => setPending(false), []);

  useEffect(() => {
    return () => {
      if (pendingRef.current && runOnUnmount) {
        callbackRef.current?.();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { enable, disable };
}
