'use client';

import { ChecklistCardSkeleton } from '@/app/[lang]/(shell)/components/dashboard/dashboard-cards-skeleton';
import { CardContent } from '@mui/material';
import { useCallback } from 'react';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Trans } from '@lingui/macro';
import { checklistStore } from '@/stores/checklist-store';
import { useCreateCommentMutation } from '@/app/[lang]/(shell)/learn/api/comments/create';
import { ChecklistList } from '@/app/[lang]/(shell)/checklists/components/checklist-list';
import { CommentParentType, CommentType } from '@/__generated__/API';
import { useIntersectionObserver } from 'usehooks-ts';
import { getThreadIdForCard } from '../utils/get-threadid-for-card';
import { DashboardCardWrapper } from './card-wrapper';
import { DashboardCardProps } from './types';

export function ChecklistCard({ card, onComplete }: DashboardCardProps) {
  const { mutate: createComment } = useCreateCommentMutation();
  const { ref, isIntersecting } = useIntersectionObserver({
    initialIsIntersecting: false,
    freezeOnceVisible: true
  });

  const parentId = `dashboard#${card.date}#${card.id}`;

  const createChecklistComment = (checklistTitles: Array<string> = []) => {
    let list = `<ul>`;
    checklistTitles.forEach((taskTitle) => {
      list = `${list}<li>${taskTitle}</li>`;
    });
    return (list = `${list}</ul>`);
  };

  const onShareClicked = useCallback(() => {
    const taskTitles = checklistStore.get.taskTitles();
    const commentContent = createChecklistComment(taskTitles);

    createComment({
      threadId: getThreadIdForCard(card),
      content: commentContent,
      parentId: parentId,
      type: CommentType.COMMENT,
      parentType: CommentParentType.CHECKLIST
    });
  }, [card, createComment, parentId]);

  const title = (
    <Typography variant="h6" fontWeight={600}>
      <Trans id="dashboard.checklistTitle">Today&apos;s Checklist</Trans>
    </Typography>
  );

  return (
    <div id="checklist" ref={ref}>
      {!isIntersecting ? (
        <ChecklistCardSkeleton />
      ) : (
        <DashboardCardWrapper
          parentId={parentId}
          parentType={CommentParentType.CHECKLIST}
          commentsTitle={<CardHeader title={title} />}
        >
          <CardHeader sx={{ paddingBottom: 0 }} title={title} />
          <CardContent sx={{ '&:last-child': { paddingBottom: 0 } }}>
            <ChecklistList
              onComplete={onComplete}
              onShare={onShareClicked}
              todayDate={card.date}
              // showShareButton // disabled until after initial 3.0 launch
            />
          </CardContent>
        </DashboardCardWrapper>
      )}
    </div>
  );
}
