'use client';

import { getDashboardStreakQuery } from '@/api/dashboard/get-streak';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useAuthStore } from '@/stores/auth-store-provider';
import { useSuspenseQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useMemo } from 'react';

export function useSuspenseStreakInfo(date: string, weekDates: string[]) {
  const authStore = useAuthStore();
  const userId = authStore.useTracked.userId() ?? '';
  const graphqlClient = useGraphqlClient();
  const yesterday = dayjs.tz(date).subtract(1, 'day').format('YYYY-MM-DD');

  const { data: dashboardStreaks } = useSuspenseQuery(
    getDashboardStreakQuery(graphqlClient, userId, yesterday)
  );

  const currentStreakCount = useMemo(
    () =>
      // Find the streak in dashboardStreaks which has lastActiveDate
      // as yesterday or today. We do this by checking the difference between
      // given date and each streaks lastActiveDate, if that is 0 or 1.
      dashboardStreaks?.find((streak) => {
        const diff = dayjs(date).diff(streak.lastActiveDate, 'day');
        return diff === 0 || diff === 1;
      })?.count ?? 0,
    [dashboardStreaks, date]
  );

  const streakDates = useMemo(
    () =>
      // Filter the weekDates array to find dates that fall within any streak.
      // This would mean, that date was part of some streak and should be
      // marked as completed
      weekDates.filter((weekDate) => {
        return dashboardStreaks?.some((streak) =>
          dayjs(weekDate).isBetween(
            streak.startDate,
            streak.lastActiveDate,
            'day',
            '[]' // Inclusive of both `startDate` and `lastActiveDate`
          )
        );
      }),
    [dashboardStreaks, weekDates]
  );

  return {
    currentStreakCount,
    streakDates
  };
}
