'use client';

import { SignInForm } from './sign-in-form';
import { Trans } from '@lingui/macro';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export function SignInPageContent() {
  return (
    <>
      <Stack spacing={0.5} direction="column">
        <Typography fontWeight="500" variant="h6">
          <Trans id="sign-in.h1">Log into your account</Trans>
        </Typography>
        <Typography color="text.secondary" variant="body2">
          <Trans id="sign-in.trial-cta">
            New to GrowthDay?{' '}
            <Link
              id="free-trial-button"
              data-testid="free-trial-button"
              href="/sign-up"
              color="text.secondary"
              underline="always"
              prefetch={false}
              onClick={() => {
                analytics.track('Click', {
                  element_id: 'free-trial-button',
                  type: 'BUTTON',
                  parent_id: 'sign-in-page'
                });
              }}
            >
              Start your free trial
            </Link>
          </Trans>
        </Typography>
      </Stack>
      <SignInForm />
    </>
  );
}
