import { HTMLAttributeAnchorTarget } from 'react';

const APP_URL = process.env.NEXT_PUBLIC_APP_URL!;

// Regex patterns
const SPECIAL_PROTOCOL_PATTERN =
  /^(mailto:|tel:|data:|blob:|javascript:|ftp:|file:)/i;
const DOMAIN_OR_PATH_PATTERN =
  /^((\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|localhost|(\d{1,3}\.){3}\d{1,3})(:\d+)?(\/\S*)?|^\//i;

// Types
type URLComponents = {
  isLocal: boolean;
  target: HTMLAttributeAnchorTarget;
  path: string;
  href: string;
};

/**
 * Normalizes the input URL by:
 * - Handling protocol-relative URLs (e.g., `//example.com`) by adding `https:`.
 * - Adding `https://` to domain-like inputs without a protocol.
 * - Resolving absolute (`/hello`) and relative (`hello/foo/bar`) paths to full URLs using `APP_URL`.
 */
function normalizeUrl(input: string): string {
  if (input.startsWith('//')) {
    // Protocol-relative URL (e.g., `//example.com`)
    return `https:${input}`;
  }

  if (input.startsWith('/')) {
    // Absolute path (e.g., `/path`)
    return new URL(input, APP_URL).toString();
  }

  if (input.startsWith('http://') || input.startsWith('https://')) {
    return input; // Already a full URL
  }

  if (DOMAIN_OR_PATH_PATTERN.test(input)) {
    return `https://${input}`; // Domain-like input without protocol
  }

  // Relative paths (e.g., `hello/foo/bar`) are resolved against APP_URL
  return new URL(input, APP_URL).toString();
}

/**
 * Decomposes the input URL into its components:
 * - Handles special protocol URLs immediately.
 * - Normalizes all other URLs.
 * - Classifies URLs as local or external.
 */
export function decomposeUrl(
  inputUrl: string = '',
  inputTarget?: HTMLAttributeAnchorTarget
): URLComponents {
  // Handle special protocol URLs first
  if (SPECIAL_PROTOCOL_PATTERN.test(inputUrl)) {
    return {
      isLocal: false,
      target: '_blank',
      path: inputUrl,
      href: inputUrl
    };
  }

  // Normalize the URL
  const normalizedUrl = normalizeUrl(inputUrl);

  try {
    // Parse the normalized URL
    const url = new URL(normalizedUrl);
    const baseUrl = new URL(APP_URL);
    const isLocal = url.origin === baseUrl.origin;

    return {
      isLocal,
      target: inputTarget || (isLocal ? '_self' : '_blank'),
      path: url.pathname + url.search + url.hash,
      href: normalizedUrl
    };
  } catch {
    // Fallback for invalid URLs
    return {
      isLocal: false,
      target: '_blank',
      path: '',
      href: APP_URL
    };
  }
}
