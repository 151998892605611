import OpenWithOutlined from '@mui/icons-material/OpenWithOutlined';
import IconButton from '@mui/material/IconButton';

export function DragHandle() {
  return (
    <IconButton
      color="inherit"
      sx={{ ml: '-4px' }}
      className="mini-player-handle-button mini-button vds-icon"
    >
      <OpenWithOutlined sx={{ fontSize: 20 }} />
    </IconButton>
  );
}
