'use client';

import urlJoin from 'proper-url-join';
import { useEffect, ReactNode } from 'react';
import {
  StatsigProvider as StatsigSDKProvider,
  useClientAsyncInit
} from '@statsig/react-bindings';
import { runStatsigAutoCapture } from '@statsig/web-analytics';
import { useAuthStore } from '@/stores/auth-store-provider';

const env = process.env.NEXT_PUBLIC_APP_ENV || 'dev';
const statsigClientKey = process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY ?? '';
const statsigProxyUrl =
  process.env.NEXT_PUBLIC_STATSIG_PROXY_URL ||
  'https://gajqfvtr7j5ovdlpn4lkl7ed4q0zxltp.lambda-url.ap-south-1.on.aws';

export function StatsigProvider({ children }: { children: ReactNode }) {
  const authStore = useAuthStore();
  const userId = authStore.useTracked.userId();

  const getTier = () => {
    if (env === 'prod') {
      return 'production';
    } else {
      return 'dev';
    }
  };

  const { client } = useClientAsyncInit(
    statsigClientKey,
    {
      userID: userId || '',
      custom: {
        isLoggedIn: userId !== undefined,
        platform: 'web'
      }
    },
    {
      networkConfig: {
        api: urlJoin(statsigProxyUrl, 'v1')
      },
      environment: {
        tier: getTier()
      }
    }
  );

  useEffect(() => {
    runStatsigAutoCapture(client);
  }, [client]);

  return <StatsigSDKProvider client={client}>{children}</StatsigSDKProvider>;
}
