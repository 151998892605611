'use client';

import { useState } from 'react';
import { useLingui } from '@lingui/react';
import { useChangeLocale, useCurrentLocale } from '@/i18n';
import { i18nConfig, localeNameMap } from '@/i18n/config';
import { FormControlProps, selectClasses, SelectProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export function LanguageSwitcher({
  formControlProps,
  ...props
}: Partial<SelectProps<string>> & {
  formControlProps?: FormControlProps;
}) {
  const { i18n } = useLingui();
  const currentLocale = useCurrentLocale();
  const changeLocale = useChangeLocale();
  const [isOpen, setIsOpen] = useState(false);

  const handleLangChange = (event: SelectChangeEvent) => {
    analytics.track('Click', {
      element_id: 'lang-select',
      type: 'BUTTON'
    });
    changeLocale(event.target.value);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <FormControl
      variant="standard"
      {...formControlProps}
      role="form"
      aria-label={i18n.t('language-switcher.aria.language-switcher')}
    >
      <Select
        disableUnderline
        id="lang-select"
        value={currentLocale}
        onChange={handleLangChange}
        onOpen={handleOpen}
        onClose={handleClose}
        role="combobox"
        aria-expanded={isOpen}
        aria-controls="lang-select-options"
        {...props}
        sx={{
          fontSize: '0.7em',
          [`&, .${selectClasses.icon}`]: {
            color: 'inherit'
          },
          ...props.sx
        }}
        SelectDisplayProps={{
          'aria-label': i18n.t('language-switcher.aria.select-language')
        }}
        MenuProps={{
          id: 'lang-select-options'
        }}
      >
        {i18nConfig.locales.map((locale) => (
          <MenuItem
            dense
            key={locale}
            value={locale}
            aria-label={localeNameMap[locale]}
          >
            {localeNameMap[locale]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
