/**
 * Creates a URL representing the given content as a Blob object with the specified MIME type.
 * This URL can be used to access or download the content in web applications.
 *
 * @param content - The content to be encapsulated in a Blob object.
 * @param mimeType - The MIME type of the content, defining the nature and format of the content.
 * @returns A DOMString containing a URL that represents the Blob object.
 */
export function createBlobUrl(content: string, mimeType: string): string {
  const blob = new Blob([content], { type: mimeType });
  return URL.createObjectURL(blob);
}
