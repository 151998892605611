import { createStore } from 'zustand-x';
import { Entitlement } from '@/entitlements/enums';

type UpsellRedirectDialogStore = {
  isOpen: boolean;
  entitlement: Entitlement | null;
};

export const upsellRedirectDialogStore = createStore('upsellRedirectDialogStore')<UpsellRedirectDialogStore>({
  isOpen: false,
  entitlement: null
});
