import { GraphqlClient } from '@/libs/amplify/types';
import type { Schema } from '@/schema';
import { compact, keyBy } from 'lodash-es';
import { useCallback } from 'react';
import { useGraphqlClient } from '@/libs/amplify/client';
import {
  InfiniteData,
  QueryKey,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import { useLingui } from '@lingui/react';
import { CommentType } from '@/__generated__/API';
import {
  InfiniteCommentsPaginatedResponse,
  listCommentsQueryKey
} from '@/app/[lang]/(shell)/learn/api/comments/read';
import { snackbarStore } from '@/stores/snackbar-store';
import { produce } from 'immer';
import { CommunityFeedItem } from '../feed/components/feed-card';

export type GetCommunityFeedArgs = Pick<
  Schema['getCommunityFeed']['args'],
  'feedType' | 'limit'
>;

export function getCommunityFeedInfiniteQueryKey(
  options?: GetCommunityFeedArgs
): QueryKey {
  return compact(['getCommunityFeed', options]);
}
export type FlattenedCommunityFeedData = {
  itemsMap: Record<string, CommunityFeedItem>;
  itemIds: string[];
  interactionId: string;
  pageParam: string | null | undefined;
};

export function getCommunityFeedInfiniteQueryFn(
  graphqlClient: GraphqlClient,
  options: GetCommunityFeedArgs
) {
  return async ({ pageParam = '' }): Promise<FlattenedCommunityFeedData> => {
    const { data, errors } = await graphqlClient.queries.getCommunityFeed({
      feedType: options.feedType ?? 'LATEST',
      limit: options.limit ?? 12,
      nextToken: pageParam
    });

    if (errors && errors.length) {
      const errorMsg = `Error calling getCommunityFeed: ${errors.map((e) => e.message).join(', ')}`;
      console.error(errorMsg);
      throw new Error(errorMsg);
    }

    const entries = data?.items ?? [];
    const itemsMap = keyBy(
      entries,
      (entry) => entry?.commentId
    ) as FlattenedCommunityFeedData['itemsMap'];

    return {
      itemsMap,
      itemIds: entries.map((e) => e?.commentId ?? ''),
      interactionId: data?.interactionId || '',
      pageParam: data?.nextToken
    };
  };
}

export function selectCommunityFeedInfiniteQueryData(
  data: InfiniteData<FlattenedCommunityFeedData>
) {
  return compact(
    data.pages.flatMap((page) =>
      page.itemIds.map((feedId) => page.itemsMap[feedId])
    )
  );
}

export type DeletePostFeedMutationInput = {
  feed: CommunityFeedItem;
};

function useRemoveCommentFromStore() {
  const queryClient = useQueryClient();

  return useCallback(
    async ({ feed }: DeletePostFeedMutationInput) => {
      const threadId = feed.parentId ?? '';
      const parentId = feed.parentId;
      const type = CommentType.COMMENT;

      if (!parentId) {
        return;
      }
      const commentsQueryKeyPrefix = listCommentsQueryKey({
        threadId,
        parentId,
        type
      });

      const communityFeedQueryKeyPrefix = getCommunityFeedInfiniteQueryKey();

      await Promise.all([
        queryClient.cancelQueries({
          queryKey: commentsQueryKeyPrefix
        }),
        queryClient.cancelQueries({
          queryKey: communityFeedQueryKeyPrefix
        })
      ]);

      const previousCommunityFeed = queryClient.getQueriesData<
        InfiniteData<FlattenedCommunityFeedData> | undefined
      >({
        queryKey: communityFeedQueryKeyPrefix
      });

      queryClient.setQueriesData<
        InfiniteData<FlattenedCommunityFeedData> | undefined
      >(
        {
          queryKey: communityFeedQueryKeyPrefix
        },
        (old) => {
          if (!old) {
            return old;
          }
          return produce(old, (draft) => {
            for (const page of draft.pages) {
              delete  page.itemsMap?.[feed?.commentId]
            }
          });
        }
      );

      return () => {

        // Revert community feed
        for (const [key, data] of previousCommunityFeed) {
          queryClient.setQueryData(key, data);
        }
      };
    },
    [queryClient]
  );
}

export function useDeleteFeedPostMutation() {
  const { i18n } = useLingui();
  const graphqlClient = useGraphqlClient();
  const removeCommentFromStore = useRemoveCommentFromStore();

  return useMutation({
    async mutationFn({ feed }: DeletePostFeedMutationInput) {
      const response = await graphqlClient.models.Comment.delete({
        id: feed.commentId
      });
      if (response.errors?.length) {
        const errorMessage = `Failed to delete comment: ${response.errors
          .map((error) => error.message)
          .join(', ')}`;
        console.error(errorMessage);
        snackbarStore.set.create(
          'error',
          i18n.t({
            id: 'entity-comment.errors.delete',
            message: 'Error deleting comment. Please try again later!'
          })
        );
        throw new Error(errorMessage);
      }

      return response.data;
    },
    onMutate: async ({ feed }) => {
      const onError = await removeCommentFromStore({ feed });
      return { onError };
    },
    onError: (_, __, context) => {
      context?.onError?.();
    }
  });
}
