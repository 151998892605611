import jsCookie from 'js-cookie';
import { createStore } from 'zustand-x';

export type ColorMode = 'light' | 'dark';

export type AppStore = {
  colorModeState: ColorMode;
  feedbackOpen: boolean;
  onboardingOpen: boolean;
};

/**
 * Defines `appStore` for managing the color mode (light or dark), feedback state across the application.
 * This store utilizes Zustand for state management and `zustand-sync-tabs` for synchronizing
 * color mode states across browser tabs. It's designed to work seamlessly in both server-side
 * and client-side environments, making it suitable for isomorphic JavaScript applications.
 *
 * State:
 * - `colorModeState`: Represents the current color mode of the application, initialized to 'light'.
 * - `feedbackOpen`: Represents the feedback modal state.
 * - `onboardingOpen`: Represents the onboarding modal state.
 *
 * Selectors:
 * - `colorMode()`: Returns the current color mode state.
 * - `isDarkMode()`: Determines if the current color mode is set to 'dark'.
 *
 * Actions:
 * - `setServerColorMode`: Sets the color mode in both the store and server-side cookies, allowing for persistent user preferences across sessions and devices.
 *
 * Usage Guidelines:
 * - Direct manipulation of the store's state is not recommended to prevent issues with state hydration, especially during the initial render in SSR contexts.
 * - Use extended selectors to access the store's state, ensuring the server-rendered UI matches the client-rendered UI by reflecting the hydrated state from the server accurately.
 *
 * Good Practices:
 * - Use `appStore.useTracked.colorMode()` for accessing the current color mode in client-side components to benefit from automatic re-renders when the color mode changes.
 * - On the server side, `appStore.get.colorMode()` can be used to fetch the current color mode without subscribing to state changes, ideal for SSR where reactivity is not required.
 *
 * Avoid:
 * - Directly accessing `colorModeState` through `appStore.useTracked.colorModeState()` or `appStore.get.colorModeState()` can result in inconsistencies between the server-rendered and client-rendered UIs, especially if not using extended selectors properly.
 */
export function createAppStore(colorMode: ColorMode = 'light') {
  return createStore('app')<AppStore>({
    colorModeState: colorMode,
    feedbackOpen: false,
    onboardingOpen: false
  })
    .extendSelectors((store, get) => ({
      colorMode() {
        return get.colorModeState();
      },
      isDarkMode() {
        return get.colorModeState() === 'dark';
      }
    }))
    .extendActions((set) => ({
      async setServerColorMode(colorMode: ColorMode) {
        jsCookie.set('mui-color-mode', colorMode, {
          path: '/',
          expires: 365
        });
        set.colorModeState(colorMode);
      }
    }));
}
