'use client';

import {
  CommentPinnedState,
  CommentType,
  CreateCommentInput,
  Entity
} from '@/__generated__/API';
import { useUpdatedValue } from '@/hooks/use-updated-value';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useAuthStore } from '@/stores/auth-store-provider';
import { snackbarStore } from '@/stores/snackbar-store';
import { useLingui } from '@lingui/react';
import {
  InfiniteData,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { useCallback, useEffect } from 'react';
import {
  FlattenedCommunityFeedData,
  getCommunityFeedInfiniteQueryKey
} from '../../../community/api/community-feed';
import { commentCreateSubscription } from './graphql';
import {
  CommentSelectionSetResponse,
  InfiniteCommentsPaginatedResponse,
  listCommentsQueryKey,
  listCommentsSelectionSet
} from './read';
import { useUpdateSocialProofInStore } from '@/api/social-proof/utils';

export type CreateCommentMutationInput = Pick<
  CreateCommentInput,
  'threadId' | 'parentId' | 'content' | 'type' | 'parentType' | 'images'
>;

/**
 * Custom hook to add a new comment to the store (React Query cache).
 * It updates both the comments list and social proof count.
 *
 * @returns Function to add a new comment to the store.
 */
function useAddCommentToStore() {
  const queryClient = useQueryClient();

  return useCallback(
    async (newComment: CommentSelectionSetResponse) => {
      const threadId = newComment.threadId;
      const parentId = newComment.parentId;
      const type = newComment.type ?? CommentType.COMMENT;
      if (!parentId) {
        return;
      }

      const commentsQueryKeyPrefix = listCommentsQueryKey({
        threadId,
        parentId,
        type
      });

      const communityFeedQueryKeyPrefix = getCommunityFeedInfiniteQueryKey();

      await Promise.all([
        queryClient.cancelQueries({
          queryKey: commentsQueryKeyPrefix
        }),
        queryClient.cancelQueries({
          queryKey: communityFeedQueryKeyPrefix
        })
      ]);

      const previousCommentsData =
        queryClient.getQueriesData<InfiniteCommentsPaginatedResponse>({
          queryKey: commentsQueryKeyPrefix
        });

      previousCommentsData.forEach(([queryKey, previousComments]) => {
        if (previousComments) {
          // Update the comments list query with the new comment
          queryClient.setQueryData<InfiniteCommentsPaginatedResponse>(
            queryKey,
            () => {
              // Add the new comment to the beginning of the comments list
              return produce(previousComments, (draft) => {
                // Increment parent reply count
                for (const page of draft.pages) {
                  for (const comment of page.comments) {
                    if (comment.id === parentId) {
                      comment.commentsCount = (comment.commentsCount ?? 0) + 1;
                    }
                  }
                }

                const firstPage = draft.pages.at(0);
                if (firstPage) {
                  firstPage.comments.unshift(newComment);
                }
              });
            }
          );
        }
      });

      const previousCommunityFeed = queryClient.getQueriesData<
        InfiniteData<FlattenedCommunityFeedData> | undefined
      >({
        queryKey: communityFeedQueryKeyPrefix
      });

      queryClient.setQueriesData<
        InfiniteData<FlattenedCommunityFeedData> | undefined
      >(
        {
          queryKey: communityFeedQueryKeyPrefix
        },
        (old) => {
          if (!old) {
            return old;
          }
          return produce(old, (draft) => {
            for (const page of draft.pages) {
              const comment = page.itemsMap?.[parentId];
              if (comment) {
                comment.commentsCount = (comment.commentsCount ?? 0) + 1;
              }
            }
          });
        }
      );

      return () => {
        // Revert comments state
        for (const [key, data] of previousCommentsData) {
          queryClient.setQueryData(key, data);
        }

        // Revert community feed
        for (const [key, data] of previousCommunityFeed) {
          queryClient.setQueryData(key, data);
        }
      };
    },
    [queryClient]
  );
}

type CreateCommentMutationOptions = {
  onSuccess?(): void;
};

/**
 * Custom hook to create a new comment using GraphQL mutation.
 *
 * @param options - Optional success callback.
 * @returns React Query mutation object.
 */
export function useCreateCommentMutation(
  options: CreateCommentMutationOptions = {}
) {
  const authStore = useAuthStore();
  const graphqlClient = useGraphqlClient();
  const { i18n } = useLingui();
  const addCommentToStore = useAddCommentToStore();
  const userId = authStore.useTracked.userId();
  const updateSocialProof = useUpdateSocialProofInStore();

  return useMutation({
    async mutationFn(variables: CreateCommentMutationInput) {
      // Perform the GraphQL mutation to create a new comment
      const response = await graphqlClient.models.Comment.create(
        {
          owner: userId,
          visibility: 'VISIBLE',
          pinnedState: CommentPinnedState.UNPINNED,
          createdAt: dayjs().toISOString(),
          ...variables
        },
        {
          // Documented by amplify but not typed
          // https://docs.amplify.aws/react/build-a-backend/data/query-data/#fetch-only-the-data-you-need-with-custom-selection-set
          // @ts-ignore
          selectionSet: listCommentsSelectionSet
        }
      );
      if (response.errors?.length) {
        const errorMessage = `Failed to create comment: ${response.errors
          .map((error) => error.message)
          .join(', ')}`;
        console.error(errorMessage);
        snackbarStore.set.create(
          'error',
          i18n.t({
            id: 'entity-comment.errors.create',
            message: 'Error creating comment. Please try again later!'
          })
        );
        throw new Error(errorMessage);
      }
      return response.data as unknown as CommentSelectionSetResponse;
    },
    onSuccess: async (data) => {
      if (!data) {
        return;
      }

      updateSocialProof(data.parentId, (old) => ({
        commentsCount: (old?.commentsCount ?? 0) + 1
      }));

      // Update the store with the new comment data if the mutation succeeds
      await addCommentToStore(data);
      options.onSuccess?.();
    }
  });
}

/**
 * Custom hook to subscribe to new comments for a specific comment thread.
 * Updates the store with new comments received via subscription.
 *
 * @param threadId - The ID of the thread to subscribe to.
 * @param options - Options to manage subscription behavior like disabling the subscription etc.
 */
export function useOnCreateCommentSubscription(
  threadId: Entity['id'],
  options?: {
    disabled?: boolean;
    filter?: (comment: CommentSelectionSetResponse) => boolean;
  }
) {
  const authStore = useAuthStore();
  const graphqlClient = useGraphqlClient();
  const addCommentToStore = useAddCommentToStore();
  const userId = authStore.useTracked.userId();

  const disabled = options?.disabled;
  const filterRef = useUpdatedValue(options?.filter);

  useEffect(() => {
    if (!threadId || !userId || disabled) {
      return;
    }
    // Subscribe to new comments for the given entity ID
    const subscription = graphqlClient
      .graphql({
        // Cannot fetch nested fields with generated subscriptions on model
        query: commentCreateSubscription,
        variables: {
          filter: {
            threadId: {
              eq: threadId
            },
            owner: {
              ne: userId
            }
          }
        }
      })
      .subscribe({
        next: async ({ data }) => {
          const comment =
            data.onCreateComment as unknown as CommentSelectionSetResponse;
          let canAddToStore = true;
          if (filterRef.current) {
            canAddToStore = filterRef.current(comment);
          }
          if (canAddToStore) {
            // Add the new comment to the store
            await addCommentToStore(comment);
          }
        }
      });

    return () => {
      // Cleanup the subscription on component unmount
      subscription.unsubscribe();
    };
  }, [addCommentToStore, threadId, graphqlClient, userId, disabled, filterRef]);
}
