import { i18nConfig } from '@/i18n/config';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import duration from 'dayjs/plugin/duration';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import timezone from 'dayjs/plugin/timezone';
import calendar from 'dayjs/plugin/calendar';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(duration);
dayjs.extend(dayOfYear);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(timezone);
dayjs.extend(calendar);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

const locales: Record<string, Promise<ILocale>> = {
  en: import('dayjs/locale/en'),
  es: import('dayjs/locale/es'),
  fr: import('dayjs/locale/fr'),
  pt: import('dayjs/locale/pt')
};

// Throw error in development environment if dayjs locales are not setup
if (process.env.NODE_ENV === 'development') {
  i18nConfig.locales.forEach((locale) => {
    if (!(locale in locales)) {
      throw new Error(`Please setup dayjs locale, missing ${locale}`);
    }
  });
}

export async function setupDayjsLocale(locale: string) {
  await locales[locale];
  dayjs.locale(locale);
}

export function InitializeDayjs({ lang }: { lang: string }) {
  // Render nothing - this component is only included so that the init code
  // above will run client-side

  if (dayjs().locale() !== lang) {
    void setupDayjsLocale(lang);
  }

  return null;
}
