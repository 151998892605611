import { User, UserRole } from '@/types';

export function hasUserRole(user?: User | null, ...groups: UserRole[]) {
  return groups.some((group) => user?.['cognito:groups']?.includes(group));
}

export function isUserAdmin(user?: User | null) {
  return hasUserRole(user, UserRole.Admin);
}

export function canEditContent(user?: User | null) {
  return hasUserRole(user, UserRole.Admin, UserRole.Content);
}

export function isUserDev(user?: User | null) {
  return hasUserRole(user, UserRole.Admin, UserRole.Content, UserRole.Dev);
}
