import { useCallback, useRef } from 'react';

/**
 * A React hook that tracks and returns the number of times the component has rendered.
 *
 * Inside the hook, a `useRef` is utilized to create a mutable `renderCountRef` object that persists
 * for the full lifetime of the component without triggering re-renders. The `current` property of this
 * reference object is initialized to `0` and is incremented on each render of the component.
 *
 * The hook returns a memoized callback function (via `useCallback` with an empty dependency array) that,
 * when invoked, provides the current render count. This approach ensures that the function identity remains
 * constant across re-renders, thereby preventing potential unnecessary re-renders in components that rely on it.
 *
 * @returns A callback function that, when executed, returns the number of times the component has rendered.
 *
 * @example
 * const getRenderCount = useRenderCount();
 *
 * console.log(`Component has rendered ${getRenderCount()} times`);
 */
export function useRenderCount() {
  const renderCountRef = useRef(0);
  renderCountRef.current++;
  return useCallback(() => renderCountRef.current, []);
}
