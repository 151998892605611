import { Entity } from '@/__generated__/API';
import { useGraphqlClient } from '@/libs/amplify/client';
import type { Schema } from '@/schema';
import { useAuthStore } from '@/stores/auth-store-provider';
import { throwGraphqlError } from '@/utils/throw-graphql-error';
import { useQuery } from '@tanstack/react-query';
import { SelectionSet } from 'aws-amplify/data';

export const getUserEntityLikeQueryKey = (
  entityId: Entity['id'] | null | undefined
) => ['entity-like', 'user-entity-like', entityId];

const entityLikeSelectionSet = ['userProfileId', 'entityId', 'id'] as const;

export type EntityLikeSelectionSetResponse = SelectionSet<
  Schema['EntityLike']['type'],
  typeof entityLikeSelectionSet
>;

/**
 * Custom hook to fetch user entity likes using React Query.
 *
 * @param entityId - The ID of the entity.
 * @returns The entry like object for current entity Id and user Id
 */
export function useGetUserEntityLike(
  entityId: Entity['id'] | null | undefined
) {
  const authStore = useAuthStore();
  const graphql = useGraphqlClient();
  const userId = authStore.useTracked.userId();

  return useQuery<EntityLikeSelectionSetResponse | null>({
    queryKey: getUserEntityLikeQueryKey(entityId),
    enabled: !!userId && !!entityId,
    queryFn: async () => {
      if (!userId && !entityId) {
        return null;
      }

      const response =
        await graphql.models.EntityLike.listEntityLikeByEntityIdAndUserProfileId(
          {
            entityId: entityId ?? '',
            userProfileId: { eq: userId }
          },
          {
            selectionSet: entityLikeSelectionSet
          }
        );

      throwGraphqlError(response.errors);

      return response.data[0] ?? null;
    }
  });
}
