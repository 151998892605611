import { AspectRatio } from '@/components/aspect-ratio';
import { Breakpoint } from '@mui/material/styles';
import { SwiperOptions } from 'swiper/types';
import { CarouselProps } from './index';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Unstable_Grid2';
import range from 'lodash-es/range';
import { breakpointSlidesPerView, defaultSwiperProps } from './utils';

export type CarouselSkeletonProps = Pick<
  CarouselProps,
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'slidesPerViewPerBreakpoint'
  | 'spaceBetween'
  | 'skeletonAspectRatio'
>;

function getGridSpacing(spaceBetween: CarouselSkeletonProps['spaceBetween']) {
  const spacing = spaceBetween || defaultSwiperProps.spaceBetween;
  return typeof spacing === 'number' ? spacing / 8 : spacing;
}

function getGridSize(
  breakpoint: Breakpoint,
  slidesPerViewPerBreakpoint?: number,
  swiperOptions?: SwiperOptions
) {
  const slidesPerView =
    slidesPerViewPerBreakpoint ||
    (typeof swiperOptions?.slidesPerView === 'number'
      ? swiperOptions?.slidesPerView
      : breakpointSlidesPerView[breakpoint]);

  return 12 / slidesPerView;
}

export function CarouselSkeleton({
  xs,
  sm,
  md,
  lg,
  xl,
  slidesPerViewPerBreakpoint,
  spaceBetween,
  skeletonAspectRatio
}: CarouselSkeletonProps) {
  return (
    <div>
      <Grid
        flexWrap="nowrap"
        overflow="hidden"
        container
        spacing={getGridSpacing(spaceBetween)}
      >
        {range(breakpointSlidesPerView.xl * 2).map((index) => (
          <Grid
            key={index}
            flex="none"
            xs={getGridSize('xs', slidesPerViewPerBreakpoint, xs)}
            sm={getGridSize('sm', slidesPerViewPerBreakpoint, sm)}
            md={getGridSize('md', slidesPerViewPerBreakpoint, md)}
            lg={getGridSize('lg', slidesPerViewPerBreakpoint, lg)}
            xl={getGridSize('xl', slidesPerViewPerBreakpoint, xl)}
          >
            <Card>
              <AspectRatio ratio={skeletonAspectRatio}>
                <Skeleton variant="rounded" height="100%" />
              </AspectRatio>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
