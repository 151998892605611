import { CommentType, ModelSortDirection } from '@/__generated__/API';
import { useGraphqlClient } from '@/libs/amplify/client';
import { Schema } from '@/schema';
import { useAuthStore } from '@/stores/auth-store-provider';
import { snackbarStore } from '@/stores/snackbar-store';
import { OverridePathToArray } from '@/types';
import { useLingui } from '@lingui/react';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { SelectionSet } from 'aws-amplify/data';
import compact from 'lodash-es/compact';

const MAX_COMMENTS_PAGE_SIZE = 10;
const MAX_COMMENT_REPLIES_PAGE_SIZE = 3;

/** Selection set for comments, defining the fields to be fetched */
export const listCommentsSelectionSet = [
  'id',
  'content',
  'author.publicProfileId',
  'author.displayName',
  'author.profileImage.*',
  'author.badges.*',
  'images.*',
  'owner',
  'createdAt',
  'updatedAt',
  'threadId',
  'parentId',
  'parentType',
  'type',
  'likesCount',
  'commentsCount',
  'version',
  'visibility',
  'pinnedState'
] as const;

export type CommentSelectionSetResponse = OverridePathToArray<
  SelectionSet<Schema['Comment']['type'], typeof listCommentsSelectionSet>,
  'images'
>;

export type CommentsPaginatedResponse = {
  comments: CommentSelectionSetResponse[];
  nextToken: string | null;
};

export type InfiniteCommentsPaginatedResponse =
  | InfiniteData<CommentsPaginatedResponse>
  | undefined;

export type ListCommentsInput = {
  threadId: CommentSelectionSetResponse['threadId'];
  parentId: CommentSelectionSetResponse['parentId'];
  type: NonNullable<CommentSelectionSetResponse['type']>;
};

/**
 * Generates a unique query key for the `useListComments` hook.
 *
 * @param input - The input parameters for filtering comments.
 * @param limit - Optional limit on the number of comments to fetch.
 * @returns A unique key for the query.
 */
export const listCommentsQueryKey = (
  input: ListCommentsInput,
  limit?: number
) => ['comments', 'list', input, ...(limit ? [{ limit }] : [])] as const;

/**
 * Custom hook to fetch a paginated list of comments.
 * Supports infinite scrolling via React Query's `useInfiniteQuery`.
 *
 * @param input - Parameters for filtering comments, such as type and parent ID.
 * @param limit - Optional limit on the number of comments to fetch.
 * @returns An infinite query with paginated comments.
 */
export function useListComments(input: ListCommentsInput, limit?: number) {
  const authStore = useAuthStore();
  const graphqlClient = useGraphqlClient();
  const { i18n } = useLingui();
  const userId = authStore.useTracked.userId();

  return useInfiniteQuery({
    initialPageParam: '',
    enabled: Boolean(userId),
    queryKey: listCommentsQueryKey(input, limit),
    queryFn: async ({ pageParam }) => {
      const { type, threadId, parentId } = input;

      const paginationLimit =
        type === CommentType.COMMENT
          ? (limit ?? MAX_COMMENTS_PAGE_SIZE)
          : (limit ?? MAX_COMMENT_REPLIES_PAGE_SIZE);

      const response =
        await graphqlClient.models.Comment.listCommentByThreadIdAndParentIdAndCreatedAt(
          {
            threadId,
            parentIdCreatedAt: {
              beginsWith: { parentId, createdAt: '' }
            }
          },
          {
            nextToken: pageParam,
            limit: paginationLimit,
            selectionSet: listCommentsSelectionSet,
            sortDirection: ModelSortDirection.DESC
          }
        );

      if (response.errors?.length) {
        const errorMessage = `Failed to fetch comments: ${response.errors
          .map((error) => error.message)
          .join(', ')}`;
        console.error(errorMessage);
        snackbarStore.set.create(
          'error',
          i18n.t({
            id: 'entity-comment.errors.list',
            message: 'Error retrieving comments. Please try again later!'
          })
        );
        throw new Error(errorMessage);
      }

      return {
        comments: compact(
          response.data
        ) as unknown as CommentSelectionSetResponse[],
        nextToken: response.nextToken ?? null
      };
    },
    select: (infiniteData) => {
      return infiniteData.pages.flatMap((page) => page.comments);
    },
    getNextPageParam: (lastPage) => lastPage.nextToken
  });
}
