'use client';

import { Trans } from '@lingui/macro';
import { Box, Link } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { useRef, useCallback } from 'react';
import { useBoolean, useResizeObserver } from 'usehooks-ts';

export type ReadMoreProps = BoxProps & {
  lines?: number;
};

export function ReadMore({
  lines = 2,
  ...boxProps
}: ReadMoreProps) {
  const { value: isExpanded, toggle: toggleExpansion } = useBoolean(false);
  const { value: showButton, setValue: setShowButton } = useBoolean(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const checkTruncation = useCallback(() => {
    if (contentRef.current) {
      const shouldShowButton = contentRef.current.scrollHeight > contentRef.current.clientHeight;
      setShowButton(shouldShowButton);
    }
  }, [setShowButton]);

  useResizeObserver({
    ref: contentRef,
    onResize: checkTruncation
  });

  return (
    <>
      <Box
        {...boxProps}
        ref={contentRef}
        sx={[
          {
            overflow: 'hidden'
          },
          !isExpanded && {
            WebkitLineClamp: lines,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical"
          },
          ...(Array.isArray(boxProps?.sx) ? boxProps.sx : [boxProps?.sx])
        ]}
      />

      {(showButton || isExpanded) && (
        <Link variant="body2" onClick={toggleExpansion}>
          {isExpanded ? (
            <Trans id="common.readLess">Read less</Trans>
          ) : (
            <Trans id="common.readMore">Read more</Trans>
          )}
        </Link>
      )}
    </>
  );
}
