import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

export function HashDeepLinks() {
  const pathname = usePathname();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleDeepLink = () => {
      const hash = pathname?.split('#')[1];
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    handleDeepLink();
  }, [pathname]);

  return null;
}
