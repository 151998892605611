'use client';

import {
  defaultShouldDehydrateQuery,
  QueryClient
} from '@tanstack/react-query';

export function getQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false,
        retry: false
      },
      mutations: {
        retry: false,
        retryDelay: (failureCount) => 2 ** failureCount * 1000
      },
      dehydrate: {
        // include pending queries in dehydration
        shouldDehydrateQuery: (query) =>
          defaultShouldDehydrateQuery(query) || query.state.status === 'pending'
      }
    }
  });
}
