import { createContext, type ReactNode, useContext, useRef } from 'react';
import { createAppStore, ColorMode } from './app-store';

export type AppStoreApi = ReturnType<typeof createAppStore>;

export const AppStoreContext = createContext<AppStoreApi | undefined>(
  undefined
);

export interface AppStoreProviderProps {
  children: ReactNode;
  colorMode?: ColorMode;
}

export function AppStoreProvider({
  children,
  colorMode
}: AppStoreProviderProps) {
  const storeRef = useRef<AppStoreApi>();

  if (!storeRef.current) {
    storeRef.current = createAppStore(colorMode);
  }

  return (
    <AppStoreContext.Provider value={storeRef.current}>
      {children}
    </AppStoreContext.Provider>
  );
}

export function useAppStore() {
  const authStoreContext = useContext(AppStoreContext);

  if (!authStoreContext) {
    throw new Error(`useAppStore must be used within AppStoreProvider`);
  }

  return authStoreContext;
}
