'use client';

import { BoxProps, styled } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';

export type AspectRatioProps = BoxProps & {
  ratio?: [number, number];
  disabled?: boolean;
};

const AspectRatioBox = styled(Box, {
  shouldForwardProp: (propName) => !['ratio'].includes(propName as string)
  // eslint-disable-next-line lingui/text-restrictions
})<AspectRatioProps>`
  position: relative;
  width: 100%;
  border-radius: inherit;

  &::before {
    content: '';
    display: block;
    border-radius: inherit;
    padding-top: ${(props) =>
      props.ratio ? (props.ratio[1] / props.ratio[0]) * 100 : (9 / 16) * 100}%;
  }

  @supports (aspect-ratio: 1 / 1) {
    &::before {
      content: none;
    }

    aspect-ratio: ${(props) =>
      props.ratio ? `${props.ratio[0]} / ${props.ratio[1]}` : '16 / 9'};
  }
`;

const AspectRatioContent = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
`;

export function AspectRatio({
  disabled,
  ratio,
  children,
  ...props
}: AspectRatioProps) {
  if (disabled) {
    return (
      <Box position="relative" borderRadius="inherit" {...props}>
        {children}
      </Box>
    );
  }
  return (
    <AspectRatioBox ratio={ratio ?? [16, 9]} {...props}>
      <AspectRatioContent>{children}</AspectRatioContent>
    </AspectRatioBox>
  );
}
