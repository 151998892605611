'use client';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { CloseOutlined, VerifiedUser } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  bindDialog,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import Image from 'next/image';
import { useBoolean } from 'usehooks-ts';

import img from '@/assets/images/journal-privacy.webp';

export function JournalPrivacy() {
  const dialogState = usePopupState({
    variant: 'dialog'
  });
  const { i18n } = useLingui();

  const privacyTitle = i18n.t({
    id: 'journal.privacyTitle',
    message: '100% private'
  });

  return (
    <>
      <Tooltip title={i18n.t('journal.aria.privacy-information')}>
        <IconButton {...bindTrigger(dialogState)} color="secondary">
          <VerifiedUser />
        </IconButton>
      </Tooltip>
      <Dialog {...bindDialog(dialogState)}>
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
          onClick={dialogState.close}
        >
          <CloseOutlined />
        </IconButton>

        <DialogContent>
          <Stack
            sx={{ textAlign: 'center' }}
            alignItems="center"
            direction="column"
          >
            <Image width={320} src={img} alt={privacyTitle} />
            <Typography variant="h4" fontWeight={600}>
              {privacyTitle}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Trans id="journal.privacyDescription">
                We take your privacy seriously. That&apos;s why we&apos;ve built
                GrowthDay with rock-solid security to ensure that your personal
                information is yours alone.
              </Trans>
            </Typography>
            <Link href="/settings/privacy" variant="body2">
              <Trans id="journal.privacyLink">
                How we protect your information
              </Trans>
            </Link>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
