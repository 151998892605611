import { ThumbPreview } from '@/__generated__/API';

/**
 * Generates the content for a VTT (Web Video Text Tracks) file based on the provided `ThumbPreview` object.
 *
 * This function constructs a VTT file content that maps video timestamps to thumbnail images within a sprite,
 * facilitating thumbnail previews during video scrubbing. It calculates the layout of thumbnails within the sprite
 * by determining the number of columns and rows based on the sprite's dimensions and the total frame count. Each
 * thumbnail's display duration is calculated using the video's total duration and the number of frames. The function
 * then formats this information into VTT standard time codes and coordinates, specifying the exact position of each
 * thumbnail within the sprite for every corresponding time segment in the video.
 *
 * @param input - An optional `ThumbPreview` object containing metadata for the thumbnail sprite, such as its URL,
 *                dimensions, and the number of frames. If the input is `null` or `undefined`, the function returns
 *                `undefined`.
 * @returns A string representing the VTT content, formatted according to VTT standards, which includes timestamps
 *          and coordinates for each frame within the thumbnail sprite. If no input is provided, the function returns
 *          `undefined`. This VTT content can be used directly in `<track>` elements of HTML `<video>` players to
 *          display thumbnail previews during scrubbing.
 */
export function getVttContent(input?: Omit<ThumbPreview, '__typename'> | null) {
  if (input) {
    const { url, frameHeight, width, frameWidth, frameCount, duration } = input;
    // Calculate number of columns and rows based on the sprite dimensions and frame count
    const cols = Math.floor(width / frameWidth);
    const rows = Math.ceil(frameCount / cols);

    // Calculate the duration each frame should be displayed
    const frameDuration = duration / frameCount;

    let vttContent = 'WEBVTT\n\n';
    let currentFrame = 0;

    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        if (currentFrame >= frameCount) break; // Stop if we've reached the frame count

        // Calculate the start and end time for this frame
        const startTime = frameDuration * currentFrame;
        const endTime = frameDuration * (currentFrame + 1);

        // Calculate the x and y position for this frame within the sprite
        const x = col * frameWidth;
        const y = row * frameHeight;

        // Format the start and end times into hh:mm:ss.fff format
        const startTimestamp = new Date(startTime * 1000)
          .toISOString()
          .slice(11, -1);
        const endTimestamp = new Date(endTime * 1000)
          .toISOString()
          .slice(11, -1);

        // Add the frame details to the VTT content
        vttContent += `${startTimestamp} --> ${endTimestamp}\n`;
        vttContent += `${url}#xywh=${x},${y},${frameWidth},${frameHeight}\n\n`;

        currentFrame++;
      }
    }

    return vttContent;
  }
}
