import {
  CaptionOption,
  IconComponent,
  Menu,
  MenuPlacement,
  Tooltip,
  useCaptionOptions,
  useMediaProvider,
  useMediaState,
  usePlaybackRateOptions,
  useVideoQualityOptions
} from '@vidstack/react';
import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClosedCaptionsIcon,
  OdometerIcon,
  SettingsIcon,
  SettingsMenuIcon
} from '@vidstack/react/icons';
import { useDefaultLayoutWord } from '@vidstack/react/player/layouts/default';

export function SettingsMenu({
  placement = 'top end'
}: {
  placement?: MenuPlacement;
}) {
  const settingsLabel = useDefaultLayoutWord('Settings');
  const provider = useMediaProvider();
  const isAudio = provider?.type === 'audio';
  const isCaptionsDisabled = useCaptionOptions().disabled;

  if (isAudio && isCaptionsDisabled) {
    return null;
  }

  return (
    <Menu.Root className="vds-menu vds-settings-menu">
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <Menu.Button
            className="vds-menu-button vds-button"
            aria-label={settingsLabel}
          >
            <SettingsIcon className="vds-rotate-icon vds-icon" />
          </Menu.Button>
        </Tooltip.Trigger>
        <Tooltip.Content className="vds-tooltip-content" placement={placement}>
          {settingsLabel}
        </Tooltip.Content>
      </Tooltip.Root>
      <Menu.Items className="vds-menu-items" placement={placement} offset={0}>
        <CaptionsSubmenu />
        <QualitySubmenu />
        <SpeedSubmenu />
      </Menu.Items>
    </Menu.Root>
  );
}

interface SubmenuButtonProps {
  label: string;
  hint: string;
  disabled?: boolean;
  icon: IconComponent;
}

function SubmenuButton({
  label,
  hint,
  icon: Icon,
  disabled
}: SubmenuButtonProps) {
  return (
    <Menu.Button className="vds-menu-item" disabled={disabled}>
      <ChevronLeftIcon className="vds-menu-close-icon" />
      <Icon className="vds-icon" />
      <span className="vds-menu-item-label">{label}</span>
      <span className="vds-menu-item-hint">{hint}</span>
      <ChevronRightIcon className="vds-menu-open-icon" />
    </Menu.Button>
  );
}

function SpeedSubmenu() {
  const speedLabel = useDefaultLayoutWord('Speed');
  const normalLabel = useDefaultLayoutWord('Normal');
  const $live = useMediaState('live');
  const options = usePlaybackRateOptions(),
    hint =
      options.selectedValue === '1' ? normalLabel : options.selectedValue + 'x';
  return (
    <Menu.Root>
      <SubmenuButton
        label={speedLabel}
        hint={hint}
        disabled={$live || options.disabled}
        icon={OdometerIcon}
      />
      <Menu.Content className="vds-menu-items">
        <Menu.RadioGroup
          className="vds-radio-group"
          value={options.selectedValue}
        >
          {options.map(({ label, value, select }) => (
            <Menu.Radio
              className="vds-radio"
              value={value}
              onSelect={select}
              key={value}
            >
              <CheckIcon className="vds-icon" />
              <span className="vds-radio-label">{label}</span>
            </Menu.Radio>
          ))}
        </Menu.RadioGroup>
      </Menu.Content>
    </Menu.Root>
  );
}

function QualitySubmenu() {
  const qualityLabel = useDefaultLayoutWord('Quality');
  const autoLabel = useDefaultLayoutWord('Auto');
  const options = useVideoQualityOptions(),
    currentQuality = options.selectedQuality?.height,
    hint =
      options.selectedValue !== 'auto' && currentQuality
        ? `${currentQuality}p`
        : `${autoLabel}${currentQuality ? ` (${currentQuality}p)` : ''}`;
  return (
    <Menu.Root>
      <SubmenuButton
        label={qualityLabel}
        hint={hint}
        disabled={options.disabled}
        icon={SettingsMenuIcon}
      />
      <Menu.Content className="vds-menu-items">
        <Menu.RadioGroup
          className="vds-radio-group"
          value={options.selectedValue}
        >
          {options.map(({ label, value, bitrateText, select }) => (
            <Menu.Radio
              className="vds-radio"
              value={value}
              onSelect={select}
              key={value}
            >
              <CheckIcon className="vds-icon" />
              <span className="vds-radio-label">{label}</span>
              {bitrateText ? (
                <span className="vds-radio-hint">{bitrateText}</span>
              ) : null}
            </Menu.Radio>
          ))}
        </Menu.RadioGroup>
      </Menu.Content>
    </Menu.Root>
  );
}

function CaptionsSubmenu() {
  const offLabel = useDefaultLayoutWord('Off');

  const options = useCaptionOptions(),
    hint = options.selectedTrack?.label ?? offLabel;

  const optionsMap: Record<string, CaptionOption> = {};
  options.forEach((option) => {
    if (!optionsMap[option.label] || option.value.startsWith('hls')) {
      optionsMap[option.label] = option;
    }
  });

  return (
    <Menu.Root>
      <SubmenuButton
        label="Captions"
        hint={hint}
        disabled={options.disabled}
        icon={ClosedCaptionsIcon}
      />
      <Menu.Content className="vds-menu-items">
        <Menu.RadioGroup
          className="vds-radio-group"
          value={options.selectedValue}
        >
          {Object.values(optionsMap).map(({ label, value, select }) => (
            <Menu.Radio
              className="vds-radio"
              value={value}
              onSelect={select}
              key={value}
            >
              <CheckIcon className="vds-icon" />
              <span className="vds-radio-label">{label}</span>
            </Menu.Radio>
          ))}
        </Menu.RadioGroup>
      </Menu.Content>
    </Menu.Root>
  );
}
