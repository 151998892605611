import {
  getOfferingSelectionSet,
  Offering,
  RevenueCatSubscriber
} from '@/entitlements/types';
import { GraphqlClient } from '@/libs/amplify/types';
import { safeJsonParse } from '@/utils/safe-json';
import type { DefaultError } from '@tanstack/query-core';
import { UseSuspenseQueryOptions } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { omitBy } from 'lodash-es';

export function getSubscriberInfoQueryKey(owner: string) {
  return ['GetSubscriberInfo', owner] as const;
}

export type GetSubscriberInfoQueryKey = ReturnType<
  typeof getSubscriberInfoQueryKey
>;

export function getSubscriberInfoQuery(
  graphqlClient: GraphqlClient,
  owner: string
): UseSuspenseQueryOptions<
  RevenueCatSubscriber | null,
  DefaultError,
  RevenueCatSubscriber | null,
  GetSubscriberInfoQueryKey
> {
  const queryKey = getSubscriberInfoQueryKey(owner);
  return {
    queryKey,
    queryFn: async () => {
      if (!owner) {
        return null;
      }
      const { data, errors } = await graphqlClient.queries.getEntitlements();

      if (errors?.length) {
        console.error(errors);
      }

      if (!data?.subscriber) {
        return null;
      }

      return produce(data.subscriber, (draft) => {
        delete draft.otherPurchases;
        draft.entitlements = omitBy(
          safeJsonParse(draft.entitlements as any),
          (value) => dayjs().isAfter(value.expires_date)
        );
        draft.nonSubscriptions = safeJsonParse(draft.nonSubscriptions as any);
        draft.subscriptions = safeJsonParse(draft.subscriptions as any);
      }) as RevenueCatSubscriber;
    }
  };
}

export function getOfferingQueryKey(id: string) {
  return ['GetOffering', id] as const;
}

export type GetOfferingQueryKey = ReturnType<typeof getOfferingQueryKey>;

export function getOfferingQuery(
  graphqlClient: GraphqlClient,
  id: string
): UseSuspenseQueryOptions<
  Offering | null,
  DefaultError,
  Offering | null,
  GetOfferingQueryKey
> {
  const queryKey = getOfferingQueryKey(id);
  return {
    queryKey,
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const { data, errors } = await graphqlClient.models.Offering.get(
        { id },
        { selectionSet: getOfferingSelectionSet }
      );

      if (errors?.length) {
        console.error(errors);
      }

      return (data as Offering) || null;
    }
  };
}
