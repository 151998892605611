'use client';
import { useCallback } from 'react';
import { createPathWithLocale } from '../utils/create-path-with-locale';
import { useRouter } from '@/hooks/use-router';

export function useChangeLocale() {
  const router = useRouter();

  return useCallback(
    (locale: string) => {
      router.replace(
        createPathWithLocale({
          pathname: window.location.pathname,
          search: window.location.search,
          locale
        })
      );
      router.refresh();
    },
    [router]
  );
}
