'use client';

import { useRouter } from '@/hooks/use-router';
import { ConfirmNavigationContext } from '@/providers/confirm-navigation-provider';
import { decomposeUrl } from '@/utils/decompose-url';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  useCallback,
  useContext
} from 'react';

function LinkBehaviourInternal(
  props: NextLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  const [shouldConfirm] = useContext(ConfirmNavigationContext);
  const router = useRouter();

  const onClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      if (shouldConfirm) {
        // Delegate to router for confirmation
        event.preventDefault();
        const href = (event.target as HTMLAnchorElement).href;
        if (props.replace) {
          router.replace(href);
        } else {
          router.push(href);
        }
      }
      props.onClick?.(event);
    },
    [props, router, shouldConfirm]
  );

  if (!props.href) {
    const preventDefault = (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      props.onClick?.(event);
    };
    return <a ref={ref} {...props} href="#!" onClick={preventDefault} />;
  }

  const { href, isLocal, path, target } = decomposeUrl(props.href.toString());

  return (
    <NextLink
      ref={ref}
      {...props}
      target={target}
      href={isLocal ? path : href}
      onClick={onClick}
      data-prevent-nprogress={shouldConfirm}
    />
  );
}

export const LinkBehaviour = forwardRef(LinkBehaviourInternal);
