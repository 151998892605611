'use client';

import { useUpdateSocialProofInStore } from '@/api/social-proof/utils';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  getSocialProofQueryKey,
  SocialProofQueryResponse,
  socialProofSelectionSet
} from '@/api/social-proof/read';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import UpdateSocialProof from '../social-proof/update-social-proof.graphql';
import CreateSocialProof from '../social-proof/create-social-proof.graphql';
import { throwGraphqlError } from '@/utils/throw-graphql-error';

export const useUpdateShareCount = () => {
  const graphqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const updateSocialProof = useUpdateSocialProofInStore();

  return useMutation({
    mutationFn: async (parentId: string) => {
      const { data: socialProof } = await graphqlClient.models.SocialProof.get(
        { parentId },
        {
          selectionSet: socialProofSelectionSet
        }
      );

      if (socialProof) {
        const { data, errors } = (await graphqlClient.graphql({
          query: UpdateSocialProof,
          variables: {
            input: {
              parentId,
              version: (socialProof.version ?? 0) + 1,
              sharesCount: (socialProof.sharesCount ?? 0) + 1
            },
            condition: { version: { eq: socialProof.version } }
          }
        })) as GraphQLResult<{
          updateSocialProof: SocialProofQueryResponse;
        }>;

        throwGraphqlError(errors);

        return data.updateSocialProof;
      }

      const { data, errors } = (await graphqlClient.graphql({
        query: CreateSocialProof,
        variables: {
          input: {
            parentId,
            version: 1,
            sharesCount: 1
          }
        }
      })) as GraphQLResult<{
        createSocialProof: SocialProofQueryResponse;
      }>;

      throwGraphqlError(errors);

      return data.createSocialProof;
    },
    onMutate: (parentId) => {
      const queryKey = getSocialProofQueryKey(parentId);
      const previousSocialProof = queryClient.getQueryData(queryKey);

      updateSocialProof(parentId, (old) => ({
        sharesCount: (old?.sharesCount ?? 0) + 1
      }));

      return previousSocialProof;
    },
    onError: (error, parentId, previousSocialProof) => {
      console.error('Error updating share count', error);
      if (previousSocialProof) {
        updateSocialProof(parentId, () => previousSocialProof);
      }
    },
    onSuccess: (socialProof, parentId) => {
      if (socialProof) {
        updateSocialProof(parentId, () => socialProof);
      }
    },
    retry: 3
  });
};
