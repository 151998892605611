import { GraphqlClient } from '@/libs/amplify/types';
import {
  QueryKey,
  UseSuspenseInfiniteQueryOptions
} from '@tanstack/react-query';
import { compact, uniqBy } from 'lodash-es';
import { listRecentlyPlayedQueryKey } from './query-options';
import { RecentlyPlayedItem, recentlyPlayedSelectionSet } from './types';
import { throwGraphqlError } from '@/utils/throw-graphql-error';

export type ListRecentlyPlayedPage = {
  data: RecentlyPlayedItem[];
  nextToken: string | null | undefined;
};

export function listRecentlyPlayed(
  graphqlClient: GraphqlClient,
  owner: string,
  numResults: number
): UseSuspenseInfiniteQueryOptions<
  ListRecentlyPlayedPage,
  unknown,
  RecentlyPlayedItem[],
  ListRecentlyPlayedPage,
  QueryKey,
  string | null
> {
  return {
    initialPageParam: null,
    queryKey: listRecentlyPlayedQueryKey(owner),
    queryFn: async ({ pageParam }) => {
      if (!owner) {
        return {
          data: [],
          nextToken: null
        };
      }
      const { data, errors, nextToken } =
        await graphqlClient.models.RecentlyPlayed.listRecentlyPlayedByOwnerAndLastPlayedAt(
          {
            owner
          },
          {
            nextToken: pageParam,
            limit: numResults,
            filter: {
              or: [
                {
                  entityType: { eq: 'COURSE' }
                },
                {
                  entityType: { eq: 'REPLAY' }
                },
                {
                  entityType: { eq: 'GROWTH_REC' }
                }
              ]
            },
            sortDirection: 'DESC',
            selectionSet: recentlyPlayedSelectionSet
          }
        );

      throwGraphqlError(errors);

      return {
        data: compact(data) as unknown as RecentlyPlayedItem[],
        nextToken
      };
    },
    getNextPageParam(lastResult) {
      return lastResult.nextToken;
    },
    select: (infiniteData) => {
      const items = infiniteData.pages.flatMap((page) => page.data);
      return uniqBy(items, 'entityId');
    }
  };
}
