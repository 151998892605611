'use client';
import { getLeaderboardQuery } from '@/api/leaderboard/get-leaderboard';
import { useOnUpdateLeaderboard } from '@/api/leaderboard/use-on-update-leaderboard';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useAuthStore } from '@/stores/auth-store-provider';
import { useSuspenseQuery } from '@tanstack/react-query';

export function HighestStreakCount({ month }: { month: string }) {
  const authStore = useAuthStore();
  const owner = authStore.useTracked.userId() ?? '';
  const graphqlClient = useGraphqlClient();
  const { data } = useSuspenseQuery(
    getLeaderboardQuery(graphqlClient, owner, month)
  );
  useOnUpdateLeaderboard(owner, month);
  return data?.highestStreakCount ?? 0;
}
