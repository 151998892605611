'use client';

import { EMAIL_REGEX, PASSWORD_REGEX } from '@/utils/regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { I18n } from '@lingui/core';
import { useBoolean } from 'usehooks-ts';
import * as yup from 'yup';
import { SocialSignup } from '../../components/social-signup';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Link, { LinkProps } from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { snackbarStore } from '@/stores/snackbar-store';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import PasswordElement from '../../components/password-element';
import { SignInCredentialsInput } from '@/stores/auth-store';
import { useAuthStore } from '@/stores/auth-store-provider';
import { useSearchParams } from 'next/navigation';

export type SignInFormProps = {
  ForgotPasswordProps?: LinkProps;
  hideSocialSignup?: boolean;
  skipRedirect?: boolean;
  afterSubmit?: () => void | Promise<void>;
  defaultValues?: Partial<SignInCredentialsInput>;
};

function validationSchemaResolver(i18n: I18n) {
  const schema: yup.ObjectSchema<SignInCredentialsInput> = yup.object().shape({
    email: yup
      .string()
      .required(i18n.t('validations.required'))
      .matches(EMAIL_REGEX, i18n.t('validations.invalid-email')),
    password: yup
      .string()
      .required(i18n.t('validations.required'))
      .matches(PASSWORD_REGEX, i18n.t('validations.invalid-password'))
  });
  return yupResolver(schema);
}

const analyticsParentId = 'Sign In Page';

export function SignInForm({
  ForgotPasswordProps,
  hideSocialSignup,
  skipRedirect,
  afterSubmit,
  defaultValues
}: SignInFormProps) {
  const authStore = useAuthStore();
  const { i18n } = useLingui();
  const {
    value: isLoading,
    setTrue: startLoading,
    setFalse: endLoading
  } = useBoolean();

  const formContext = useForm<SignInCredentialsInput>({
    defaultValues: {
      email: defaultValues?.email || '',
      password: defaultValues?.password || ''
    },
    resolver: validationSchemaResolver(i18n)
  });

  const handleSignIn = async (data: SignInCredentialsInput) => {
    startLoading();
    try {
      analytics.track('Click', {
        element_id: 'sign-in-button',
        type: 'BUTTON',
        parent_id: analyticsParentId
      });
      snackbarStore.set.close();

      await authStore.set.signIn(data, skipRedirect);

      await afterSubmit?.();
    } catch (e) {
      console.log('error: ', e);
      snackbarStore.set.create(
        'error',
        i18n.t('auth-errors.incorrect-username-password-error')
      );
      endLoading();
    }
  };

  const search = useSearchParams();

  return (
    <FormContainer formContext={formContext} onSuccess={handleSignIn}>
      <Stack spacing={{ xs: 1.5, sm: 2, md: 3 }} direction="column">
        <TextFieldElement
          id="email-input"
          data-testid="email-input"
          name="email"
          type="email"
          placeholder={i18n.t('sign-in.email-placeholder')}
          margin="none"
          aria-label={i18n.t('sign-in.email-placeholder')}
          required
          autoComplete="email"
          onClick={() => {
            analytics.track('Click', {
              element_id: 'email-input',
              type: 'TEXT',
              parent_id: analyticsParentId
            });
          }}
        />
        <PasswordElement
          id="password-input"
          data-testid="password-input"
          name="password"
          placeholder={i18n.t('sign-in.password-placeholder')}
          margin="none"
          required
          onClick={() => {
            analytics.track('Click', {
              element_id: 'password-input',
              type: 'TEXT',
              parent_id: analyticsParentId
            });
          }}
        />
        <Link
          variant="body2"
          color="text.secondary"
          underline="always"
          href={
            search.size
              ? `/forgot-password?${search.toString()}`
              : `/forgot-password`
          }
          {...ForgotPasswordProps}
          onClick={(event) => {
            analytics.track('Click', {
              element_id: 'forgot-password-button',
              type: 'BUTTON',
              parent_id: analyticsParentId
            });
            ForgotPasswordProps?.onClick?.(event);
          }}
          sx={[
            { alignSelf: 'flex-start' },
            ...(Array.isArray(ForgotPasswordProps?.sx)
              ? ForgotPasswordProps?.sx
              : [ForgotPasswordProps?.sx])
          ]}
        >
          <Trans id="sign-in.forgot-password-link">Forgot your password?</Trans>
        </Link>
        <LoadingButton
          id="sign-in-button"
          data-testid="sign-in-button"
          loading={isLoading}
          type="submit"
          size="large"
          fullWidth
          sx={{ borderRadius: 6 }}
        >
          <Trans id="sign-in.sign-in-button">Log In</Trans>
        </LoadingButton>

        {!hideSocialSignup && (
          <SocialSignup analyticsParentId={analyticsParentId} />
        )}
      </Stack>
    </FormContainer>
  );
}
