'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import capitalize from 'lodash-es/capitalize';

export function useAnalyticsPageView() {
  const pathname = usePathname();
  useEffect(() => {
    const category = pathname === '/' ? 'Home' : capitalize(pathname);
    analytics.page(category);
  }, [pathname]);
}
