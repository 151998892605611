'use client';

import { darken, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function FireIcon({
  faded,
  ...props
}: SvgIconProps & { faded?: boolean }) {
  const { palette } = useTheme();
  const gradientId = `fire_gradient_${faded ? 'faded' : 'secondary'}`;

  const mainColor = faded ? palette.grey[200] : palette.secondary.main;
  const darkenedColor = faded
    ? palette.grey[400]
    : darken(palette.secondary.main, 0.2);
  const centerColor = palette.common.white;

  return (
    <SvgIcon
      width="20"
      height="20"
      {...props}
      sx={[
        { fill: 'none' },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx])
      ]}
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0175 6.24486C15.2004 6.21479 15.3874 6.27361 15.5205 6.40308C17.0694 7.90909 18.1724 9.92471 18.1724 12.0786C18.1724 16.4464 14.3691 20 9.70145 20C6.13855 20 3.01184 17.5539 2.13703 14.0518C1.96653 13.3873 1.88008 12.7235 1.88008 12.0786C1.88008 10.8128 2.17578 9.59596 2.75899 8.46201C3.33293 7.34721 4.17688 6.37104 5.19969 5.63874C5.20563 5.63448 5.21168 5.63034 5.21778 5.62628C5.25546 5.60143 5.29571 5.57198 5.33834 5.54079L5.34274 5.53756C5.34707 5.5344 5.3514 5.53123 5.35574 5.52805C5.37278 5.51558 5.38984 5.50309 5.40699 5.49073C7.03328 4.32698 8.05469 2.52291 8.21086 0.539946C8.22625 0.344243 8.33864 0.169283 8.51024 0.0738523C8.68184 -0.0215384 8.88965 -0.0247025 9.06406 0.065415C11.0674 1.10084 12.5381 2.94621 13.0865 5.12826C13.257 5.79261 13.3435 6.45639 13.3435 7.10143C13.3435 7.4376 13.3215 7.77288 13.278 8.10617C13.7149 7.64479 14.0863 7.12288 14.3785 6.55495C14.4635 6.38986 14.6218 6.27483 14.8050 6.24486H15.0175Z"
        fill={`url(#${gradientId})`}
      />
      <path
        d="M7.26479 17.26C8.44639 18.4251 10.3487 18.4119 11.5138 17.2304C14.2303 14.4755 11.4427 10.4911 10.0344 8.82131C9.86432 8.61965 9.41566 8.82648 9.38895 9.0889C9.29817 9.98114 8.84772 11.3757 7.23507 13.011C6.06992 14.1925 6.08329 16.0951 7.26479 17.26Z"
        fill={centerColor}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="5.72658"
          y1="5.48387"
          x2="14.9038"
          y2="17.2227"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={mainColor} />
          <stop offset="1" stopColor={darkenedColor} />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
