import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Card/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/CardContent/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/SvgIcon/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["LogoutButton"] */ "/home/runner/work/client/client/apps/web/src/app/[lang]/(setup)/components/logout-button.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/src/app/[lang]/(setup)/setup-layout.scss");
