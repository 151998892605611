import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/SvgIcon/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@mui/material/Unstable_Grid2/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/src/app/[lang]/(auth)/auth-layout.scss");
;
import(/* webpackMode: "eager", webpackExports: ["AuthCarousel"] */ "/home/runner/work/client/client/apps/web/src/app/[lang]/(auth)/components/auth-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthFooter"] */ "/home/runner/work/client/client/apps/web/src/app/[lang]/(auth)/components/auth-footer.tsx");
