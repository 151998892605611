'use client';

import { SocialProof } from '@/__generated__/API';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { getSocialProofQuery } from './query-options';

export const useGetSocialProof = (parentId: SocialProof['parentId']) => {
  const graphqlClient = useGraphqlClient();

  return useQuery(getSocialProofQuery(parentId, graphqlClient));
};

export const useStreamSocialProof = (parentId: SocialProof['parentId']) => {
  const graphqlClient = useGraphqlClient();

  return useSuspenseQuery(getSocialProofQuery(parentId, graphqlClient));
};

export {
  getSocialProofQueryKey,
  socialProofSelectionSet
} from './query-options';

export type { SocialProofQueryResponse } from './query-options';
