'use client';

import { useGraphqlClient } from '@/libs/amplify/client';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { produce } from 'immer';
import assign from 'lodash-es/assign';
import { useCallback, useEffect } from 'react';
import { getLeaderboardQueryKey } from './query-options';
import { GetLeaderboard, getLeaderboardSelectionSet } from './types';

export function useOnUpdateLeaderboard(owner: string, month: string) {
  const queryClient = useQueryClient();
  const queryKey = getLeaderboardQueryKey(month);
  const graphqlClient = useGraphqlClient();

  const onSubscribe = useCallback(
    (updatedLeaderboard: GetLeaderboard) => {
      queryClient.setQueryData<GetLeaderboard>(queryKey, (data) => {
        if (
          data &&
          updatedLeaderboard?.owner === owner &&
          dayjs(updatedLeaderboard.updatedAt).isAfter(data.updatedAt)
        ) {
          return produce(data, (draft) => {
            assign(draft, updatedLeaderboard);
          });
        }
        return data;
      });
    },
    [queryClient, queryKey, owner]
  );

  useEffect(() => {
    if (owner) {
      const options = {
        selectionSet: getLeaderboardSelectionSet,
        filter: {
          month: { eq: month }
        }
      };

      const createSubscription =
        graphqlClient.models.Leaderboard.onCreate(options).subscribe(
          onSubscribe
        );

      const updateSubscription =
        graphqlClient.models.Leaderboard.onUpdate(options).subscribe(
          onSubscribe
        );

      return () => {
        createSubscription.unsubscribe();
        updateSubscription.unsubscribe();
      };
    }
  }, [graphqlClient, month, owner, onSubscribe]);
}
