import { PlayButton } from '@vidstack/react';
import { PlayIcon } from '@vidstack/react/icons';

export function BigPlayButton() {
  return (
    <PlayButton className="vds-play-button vds-big-play-button vds-button">
      <PlayIcon className="play-icon vds-icon" />
    </PlayButton>
  );
}
