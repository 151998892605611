'use client';

import { useSuspenseStreakInfo } from '../hooks/use-suspense-streak-info';
import type { DashboardStreakDatesProps } from './dashboard-streak-dates';

export function CurrentStreakCount({
  date,
  weekDates
}: DashboardStreakDatesProps) {
  const { currentStreakCount } = useSuspenseStreakInfo(date, weekDates);
  return currentStreakCount ?? 0;
}
