'use client';

import { JournalCardSkeleton } from '@/app/[lang]/(shell)/components/dashboard/dashboard-cards-skeleton';
import { useListJournalsByParentId } from '@/app/[lang]/(shell)/journal/api/list';
import { JournalInlineEditor } from '@/app/[lang]/(shell)/journal/components/journal-inline-editor';
import useStableCallback from '@/hooks/use-stable-callback';
import { useAuthStore } from '@/stores/auth-store-provider';
import { CardContent, CardHeader, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';
import { useEffect } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import { DashboardCardProps } from './types';
import { JournalPrivacy } from '@/app/[lang]/(shell)/journal/components/journal-privacy';
import { CommentParentType } from '@/__generated__/API';
import { DashboardCardWrapper } from './card-wrapper';
import {
  dashboardCardItemCompleted,
  sendBrazeDashboardTaskStartedEvent
} from '../utils/send-braze-dashboard-events';
import { useQueryClient } from '@tanstack/react-query';
import { useUpsellOffering } from '@/entitlements/hooks/use-upsell-offering';
import { Entitlement } from '@/entitlements/enums';

export function JournalCard({ card, onComplete }: DashboardCardProps) {
  const queryClient = useQueryClient();
  const authStore = useAuthStore();
  const userId = authStore.useTracked.userId();
  const parentId = `dashboard#${card.date}#${card.id}`;
  const title = (
    <Typography variant="h6" fontWeight={600}>
      <Trans id="dashboard.journalTitle">
        Fill out today&apos;s mindset prompt
      </Trans>
    </Typography>
  );

  const { ref, isIntersecting } = useIntersectionObserver({
    initialIsIntersecting: false,
    freezeOnceVisible: true
  });
  const { data: list, isLoading } = useListJournalsByParentId(
    { parentId },
    isIntersecting
  );

  const { isEntitled, showUpsell } = useUpsellOffering({
    requiredEntitlements: [
      Entitlement.MASTERY,
      Entitlement.PRO,
      Entitlement.STARTER
    ]
  });

  const onStableComplete = useStableCallback(onComplete);

  useEffect(() => {
    const journal = list?.[0];
    if (journal) {
      onStableComplete();
    }
  }, [list, onStableComplete]);

  return (
    <div id="morning-mindset" ref={ref}>
      {!isIntersecting || isLoading ? (
        <JournalCardSkeleton />
      ) : (
        <DashboardCardWrapper
          parentId={parentId}
          parentType={CommentParentType.JOURNAL}
          commentsTitle={<CardHeader title={title} />}
        >
          <CardHeader
            sx={{ paddingBottom: 0 }}
            title={title}
            action={<JournalPrivacy />}
          />
          <CardContent
            onClick={() => {
              if (!isEntitled) {
                showUpsell();
              } else {
                const isComplete = dashboardCardItemCompleted(
                  queryClient,
                  userId ?? '',
                  'JOURNAL'
                );
                if (!isComplete) {
                  sendBrazeDashboardTaskStartedEvent(
                    queryClient,
                    userId ?? '',
                    'JOURNAL'
                  );
                }
              }
            }}
          >
            <JournalInlineEditor
              parentId={parentId}
              customPrompt={card.customPrompt}
              customPromptImage={card.image}
              readOnly={!isEntitled}
            />
          </CardContent>
        </DashboardCardWrapper>
      )}
    </div>
  );
}
