import * as APITypes from '@/__generated__/API';
import { GeneratedSubscription } from '@aws-amplify/api-graphql';

/**
 * Custom subscription for comment creation which fetches nested fields
 * Keeping in mind permissions and selection set
 */
export const commentCreateSubscription = /* GraphQL */ `
  subscription CommentCreateSubscription($filter: ModelSubscriptionCommentFilterInput) {
    onCreateComment(filter: $filter) {
      author {
        publicProfileId
        displayName
        profileImage {
          cdnId
          url
          height
          width
        }
      }
      id
      content
      owner
      createdAt
      updatedAt
      threadId
      parentId
      parentType
      type
      likesCount
      commentsCount
      version
      visibility
      pinnedState
    }
  }
` as GeneratedSubscription<
  Pick<APITypes.OnCreateCommentSubscriptionVariables, 'filter'>,
  APITypes.OnCreateCommentSubscription
>;
