import { GraphqlClient } from '@/libs/amplify/types';
import type { DefaultError } from '@tanstack/query-core';
import { UseSuspenseQueryOptions } from '@tanstack/react-query';
import {
  getDashboardCardQueryKey,
  GetDashboardCardQueryKey,
  getDashboardProgressQueryKey,
  GetDashboardProgressQueryKey
} from './query-options';
import {
  DashboardCard,
  dashboardCardSelectionSet,
  DashboardProgress
} from './types';
import { throwGraphqlError } from '@/utils/throw-graphql-error';

export function getDashboardCardQuery(
  graphqlClient: GraphqlClient,
  date: string
): UseSuspenseQueryOptions<
  DashboardCard | null,
  DefaultError,
  DashboardCard | null,
  GetDashboardCardQueryKey
> {
  const queryKey = getDashboardCardQueryKey(date);
  return {
    queryKey,
    queryFn: async () => {
      const { data, errors } = await graphqlClient.models.DashboardCard.get(
        { date },
        { selectionSet: dashboardCardSelectionSet }
      );
      throwGraphqlError(errors);
      return data as DashboardCard;
    }
  };
}

export function getDashboardProgressQuery(
  graphqlClient: GraphqlClient,
  owner: string,
  date: string
): UseSuspenseQueryOptions<
  DashboardProgress | null,
  DefaultError,
  DashboardProgress | null,
  GetDashboardProgressQueryKey
> {
  const queryKey = getDashboardProgressQueryKey(owner, date);
  return {
    queryKey,
    queryFn: async () => {
      if (!owner) {
        return null;
      }
      const { data, errors } = await graphqlClient.models.DashboardProgress.get(
        { date, owner }
      );
      throwGraphqlError(errors);
      return data as DashboardProgress;
    }
  };
}
