'use client';

import { GraphQLResult } from '@aws-amplify/api-graphql';
import { listRecentlyPlayedQuery } from './graphql';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useAuthStore } from '@/stores/auth-store-provider';
import { useQuery } from '@tanstack/react-query';
import {
  getRecentlyPlayedQueryKey,
  getRecentlyPlayedVariables,
  RecentlyPlayedFilterInput
} from './query-options';
import { RecentlyPlayedItem } from './types';
import { throwGraphqlError } from '@/utils/throw-graphql-error';

export function useGetRecentlyPlayed(filterInput: RecentlyPlayedFilterInput) {
  const authStore = useAuthStore();
  const userId = authStore.useTracked.userId();
  const graphqlClient = useGraphqlClient();
  const variables = getRecentlyPlayedVariables(filterInput);
  const queryKey = getRecentlyPlayedQueryKey(userId ?? '', filterInput);

  const result = useQuery({
    queryKey,
    queryFn: async () => {
      if (!userId) {
        throw new Error('User must be signed in!');
      }

      const { data, errors } = (await graphqlClient.graphql({
        query: listRecentlyPlayedQuery,
        variables: {
          owner: userId ?? '',
          entityId: { eq: variables.entityId }
        }
      })) as GraphQLResult<{
        listRecentlyPlayedByOwnerAndEntityId: { items: RecentlyPlayedItem[] };
      }>;

      throwGraphqlError(errors);

      return (data?.listRecentlyPlayedByOwnerAndEntityId?.items?.[0] ||
        null) as unknown as RecentlyPlayedItem | null;
    },
    enabled: !!userId && !!filterInput.entityId
  });

  const recentPlayed = filterInput.parentEntityId
    ? Array.isArray(result.data?.children)
      ? result.data.children.find(
          (child) => child?.entityId === filterInput.entityId
        )
      : undefined
    : result.data;

  return { ...result, recentPlayed };
}
