'use client';

import { CdnImage } from '@/__generated__/API';
import { calculateEditorHeight } from '@/libs/editor/editor';
import { absoluteFill } from '@/utils/styles';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import Image from 'next/image';
import { useBoolean } from 'usehooks-ts';
import { CreateJournalInput } from '../api/create';
import { useListJournalsByParentId } from '../api/list';
import { CreateJournal } from './create-journal';
import { JournalActions } from './journal-actions';
import { RenderJournal } from './render-journal';
import { UpdateJournal } from './update-journal';
import { Entitlement } from '@/entitlements/enums';
import { useUpsellOffering } from '@/entitlements/hooks/use-upsell-offering';

export type JournalInlineEditorProps = {
  readOnly?: boolean;
  disableContentContainerStyles?: boolean;
  isLoading?: boolean;
  hideDate?: boolean;
  hideImageUpload?: boolean;
  hideJournalPrompts?: boolean;
  hideJournalCustomPrompt?: boolean;
  parentId: string;
  customPrompt?: string | null;
  customPromptImage?: Partial<CdnImage> | null;
  onCreate?(): void;
  onBeforeCreate?(): Promise<Partial<CreateJournalInput> | void | false>;
  requiredEntitlements?: Entitlement[]
};

export function JournalInlineEditor({
  readOnly,
  disableContentContainerStyles,
  isLoading,
  hideDate = true,
  hideImageUpload = true,
  hideJournalPrompts = true,
  hideJournalCustomPrompt,
  parentId,
  customPrompt,
  customPromptImage,
  requiredEntitlements = [
    Entitlement.MASTERY,
    Entitlement.PRO,
    Entitlement.STARTER
  ],
  onCreate,
  onBeforeCreate
}: JournalInlineEditorProps) {
  const { value: updateMode, setValue: setUpdateMode } = useBoolean();
  const { data: list, isLoading: isListingLoading } = useListJournalsByParentId(
    { parentId }
  );
  const journal = list?.[0];

  const { isEntitled, showUpsell } = useUpsellOffering({
    requiredEntitlements
  });

  return (
    <Stack direction="column" spacing={2} position="relative">
      {!hideJournalCustomPrompt && customPrompt && (
        <Box
          sx={{
            borderRadius: 1,
            overflow: 'hidden',
            backgroundColor: 'grey.900',
            position: 'relative',
            p: 3
          }}
        >
          {customPromptImage?.url && (
            <>
              <Image
                alt="cover image"
                src={customPromptImage.url}
                fill
                style={{ objectFit: 'cover', zIndex: 0 }}
              />
              <Box
                sx={[absoluteFill, { backgroundColor: 'rgba(0,0,0,0.5)' }]}
              />
            </>
          )}
          {/* background is always going to be dark or image, thereby white text */}
          <Typography
            position="relative"
            zIndex={2}
            textAlign="center"
            color="white"
            fontWeight={600}
          >
            {customPrompt}
          </Typography>
        </Box>
      )}

      {isListingLoading ? (
        <Stack direction="column" spacing={1} position="relative">
          {!hideDate && (
            <Typography variant="caption">
              <Skeleton width={154} />
            </Typography>
          )}
          <Skeleton
            variant="rounded"
            height={calculateEditorHeight(3)}
            sx={{ opacity: 0.5 }}
          />
        </Stack>
      ) : journal ? (
        <Stack direction="column" spacing={1} position="relative">
          {!hideDate && (
            <Typography
              variant="caption"
              fontWeight={500}
              color="text.disabled"
            >
              {dayjs
                .utc(journal.datetime)
                .tz()
                .format('MMM DD, YYYY [at] hh:mma')}
            </Typography>
          )}
          {updateMode ? (
            <UpdateJournal
              hideJournalPrompt={hideJournalPrompts}
              hideImageUpload={hideImageUpload}
              onCancelOrFinish={() => setUpdateMode(false)}
              journal={journal}
            />
          ) : (
            <>
              <RenderJournal
                hideJournalPrompt={hideJournalPrompts}
                journal={journal}
                onClick={() => {
                  if (isEntitled) {
                    setUpdateMode(true);
                  } else {
                    showUpsell();
                  }
                }}
                contentContainerStyles={
                  disableContentContainerStyles
                    ? {}
                    : {
                        backgroundColor: 'action.hover',
                        padding: 1.5,
                        margin: 0,
                        borderRadius: 1,
                        minHeight: calculateEditorHeight(3)
                      }
                }
              />
              <JournalActions
                sx={
                  disableContentContainerStyles
                    ? { top: -16, right: 0 }
                    : { bottom: 8, top: 'unset' }
                }
                journalId={journal.id}
                onUpdate={() => setUpdateMode(true)}
                requiredEntitlements={requiredEntitlements}
              />
            </>
          )}
        </Stack>
      ) : (
        <CreateJournal
          readOnly={readOnly}
          isLoading={isLoading}
          customPrompt={customPrompt}
          parentId={parentId}
          onCreate={onCreate}
          onBeforeCreate={onBeforeCreate}
          paperContainerStyles={{ boxShadow: 'none', p: 0 }}
          disableAutofocus
          hideJournalPrompts={hideJournalPrompts}
          hideImageUpload={hideImageUpload}
          requiredEntitlements={requiredEntitlements}
        />
      )}
    </Stack>
  );
}
