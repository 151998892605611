'use client';

import { useAuthStore } from '@/stores/auth-store-provider';
import { Trans } from '@lingui/macro';
import Button from '@mui/material/Button';
import { useRouter } from '@/hooks/use-router';

export function LogoutButton() {
  const authStore = useAuthStore();
  const isSignedIn = authStore.useTracked.isSignedIn();
  const router = useRouter();

  const handleLogout = async () => {
    analytics.track('Click', {
      element_id: 'logout-button',
      type: 'BUTTON'
    });
    await authStore.set.signOut(true);
    router.refresh();
  };

  if (!isSignedIn) return null;

  return (
    <Button
      id="logout-button"
      data-testid="logout-button"
      onClick={handleLogout}
      variant="outlined"
      size="small"
    >
      <Trans id="setup.logoutCta">Logout</Trans>
    </Button>
  );
}
