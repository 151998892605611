import { CreateEntityLikeInput } from '@/__generated__/API';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useAuthStore } from '@/stores/auth-store-provider';
import {
  EntityLikeSelectionSetResponse,
  getUserEntityLikeQueryKey
} from '@/api/entity-like/read';
import {
  getSocialProofQueryKey,
  SocialProofQueryResponse
} from '@/api/social-proof/read';
import { useUpdateSocialProofInStore } from '@/api/social-proof/utils';
import { throwGraphqlError } from '@/utils/throw-graphql-error';

/**
 * Custom React hook for creating an entity like.
 *
 * @returns Mutation for creating entity like
 */
export function useCreateEntityLike() {
  const authStore = useAuthStore();
  const graphql = useGraphqlClient();
  const queryClient = useQueryClient();
  const userProfileId = authStore.useTracked.userId();
  const updateSocialProof = useUpdateSocialProofInStore();

  /**
   * Mutation function to create an entity like.
   *
   * @param payload - The payload containing the entity ID.
   * @returns The response data or null
   */
  return useMutation({
    async mutationFn(payload: Pick<CreateEntityLikeInput, 'entityId'>) {
      if (!userProfileId) {
        return null;
      }
      const response = await graphql.models.EntityLike.create({
        ...payload,
        userProfileId
      });
      throwGraphqlError(response.errors);
      return response.data;
    },
    async onMutate(payload) {
      if (!userProfileId) {
        return null; // Return null if userProfileId is not available
      }
      const entityLikeQueryKey = getUserEntityLikeQueryKey(payload.entityId);
      const socialProofQueryKey = getSocialProofQueryKey(payload.entityId);

      // Cancel any outgoing queries for entity like and social proof
      const likeQueryCancel = queryClient.cancelQueries({
        queryKey: entityLikeQueryKey
      });
      const socialProofQueryCancel = queryClient.cancelQueries({
        queryKey: socialProofQueryKey
      });

      // Wait for all cancellations to settle
      await Promise.allSettled([likeQueryCancel, socialProofQueryCancel]);

      // Get the previous cache data for entity like
      const previousEntityLike =
        queryClient.getQueryData<EntityLikeSelectionSetResponse | null>(
          entityLikeQueryKey
        );
      // Optimistically update the cache for entity like
      queryClient.setQueryData<EntityLikeSelectionSetResponse | null>(
        entityLikeQueryKey,
        {
          userProfileId,
          id: `${payload.entityId}-like-id`,
          entityId: payload.entityId
        }
      );

      // Get the previous cache data for social proof
      const previousSocialProof =
        queryClient.getQueryData<SocialProofQueryResponse | null>(
          getSocialProofQueryKey(payload.entityId)
        );
      // Optimistically update the cache for social proof
      updateSocialProof(payload.entityId, (old) => ({
        likesCount: (old?.likesCount ?? 0) + 1
      }));

      return { previousEntityLike, previousSocialProof };
    },
    /**
     * Rollback the cache updates in case of an error.
     *
     * @param _ - The error object.
     * @param payload - The payload containing the entity ID.
     * @param context - The context containing previous cache data.
     */
    onError(_, payload, context) {
      if (context) {
        const entityLikeQueryKey = getUserEntityLikeQueryKey(payload.entityId);
        const socialProofQueryKey = getSocialProofQueryKey(payload.entityId);

        // Rollback the cache updates for entity like and social proof
        queryClient.setQueryData(
          entityLikeQueryKey,
          context.previousEntityLike
        );
        queryClient.setQueryData(
          socialProofQueryKey,
          context.previousSocialProof
        );
      }
    },
    /**
     * Update the cache with the response data on successful mutation.
     *
     * @param response - The response data from the mutation.
     * @returns Returns null if response is not available.
     */
    onSuccess(response) {
      if (!response) {
        return null;
      }
      const entityLikeQueryKey = getUserEntityLikeQueryKey(response.entityId);
      // Update the cache with the response data
      queryClient.setQueryData<EntityLikeSelectionSetResponse | null>(
        entityLikeQueryKey,
        {
          userProfileId: response.userProfileId,
          id: response.id,
          entityId: response.entityId
        }
      );
    }
  });
}
