'use client';

import { EntityType } from '@/__generated__/API';
import { EntityItem } from '@/api/entities/types';
import {
  getRecentlyPlayedQueryKey,
  getRecentlyPlayedVariables,
  RecentlyPlayedFilterInput
} from './query-options';
import { RecentlyPlayedItem } from './types';
import { useAuthStore } from '@/stores/auth-store-provider';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { keyBy } from 'lodash-es';
import round from 'lodash-es/round';
import { isMediaComplete } from '../../utils/is-media-complete';
import { throwGraphqlError } from '@/utils/throw-graphql-error';

type UpdateRecentlyPlayedInput = {
  entityId: string;
  entityType: EntityType;
  parentEntityId?: string;
  childrenEntityIds?: string[];
  images: EntityItem['images'];
};

function calculateParentPlaybackProgress(
  playbackPercentage: number = 0,
  input: RecentlyPlayedItem | undefined | null,
  lastPlayedAt: string,
  {
    childrenEntityIds = [],
    entityId
  }: Pick<UpdateRecentlyPlayedInput, 'childrenEntityIds' | 'entityId'>
) {
  const inputChildrenMap = keyBy(
    Array.isArray(input?.children) ? input.children : [],
    'entityId'
  );

  const startOfTimeAt = new Date(0).toISOString();

  const updatedChildren = childrenEntityIds.map((id) => {
    let child = inputChildrenMap[id];

    if (!child) {
      child = {
        entityId: id,
        lastPlayedAt: startOfTimeAt,
        playbackPercentage: 0,
        completeTime: null
      };
    }

    if (id === entityId) {
      const completeTime =
        child.completeTime ??
        (isMediaComplete(playbackPercentage) ? lastPlayedAt : null);
      return {
        ...child,
        playbackPercentage,
        lastPlayedAt,
        completeTime
      };
    }

    return child;
  });

  const totalProgressPercentage = updatedChildren.reduce((total, item) => {
    return total + (item?.playbackPercentage ?? 0);
  }, 0);

  const averagePlaybackPercentage = round(
    totalProgressPercentage / (updatedChildren.length || 1),
    2
  );

  const isParentCompleted = updatedChildren.reduce(
    (allChildrenCompleted, item) => {
      return (
        allChildrenCompleted &&
        (!!item.completeTime || isMediaComplete(item?.playbackPercentage))
      );
    },
    true
  );

  return {
    averagePlaybackPercentage,
    updatedChildren,
    isParentCompleted
  };
}

export function useUpdateRecentlyPlayed({
  childrenEntityIds,
  parentEntityId,
  entityId,
  entityType,
  images
}: UpdateRecentlyPlayedInput) {
  const authStore = useAuthStore();
  const owner = authStore.useTracked.userId();
  const queryClient = useQueryClient();
  const graphqlClient = useGraphqlClient();
  const filterInput: RecentlyPlayedFilterInput = {
    parentEntityId,
    entityId
  };
  const variables = getRecentlyPlayedVariables(filterInput);
  const queryKey = getRecentlyPlayedQueryKey(owner ?? '', filterInput);

  return useMutation({
    async mutationFn(playbackPercentage: number) {
      if (!owner) return null;
      const recentPlayed =
        queryClient.getQueryData<RecentlyPlayedItem>(queryKey);

      const lastPlayedAt = new Date().toISOString();

      const input = {
        lastPlayedAt,
        entityId: variables.entityId,
        entityType,
        images,
        completeTime: recentPlayed?.completeTime
      } as Parameters<
        | typeof graphqlClient.models.RecentlyPlayed.update
        | typeof graphqlClient.models.RecentlyPlayed.create
      >[0];

      if (parentEntityId) {
        const {
          updatedChildren,
          averagePlaybackPercentage,
          isParentCompleted
        } = calculateParentPlaybackProgress(
          playbackPercentage,
          recentPlayed,
          lastPlayedAt,
          {
            childrenEntityIds,
            entityId
          }
        );
        input.playbackPercentage = averagePlaybackPercentage;
        input.children = updatedChildren as any;

        if (isParentCompleted) {
          input.completeTime ??= lastPlayedAt;
        }
      } else {
        input.playbackPercentage = playbackPercentage;
        input.children = [];

        if (isMediaComplete(playbackPercentage)) {
          input.completeTime ??= lastPlayedAt;
        }
      }

      if (recentPlayed) {
        input.id = recentPlayed.id;
        const response = await graphqlClient.models.RecentlyPlayed.update(
          input as Parameters<
            typeof graphqlClient.models.RecentlyPlayed.update
          >[0]
        );
        throwGraphqlError(response.errors);
        return response.data;
      } else {
        input.owner = owner;
        const response = await graphqlClient.models.RecentlyPlayed.create(
          input as Parameters<
            typeof graphqlClient.models.RecentlyPlayed.create
          >[0]
        );
        throwGraphqlError(response.errors);
        return response.data;
      }
    },
    onSuccess: (updatedRecentlyPlayed) => {
      if (updatedRecentlyPlayed) {
        try {
          queryClient.setQueryData<RecentlyPlayedItem>(
            queryKey,
            updatedRecentlyPlayed as RecentlyPlayedItem
          );
        } catch {}
      }
      queryClient.invalidateQueries({
        queryKey,
        refetchType: 'none'
      });
    }
  });
}
