import { StreakDateButton } from './streak-date-button';
import { CurrentStreakCount } from './current-streak-count';
import { FireIcon } from '@/assets/icons/dashboard/fire-icon';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Suspense } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';

export type DashboardStreakDatesProps = {
  date: string;
  weekDates: string[];
};

export function DashboardStreakDates({
  date,
  weekDates
}: DashboardStreakDatesProps) {
  return (
    <Link href="/leaderboard/streaks" underline="none" color="inherit">
      <Stack alignItems="center" spacing={1} pr={1} pl={2}>
        <Stack alignItems="center" spacing={0.5} py={1}>
          <Typography variant="h6" fontWeight="medium">
            <Suspense fallback={<Skeleton width={20} />}>
              <CurrentStreakCount date={date} weekDates={weekDates} />
            </Suspense>
          </Typography>
          <FireIcon />
        </Stack>
        <Divider flexItem orientation="vertical" />
        <Stack alignItems="center" spacing={1} py={1}>
          {weekDates.map((weekDate) => (
            <Suspense
              key={weekDate}
              fallback={<Skeleton variant="circular" width={32} height={32} />}
            >
              <StreakDateButton
                date={date}
                weekDate={weekDate}
                weekDates={weekDates}
              />
            </Suspense>
          ))}
        </Stack>
      </Stack>
    </Link>
  );
}
